import SignUp from "./pages/SignUp";
import Reset from "./pages/Reset";
import ChangePassword from "./pages/ChangePassword";
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";
import LogoutDefault from "./pages/LogoutDefault";
import PasswordReseted from "./pages/PasswordReseted";
import ResetLink from "./pages/ResetLink";
import { getUriPage } from "../../../views/RootPages";
import LogoutCustomer from "./pages/LogoutCustomer";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};
const settings2 = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: true,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const sessionRoutes = [
  {
    path: getUriPage("session.signup"),
    component: SignUp,
    settings,
  },
  {
    path: getUriPage("session.logoutDefault"),
    component: LogoutDefault,
    settings,
  },
  {
    path: getUriPage("session.logoutCustomer"),
    component: LogoutCustomer,
    settings,
  },
  {
    path: getUriPage("session.passwordReseted"),
    component: PasswordReseted,
    settings,
  },
  {
    path: getUriPage("session.reset-link"),
    component: ResetLink,
    settings,
  },
  {
    path: getUriPage("session.signIn"),
    component: SignIn,
    settings,
  },
  {
    path: getUriPage("session.forgot-password"),
    component: Reset,
    settings,
  },
  {
    path: getUriPage("session.change-password"),
    component: ChangePassword,
    settings: settings2,
  },
  {
    path: getUriPage("session.404"),
    component: NotFound,
    settings,
  },
];

export default sessionRoutes;
