import _ from "lodash";
import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from "react-redux";
import constants from "../../../constants/index.js";
const Presentation = ({ ...props }) => {
    const me = useSelector((store) => store.me);
    const room = useSelector((store) => store.room);
    const officialLang = _.get(room, "info.officialLang", "es");
    const presentation = useSelector((store) => {
        return _.get(store, "room.presentation");
    });
    const deltaTime = 10;
    const refVideo = useRef();

    useEffect(() => {
        if (refVideo.current && refVideo.current.seekTo) {
            if (Math.abs(refVideo.current.getCurrentTime() - _.get(presentation, "video.playedSeconds")) > deltaTime) {
                refVideo.current.seekTo(parseFloat(_.get(presentation, "video.playedSeconds"), 'seconds'))
            }
        }

    }, [_.get(presentation, "video.playedSeconds")])


    return (
        <React.Fragment>
            <div data-component="Presentation">
                <div className="transparent-div"></div>
                <ReactPlayer
                    ref={refVideo}
                    url={_.get(presentation, "video.url")}
                    className='react-player'
                    width='100%'
                    height='100%'
                    pip={_.get(presentation, "video.pip")}
                    playing={_.get(presentation, "video.playing")}
                    controls={_.get(presentation, "video.controls")}
                    light={_.get(presentation, "video.light")}
                    loop={_.get(presentation, "video.loop")}
                    playbackRate={_.get(presentation, "video.playbackRate")}
                    volume={_.get(presentation, "video.volume")}
                    muted={officialLang !== me.peerLang && me.peerType!=constants.PEERS_TYPES.INTERPRETER}
                    

                />
            </div>
        </React.Fragment>
    );
}

export default Presentation;