import LockIcon from "@material-ui/icons/Lock";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import translate from "app/lang/translate";
import Button from "app/modules/components/buttons/Button.js";
import Modal from "./Modal";
import * as roomActions from "app/redux/actions/roomActions.js";
import { getUriPage } from "app/views/RootPages.js";
import useEvent from "../../../hooks/useEvent";

const ModalCloseRoom = ({ ...props }) => {
  const { closeRoom } = useEvent();

  const dispatch = useDispatch();
  const modalCloseRoom = useSelector((store) =>
    _.get(store, "room.modals.closeRoom")
  );
  const currentRoom = useSelector((store) => store.room);

  const exitRoom = () => {
    window.location = _.get(
      currentRoom,
      "info.urlFinish",
      getUriPage("session.logoutCustomer").replace(
        ":lang?",
        _.get(currentRoom, "info.officialLang", "")
      )
    );
  };

  const closeModal = () => {
    dispatch(roomActions.closeModal("closeRoom", {}));
  };

  return (
    <Modal
      open={modalCloseRoom.open}
      title={"MODAL_CLOSE_CUSTOMER_TITLE"}
      buttons={[
        <Button
          variant="contained"
          size="small"
          type="button"
          onClick={() => {
            closeModal();
          }}
        >
          {translate("TEXT_CUSTOMER_CANCEL_BUTTON")}
        </Button>,
        <Button
          variant="contained"
          type="button"
          size="small"
          color={"primary"}
          onClick={() => {
            closeRoom();
            exitRoom();
          }}
        >
          {translate("TEXT_CUSTOMER_EXIT_BUTTON")}
        </Button>,
      ]}
      onClose={closeModal}
    ></Modal>
  );
};
export default ModalCloseRoom;
