import _ from "lodash";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import translate from '../../../lang/translate';
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import Button from "./../../../modules/components/buttons/Button";
import Modal from "./../../../modules/components/generic/Modal";
import * as roomActions from './../../../redux/actions/roomActions';


const ModalEnabledPeer = ({ roomClient, ...props }) => {
	const dispatch = useDispatch();
	const me = useSelector((store) => store.me);
	const modalEnabledPeer = useSelector((store) => _.get(store, "room.modals.enabledPeer"));
	// const modalEnabledPeer = { open: true };
	
	const enableCam = ()=>{
		roomClient.enableWebcam();
	}
	const enableMic = ()=>{
		roomClient.enableMic();
	}
	const closeModal = ()=>{
		dispatch(roomActions.closeModal("enabledPeer",{}));
	}
	const handleSubmit = (values)=>{
		if (values.cam){
			enableCam();
		}
		if (values.mic){
			enableMic();
		}
		closeModal();
	}
	return (
		<Modal
			open={modalEnabledPeer.open}
			title={"MODAL_ENABLEDPEER_TITLE"}
			subtitle={"MODAL_ENABLEDPEER_SUBTITLE"}
			srcImage={"/assets/images/illustrations/camMicro.svg"}
			buttons={[
				<Button variant="contained" type="button" onClick={() => handleSubmit({mic:false,cam:false})}>
					{translate("MODAL_BUTTON_DISABLED")}
				</Button>,
				<Button variant="contained" type="button" onClick={() => handleSubmit({mic:true,cam:false})}>
					{translate("MODAL_BUTTON_MICRO")}
				</Button>,
				<Button variant="contained" type="button" onClick={() => handleSubmit({mic:false,cam:true})}>
					{translate("MODAL_BUTTON_CAMERA")}
				</Button>,
				<Button variant="contained" type="button" color={"primary"} onClick={() => handleSubmit({mic:true,cam:true})}>
					{translate("MODAL_BUTTON_CAMERA_MICRO")}
				</Button>
			]}
			onClose={closeModal}
		>
			<React.Fragment>
				<div data-component={"ModalEnabledPeer"}>
					{translate("TEXT_MODAL_YOU_WORD")}					
				</div>
			</React.Fragment>
		</Modal>
	)
}
export default withRoomContext(ModalEnabledPeer)
