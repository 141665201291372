export const PEERS_TYPES = {
  INVITED: "invited",
  INTERPRETER: "interpreter",
  SPEEKER: "speeker",
  ADMIN: "admin",
  CUSTOMER: "customer",
  SUPPORT: "support",
};

export const ROOM_STATUS = {
  OPEN: "open",
  DISABLED: "disabled",
};

export const ROOM_VIEWS = {
  CUSTOMER: "customer",
  DELETED: "deleted",
  CUSTOMER_INTRO: "customerIntro",
  INTRO_ONLINE_SUPPORT: "introOnlineSupport",
  SUPPORT: "support",
  INTERPRETER: "interpreter",
  DEFAULT: "default",
  INVITED: "invited",
};

export const ACTIONS_ON_PARENT = {
  CLOSE_ROOM: "CLOSE_ROOM",
  OPEN_ROOM: "OPEN_ROOM",
  DRAG_RESIZE_ON: "DRAG_RESIZE_ON",
  DRAG_RESIZE_OFF: "DRAG_RESIZE_OFF",
  DRAG_RESIZE_BUTTON: "DRAG_RESIZE_BUTTON",
  CLOSE_WINDOW: "CLOSE_WINDOW",
};

export const PARENT_EVENTS = {
  DRAG_RESIZE_ON: "DRAG_RESIZE_ON",
  DRAG_RESIZE_OFF: "DRAG_RESIZE_OFF",
};

export default { PEERS_TYPES, ROOM_STATUS, ACTIONS_ON_PARENT, PARENT_EVENTS };
