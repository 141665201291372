

export default {
  module:{
    rooms:{
      list: {
        uri:"/admin/module/rooms/list",
      },
      add: {
        uri:"/admin/module/rooms/add",
      }
    }
  }
};