import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useSnackbar } from "notistack";
import translate from "./../../../lang/translate";
export default function useService(
  serviceConfig,
  { auto, paramsUrl = {}, params, initValue, callback, ...props }
) {
  const [result, setResult] = useState(initValue || {});
  const [loading, setLoading] = useState(false);
  const token = useSelector((store) => store.session.token);
  const { enqueueSnackbar } = useSnackbar();
  const send = (params, paramsUrl) => {
    if (serviceConfig) {
      setLoading(true);
      let headers = {};
      headers["Content-Type"] = "application/json";
      if (serviceConfig.useToken) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      let uri = serviceConfig.uri;
      _.forEach(paramsUrl, (value, key) => {
        uri = uri.replace(`:${key}`, value);
      });
      let fetchParams = {
        method: serviceConfig.method,
        headers,
      }
      if (params) {
        fetchParams["body"] = JSON.stringify(params);
      }
      fetch(`${process.env.REACT_APP_API_URI}${uri}`, fetchParams)
        .then((r) => {
          if (r.status !== 200) {

            throw r;
          }
          return r.json();
        })
        .then((resp) => {
          if (serviceConfig.successMessageKey) {
            enqueueSnackbar(
              translate(
                serviceConfig.successMessageKey,
                resp,
                "NOTIFICATION_SUCCESS"
              ),
              { variant: "success" }
            );
          }

          setResult(resp);
          setLoading(false);
          if (callback) {
            callback(resp);
          }
        })
        .catch((e) => {
          if (e.status === 401) {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("user");
            window.location = window.location.origin;
          } else {
            e.json().then((data) => {
              const defaultErrorMessage = serviceConfig.defaultErrorMessageKey ? serviceConfig.defaultErrorMessageKey : data.msg;
              const errorMessage = data.errorCode ? "ERROR_CODE_" + data.errorCode : defaultErrorMessage;
              enqueueSnackbar(translate(errorMessage, e, "NOTIFICATION_ERROR"), { variant: "error" });
            }).catch(error => {
              enqueueSnackbar(translate(serviceConfig.defaultErrorMessageKey, e, "NOTIFICATION_ERROR"), { variant: "error" });
            })

          }
        });
    }
  };
  useEffect(() => {
    if (auto) {
      send(params, paramsUrl);
    }
  }, [auto]);

  return { send, result, loading };
}
