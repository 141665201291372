import Box from '@material-ui/core/Box';
import React from "react";
import ImageFadeIn from "react-image-fade-in";
import translate from "../../../../lang/translate";

export default function NotFound() {
  return (
    <div className="flex flex-center flex-middle w-100 h-100vh">
      <div className="flex flex-column flex-center flex-middle">
        <Box display="flex" justifyContent="center">
          <ImageFadeIn
            src="/assets/images/illustrations/404.svg"
            alt=""
            height="320px"
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <h5 className="mt-10 font-weight-500 text-muted">{translate("TEXT_404")}</h5>
        </Box>
      </div>
    </div>
  );
};