import _ from "lodash";
import React, { useEffect, useState } from "react";
// import Icon from "./../../modules/components/generic/Icon";
import ImageFadeIn from "react-image-fade-in";
// import InputBase from "@material-ui/core/InputBase";
// import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import IconComponent from "../../../modules/components/generic/Icon";
import ManagerAccess from "../../../modules/meeting/components/ManagerAccess";
//import ReactTooltip from "react-tooltip";
import constants from "../../../constants/index.js";
//import { bindActionCreators } from "redux";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
// import classnames from "classnames";
// import Button from "./Button";
import {
	mutePeer, setAsking,
	setSpeaker
} from "../../../redux/stateActions";
import helper from "../../../utils/helpers";
// import Text from "../Text/Text";
// import Popover from "@material-ui/core/Popover";
import Avatar from "../../../modules/components/generic/Avatar";
//mport ManagerAccess from "./ManagerAccess";
import Tooltip from "../../../modules/components/help/Tooltip";
import MoreButton from "./../../../modules/components/buttons/MoreButton";
import ListItem from "./../../../modules/components/menu/ListItem";
import ModalKickPeer from "./ModalKickPeer";

const RowContact = ({ roomClient, peer }) => {
	const dispatch = useDispatch();
	const room = useSelector((store) => store.room);
	const officialLang = _.get(room, "info.officialLang", "es");
	const consumersArray = useSelector((store) => {
		return _.get(peer, "consumers", []).map((consumerId) => store.consumers[consumerId]);
	});
	const [audioConsumer, setAudioConsumer] = useState();
	const [audioEnabled, setAudioEnabled] = useState(false);
	const [videoShareConsumer, setVideoShareConsumer] = useState();

	useEffect(() => {
		setAudioConsumer(consumersArray.find((consumer) => consumer.track.kind === 'audio' && !consumer.share));
		setVideoShareConsumer(consumersArray.find((consumer) => consumer.track.kind === 'video' && consumer.share));
		setAudioEnabled(Boolean(audioConsumer) &&
			!audioConsumer.locallyPaused &&
			!audioConsumer.remotelyPaused);
	}, [consumersArray])

	useEffect(() => {
		setAudioEnabled(Boolean(audioConsumer) &&
			!audioConsumer.locallyPaused &&
			!audioConsumer.remotelyPaused);
	}, [audioEnabled])

	

	const handleSetSpeaker = (peer) => {
		dispatch(setSpeaker(peer.id));
	};
	const [openModalKick, setOpenModalKick] = useState(false);

	const openKickPeer = () => {
		setOpenModalKick(true)
	};

	const closeKickPeer = () => {
		setOpenModalKick(false)
	};

	const handleMutePeer = () => {
		dispatch(mutePeer(peer.id));
	};

	return (
		<div data-component={"RowContact"}>
			<div className="avatar-container">
				<Avatar name={peer.displayName} size={"48px"} />
			</div>
			<div className="cont1">
				<div className="contbtn">
					<Tooltip title={helper.toTitleCase(peer.displayName)} placement="top">
						<label className="top-spacer">{helper.longTextToSmall(helper.toTitleCase(peer.displayName), 26)}</label>
					</Tooltip>
				</div>
				<div className="contbtn2">
					<div className="swith-container">
						<div className="spacer-indicator">
							<Tooltip title={`LANG_${peer.peerLang.toUpperCase()}`}>
								<ImageFadeIn
									alt=""
									width="16"
									height="16"
									src={`/room/images/flags-iso/flat/16/${peer.peerLang.toUpperCase()}.png`}
								/>
							</Tooltip>
						</div>
						{peer.handUp && (
							<div className="spacer-indicator parpadeo">
								<IconComponent
									custom={true}
									children="handup"
									size={16}
									color="#4285F4"
								>
								</IconComponent>
							</div>
						)}
						{!peer.isMe &&(peer.asking || peer.peerType !== constants.PEERS_TYPES.INVITED) && !audioEnabled && (
							<div className="spacer-indicator">
								<IconComponent
									custom={true}
									children="micmateoff"
									size={16}
									color="#FF4F30"
								>
								</IconComponent>
							</div>
						)}
						{videoShareConsumer && (
							<div className="spacer-indicator">
								<IconComponent
									custom={true}
									children="screenshare"
									size={16}
									color="#4285F4"
								>
								</IconComponent>
							</div>
						)}
					</div>
				</div>
			</div>
			<ManagerAccess action="CONTACT_ACTIONS">
				<div className="btnscontainer">
					<MoreButton
						options={[
							<ListItem
								show={
									peer.peerType !== constants.PEERS_TYPES.SPEEKER &&
									peer.asking !== true &&
									peer.peerType !== constants.PEERS_TYPES.INTERPRETER
								}
								onClick={() => {
									dispatch(setAsking(peer.id, peer.isMe));
								}}
								icon={<IconComponent
									custom={true}
									children="palabra2"
									size={20}
								>
								</IconComponent>}
								text={translate("TEXT_MENU_ENABLED_PARTICIPANT")}
							/>,
							<ListItem
								show={
									peer.peerType !== constants.PEERS_TYPES.SPEEKER &&
									peer.asking === true &&
									peer.peerType !== constants.PEERS_TYPES.INTERPRETER
								}
								onClick={() => {
									dispatch(setAsking(peer.id, peer.isMe));
								}}
								icon={<IconComponent
									custom={true}
									children="listener"
									size={20}
								>
								</IconComponent>}
								text={translate("TEXT_MENU_DISABLED_PARTICIPANT")}
							/>,
							<ListItem
								show={
									peer.peerType !== constants.PEERS_TYPES.SPEEKER &&
									peer.peerType !== constants.PEERS_TYPES.INTERPRETER &&
									peer.peerLang === officialLang
								}
								onClick={() => {
									handleSetSpeaker(peer);
								}}
								icon={<IconComponent
									custom={true}
									children="orador"
									size={20}
								>
								</IconComponent>}
								text={translate("TEXT_MENU_ADD_SPEAKER")}
							/>,
							<ListItem
								show={
									peer.peerType === constants.PEERS_TYPES.SPEEKER &&
									peer.peerType !== constants.PEERS_TYPES.INTERPRETER
								}
								onClick={() => {
									handleSetSpeaker(peer);
								}}
								icon={<IconComponent
									custom={true}
									children="orador-off"
									size={20}
								>
								</IconComponent>}
								text={translate("TEXT_MENU_REMOVE_SPEAKER")}
							/>,
							<ListItem
								show={
									!peer.isMe &&
									(peer.peerType !== constants.PEERS_TYPES.INVITED ||
										(peer.peerType === constants.PEERS_TYPES.INVITED &&
											peer.asking))
								}
								onClick={() => {
									handleMutePeer(peer);
								}}
								icon={<IconComponent
									custom={true}
									children="micmateoff"
									size={20}
								>
								</IconComponent>}
								text={translate("TEXT_MENU_MUTE")}
							/>,
							<ListItem
								show={!peer.isMe}
								onClick={openKickPeer}
								icon={<IconComponent
									custom={true}
									children="exit"
									size={20}
								>
								</IconComponent>}
								text={translate("TEXT_MENU_EJECT")}
							/>,
						]}
					></MoreButton>
				</div>
			</ManagerAccess>
			<ModalKickPeer peer={peer} open={openModalKick} onClose={closeKickPeer} />
		</div>
	);
};

export default withRoomContext(RowContact);