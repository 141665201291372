import { Grid } from "@material-ui/core/";
import { LoaderBounce } from "matx";
import React, { useMemo } from "react";
import translate from "../../../../lang/translate";
import Card from "@material-ui/core/Card";
import { Button } from "@material-ui/core";
import ModalCustomer from "../Room/roomTypes/customer/components/Modal";
import Modal from "app/modules/components/generic/Modal.js";
import { PEERS_TYPES } from "app/constants";
import { useEffect } from "react";
import useEvent from "../Room/hooks/useEvent";
import { isMobile } from "react-device-detect";
import { useRef } from "react";

import WebcamAudio from "./WebcamAudio";


/**
 * Componente que muestra una pantalla de cargando mientras se cargan los datos de la sala
 * view es el tipo de pantalla que se va a mostrar
 *
 * @param {*} param0
 * @returns
 */
function OnlineSupportIntro({ handleFinishSubmit, role }) {
  const { openRoom } = useEvent();
  return (
    <React.Fragment>
      
      {role === PEERS_TYPES.CUSTOMER || isMobile ? (
        <ModalCustomer
          open={true}
          title={translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_TITLE")}
          subtitle={translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_SUBTITLE")}
          buttons={[
            <Button
              variant="contained"
              size="small"
              type="button"
              onClick={() => {
                openRoom();
                handleFinishSubmit({ enableAsking: false });
              }}
            >
              {translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_OFF")}
            </Button>,
            <Button
              variant="contained"
              size="small"
              type="button"
              color="primary"
              onClick={() => {
                openRoom();

                handleFinishSubmit({ enableAsking: true });
              }}
            >
              {translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_ON")}
            </Button>,
          ]}
        >
          
          <WebcamAudio></WebcamAudio>
          
        </ModalCustomer>
      ) : (
        <Modal open={true} srcImage="" buttons={[]}>
          <div className="flex flex-column flex-middle">
            <h2>{translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_TITLE")}</h2>
            <p>{translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_SUBTITLE")}</p>
            
              <WebcamAudio></WebcamAudio>
            
            <br />
            <div className="flex flex-center" style={{ gap: "1em" }}>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  openRoom();
                  handleFinishSubmit({ enableAsking: false });
                }}
              >
                {translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_OFF")}
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  openRoom();
                  handleFinishSubmit({ enableAsking: true });
                }}
                color="primary"
              >
                {translate("TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_ON")}
              </Button>
            </div>
          </div>
        </Modal>
      )} 
    </React.Fragment>
  );
}
export default OnlineSupportIntro;
