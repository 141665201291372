import React from "react";
import { Fab } from "@material-ui/core";
import IcoMoon from "react-icomoon";
import { Icon } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import translate from "../../../lang/translate";
import classnames from "classnames";

const iconSet = require("../../../../styles/icons/selection.json");

const FloatButton = ({...props }) => {
  return (
    <div data-component="FloatButton">
      <div
        className={classnames({
          "right-with-panel": props.translate,
          "left": props.useLeft,
          "right": !props.useLeft,
          "left-mobile": props.browserMobile && props.useLeft,
          "right-mobile": props.browserMobile && !props.useLeft,
        })}
      >
        <Tooltip
          title={translate(props.toolTip)}
          placement={props.useLeft ? "right" : "left"}
        >
          <Fab
            color="primary"
            className={classnames({
              "float-button-desing": !props.browserMobile,
              "float-button-desing-mobile": props.browserMobile,
            })}
            onClick={(e) => {
              props.action(e);
            }}
          >
            {props.useIncoMoon !== undefined ? (
              <IcoMoon
                iconSet={iconSet}
                color="#ffffff"
                size={props.iconSize === undefined ? 28 : props.iconSize}
                icon={props.iconString}
              />
            ) : (
              <Icon
                style={{
                  fontSize: 28,
                }}
              >
                {props.iconString}
              </Icon>
            )}
          </Fab>
        </Tooltip>
      </div>
    </div>
  );
};

export default FloatButton;
