import React, { useCallback } from "react";
import translate from "app/lang/translate";
import { useSnackbar } from "notistack";
import useRoomStatus from "./useRoomStatus";

function useNotification() {
  const { enqueueSnackbar } = useSnackbar();
  const sendChangeLangNotification = () => {
    enqueueSnackbar(translate("CHANGED_LANG_NOTIFICATION"), {
      style: {
        backgroundColor: "#394364",
        width: "180px",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
      },
      autoHideDuration: 2000,
      key: "changedLangNotification",
      preventDuplicate: true,
    });
  };

  return {
    sendChangeLangNotification,
  };
}

export default useNotification;
