import { useCallback } from "react";
import { ACTIONS_ON_PARENT } from "app/constants/index.js";
import useIframeParent from "app/modules/hooks/useIframeParent.js";

const useEvent = () => {
  const { sendMessage } = useIframeParent();
  const closeRoom = useCallback((payload) => {
    sendMessage(ACTIONS_ON_PARENT.CLOSE_ROOM, payload);
  }, []);
  const openRoom = useCallback((payload) => {
    sendMessage(ACTIONS_ON_PARENT.OPEN_ROOM, payload);
  }, []);
  const dragResizeOn = useCallback((payload) => {
    sendMessage(ACTIONS_ON_PARENT.DRAG_RESIZE_ON, payload);
  }, []);
  const dragResizeOff = useCallback((payload) => {
    sendMessage(ACTIONS_ON_PARENT.DRAG_RESIZE_OFF, payload);
  }, []);
  const dragResizeButton = useCallback((payload) => {
    sendMessage(ACTIONS_ON_PARENT.DRAG_RESIZE_BUTTON, payload);
  }, []);
  const closeWindow = useCallback((payload) => {
    sendMessage(ACTIONS_ON_PARENT.CLOSE_WINDOW, payload);
  }, []);
  return {
    closeRoom,
    openRoom,
    dragResizeOn,
    dragResizeOff,
    dragResizeButton,
    closeWindow,
  };
};
export default useEvent;
