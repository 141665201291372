import _ from "lodash";
import React, {
	forwardRef,
	useImperativeHandle, useState
} from "react";
import ReactHtmlParser from 'react-html-parser';
import Scrollbar from "react-perfect-scrollbar";
import { useSelector } from 'react-redux';
import translate from "../../../lang/translate";
import Button from "./../../../modules/components/buttons/Button";
import Modal from "./../../../modules/components/generic/Modal";
const ModalTerms = forwardRef(
	({ roomClient, peer, onClose, ...props }, ref) => {
		const termsAndConditions = useSelector((store) => {
			return _.get(store,"session.client.termsAndConditions",":A:A:A")
		});
		const [open, setOpen] = useState(false);
		
		const openModal = () => {
			setOpen(true);
		};
		const closeModal = () => {
			setOpen(false);
		};
		useImperativeHandle(ref, () => ({
			open() {
				openModal();
			},
			close() {
				closeModal();
			},
		}));
		return (

			<Modal
				open={open}
				title={"MODAL_TERMS_TITLE"}
				subtitle={"MODAL_TERMS_SUBTITLE"}
				srcImage={"/assets/images/illustrations/terms.svg"}
				buttons={[
					<Button variant="contained" type="button" color={"primary"} onClick={closeModal}>
						{translate("TEXT_BUTTON_CLOSE")}
					</Button>
				]}
				onClose={closeModal}
			>
				<React.Fragment>
					<div data-component={"ModalTerms"}>
						<Scrollbar className="content">
							{ReactHtmlParser(termsAndConditions)}
						</Scrollbar>
					</div>
				</React.Fragment>
			</Modal>
		)
	})
export default ModalTerms
