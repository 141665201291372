import { Card, Grid } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import ImageFadeIn from "react-image-fade-in";
import helper from "../../../../utils/helpers";
import InterpreterForm from "../../../appRoom/forms/InterpreterForm";
import TopDetail from "../../../components/decorators/TopDetail";
import translate from "../../../../lang/translate";
import WebcamAudio from "./WebcamAudio";
function StandardDefaultRoomIntro({
  isMobile,
  client,
  room,
  handleFinishSubmit,
}) {
  return (
    <React.Fragment>
      <div className="signup flex flex-center w-100 h-100vh">
        <div className={isMobile ? "pl-8 pr-8" : "special-width-card"}>
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div
                  className={`p-32 flex flex-center flex-middle h-100${
                    isMobile ? " frame-detail-mobile" : ""
                  }`}
                >
                  <ImageFadeIn
                    src={_.get(client, "logo")}
                    alt=""
                    onError={(e) => {
                      e.target.src = `/assets/logos/solunika.png`;
                    }}
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div
                  className={`h-100${
                    isMobile ? " pl-20 pr-20 pb-20" : " p-20 frame-detail"
                  }`}
                >
                  <TopDetail
                    title="TEXT_ROOM_INIT"
                    subtitle="TEXT_ROOM_INIT_SUBTITLE"
                    srcImage={
                      isMobile
                        ? ""
                        : "/assets/images/illustrations/InterpreterDetail.svg"
                    }
                    isMobil={isMobile}
                  />

                  <div className="mb-10 recuadre">
                    <div className="room-name">
                      {translate("TEXT_ROOM_NAME_INFO")}:
                    </div>
                    <b className="room-name text-brand">
                      {_.get(room, "title") === "" ||
                      _.get(room, "title") === undefined
                        ? translate("TEXT_ROOM_UNTITLED")
                        : helper.formatText(_.get(room, "title"), true)}
                    </b>
                    <div className="room-name">
                      {translate("TEXT_ROOM_DESCRIPTION_INFO")}:
                    </div>
                    <b className="room-name text-brand">
                      {_.get(room, "description") === "" ||
                      _.get(room, "description") === undefined
                        ? translate("TEXT_ROOM_NO_DESCRIPTION")
                        : helper.formatText(_.get(room, "description"), true)}
                    </b>
                  </div>

                  <React.Fragment>
                    <WebcamAudio/>
                    <h5 className={"mb-24 mt-24"}>
                      {translate("TEXT_ROOM_DATA_INIT_INTERPRETER")}
                    </h5>
                    <InterpreterForm onFinishSubmit={handleFinishSubmit} />
                  </React.Fragment>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
export default StandardDefaultRoomIntro;
