import React, { useContext } from "react";
import { Button } from "@material-ui/core";
//import _ from "lodash";
import FormContext from "../../forms/FormContext";

const ButtonComponent = ({ children, ...props }) => {
  const formState = useContext(FormContext);
  const { isSubmitting, isValid } = formState;
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={isSubmitting || !isValid}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
