import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import constants from "../../../../../../../constants";
import translate from "../../../../../../../lang/translate";
import IconComponent from "../../../../../../components/generic/Icon";
import ApngComponent from "react-apng";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

const StatusBar = ({
  roomClient,
  showDescriptions = true,
  showPersonIcon = true,
}) => {
  const room = useSelector((store) => store.room);
  const me = useSelector((store) => store.me);
  const cantPeers = useSelector((store) => {
    let peersToCount = [];
    if (store.me.peerType === constants.PEERS_TYPES.SPEEKER) {
      peersToCount = _.filter(store.peers, (peer) => {
        return (
          peer.peerType !== constants.PEERS_TYPES.INTERPRETER &&
          peer.peerType !== constants.PEERS_TYPES.SPEEKER
        );
      });
      return peersToCount.length;
    }
    if (store.me.peerType === constants.PEERS_TYPES.INTERPRETER) {
      peersToCount = _.filter(store.peers, (peer) => {
        return (
          peer.peerType == constants.PEERS_TYPES.INVITED &&
          peer.peerLang == me.peerLang
        );
      });
      return peersToCount.length;
    }
    return null;
  });

  const getTitleHandUp = () => {
    if (!room.handUp) return "TOOLTIP_STOP_RAISE_HAND";
    if (room.handUp) return "TOOLTIP_RAISE_HANDUP";
  };

  const audioProducer = useSelector((store) => {
    const producersArray = Object.values(store.producers);
    return producersArray.find((producer) => producer.track.kind === "audio");
  });

  const getIconConfig = () => {
    if (!me.asking && me.peerType == constants.PEERS_TYPES.INVITED)
      return { icon: "listener", color: "#3366FF", text: "YOUARE_LISTENER" };
    if (me.asking && me.peerType === constants.PEERS_TYPES.INVITED)
      return { icon: "palabra2", color: "#fd7e14", text: "CAN_TALK" };
    if (me.peerType === constants.PEERS_TYPES.INTERPRETER)
      return {
        icon: "traductor",
        color: "#fdf623",
        text: "YOUARE_INTERPRETER",
        conector: "TEXT_CONECTOR2",
        role: "ROLES_INVITED",
        plural: "TEXT_ADD_PLURAL2",
      };
    if (me.peerType === constants.PEERS_TYPES.SPEEKER)
      return {
        icon: "orador",
        color: "#10c304",
        text: "YOUARE_SPEAKER",
        conector: "TEXT_CONECTOR1",
        role: "ROLES_INVITED",
        plural: "TEXT_ADD_PLURAL2",
      };
  };

  const disDisabledMic = !(
    me.canSendMic &&
    audioProducer &&
    !audioProducer.paused
  );
  const enabledMic =
    me.peerType === constants.PEERS_TYPES.SPEEKER ||
    me.peerType === constants.PEERS_TYPES.INTERPRETER ||
    me.asking;

  return (
    <div data-component="StatusBar">
      <div className="conected-status-area2">
        <div className={room.state === "connected" ? "" : "animate-hide"}>
          {showDescriptions ? (
            <Tooltip title={translate("TOOLTIP_CONNECTED")}>
              <ApngComponent
                autoPlay={true}
                src="/assets/animations/online.png"
                className="animate-size"
              />
            </Tooltip>
          ) : (
            <ApngComponent
              autoPlay={true}
              src="/assets/animations/online.png"
              className="animate-size"
            />
          )}
        </div>
        <div className={room.state === "connected" ? "animate-hide" : ""}>
          {showDescriptions ? (
            <Tooltip title={translate("TOOLTIP_DISCONNECTED")}>
              <ApngComponent
                autoPlay={true}
                src="/assets/animations/connecting.png"
                className="animate-size3"
              />
            </Tooltip>
          ) : (
            <ApngComponent
              autoPlay={true}
              src="/assets/animations/connecting.png"
              className="animate-size3"
            />
          )}
        </div>
      </div>
      <div
        className={classnames("conected-status-area", {
          "animate-hide": getTitleHandUp() === "TOOLTIP_STOP_RAISE_HAND",
        })}
      >
        <div>
          <ApngComponent
            autoPlay={true}
            src="/assets/animations/hand.png"
            className="animate-size2"
          />
        </div>
        {showDescriptions && (
          <div className="text-status">{translate(getTitleHandUp())}</div>
        )}
      </div>
      {showPersonIcon && (
        <div className="conected-status-area">
          <div>
            <IconComponent
              custom={true}
              children={getIconConfig().icon}
              size={20}
              color={getIconConfig().color}
            ></IconComponent>
          </div>
          {showDescriptions && (
            <div className="text-status">
              {translate(getIconConfig().text)}
              {cantPeers !== null && cantPeers !== 0 ? (
                <span>
                  &nbsp;{translate(getIconConfig().conector)}
                  <br></br>
                  {cantPeers}
                  {cantPeers > 1 ? (
                    <React.Fragment>
                      &nbsp;{translate(getIconConfig().role).toLowerCase()}
                      {translate(getIconConfig().plural)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      &nbsp;{translate(getIconConfig().role).toLowerCase()}
                    </React.Fragment>
                  )}
                </span>
              ) : null}
            </div>
          )}
        </div>
      )}
      {showPersonIcon && enabledMic && disDisabledMic && (
        <div className="conected-status-area">
          <div>
            <IconComponent
              custom={true}
              children="micmateoff"
              size={20}
              color="#FF4F30"
            ></IconComponent>
          </div>
          {showDescriptions && (
            <div className="text-status">{translate("TEXT_MIC_OFF")}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default StatusBar;
