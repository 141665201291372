
import _ from "lodash"
import DashboardPage from "../modules/dashboard/views/DashboardPages";
import RoomPages from "../modules/room/views/RoomPages";
import CompaniesPages from "../modules/companies/views/CompaniesPages";
import SessionPages from "../modules/session/views/SessionPages";

import ActivityLogPages from "./activityLog/Pages";
import MonitorPages from "./monitor/Pages";
import UsersPage from "./users/Pages";



let pages = {};
_.merge(pages,
    DashboardPage,
    RoomPages,
    CompaniesPages,
    SessionPages,
    MonitorPages,
    UsersPage,
    ActivityLogPages,
);


let getUriPage = (pageKey) => {
    return _.get(pages, `${pageKey}.uri`);
}
let hasAccessByPath = (path, access) => {
    if (path) {
        if (path.indexOf("/admin/") < 0 && path.indexOf("/dev/") < 0) {
            return true;
        }
        if (path.indexOf("/dev/") >= 0) {
            return process.env.REACT_APP_NODE_ENV === "dev";
        }
        if (path.indexOf("/admin/") >= 0) {
            let uri = path.substring(7, path.length);
            uri = uri.replace(/\//g, ".");
            return hasAccessAction(uri, access);
        }
    }
    return false;
}
let hasAccessAction = (action, access = []) => {
    return access.length && access.some(a => a === action)
}
let getDefaultPageByUser = (user, access) => {
    return getUriPage("module.dashboard.room");
}


export {
    getDefaultPageByUser,
    hasAccessByPath,
    hasAccessAction,
    getUriPage
}