import React from "react";
import Box from '@material-ui/core/Box';
import translate from "../../../lang/translate";
import ImageFadeIn from "react-image-fade-in";

export default function WaitingState({
  ...props
}) {
  return (
    <div data-component="WaitingState" className="p-60 flex flex-center flex-middle flex-column">
      <Box display="flex" justifyContent="center">
        <ImageFadeIn
          src="/assets/images/illustrations/waitingState.svg"
          alt=""
          //height="320px"
          height={props.isMobile ? "220px" : "320px"}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <h5 className="mt-10 font-weight-500 text-muted" align="center">{translate("TEXT_WAITING_STATE")}</h5>
      </Box>
    </div>
  );
};