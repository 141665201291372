import { Fab } from "@material-ui/core";
//import { Icon, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SendIcon from '@material-ui/icons/Send';
import classnames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { isMobile } from 'react-device-detect';
import ReactHtmlParser from "react-html-parser";
import Scrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import Avatar from "../../../modules/components/generic/Avatar";
import NotAvaibleState from "../../../modules/components/generic/NotAvaibleState";
//import Contacts from "./Contacts";
//import { bindActionCreators } from "redux";
//import ReactMarkdown from "react-markdown";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
//import Tooltip from "../../modules/components/help/Tooltip";
//import { useSelector, useDispatch } from "react-redux";
import { toogleChat } from "../../../redux/actions/roomActions";
import helper from "../../../utils/helpers";

const BotMessageRegex = new RegExp("^@bot (.*)");
class ChatInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      openContacts: false,
    };

    // TextArea element got via React ref.
    // @type {HTMLElement}
    this._textareaElem = null;
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      //this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "start" });
      var div = document.getElementById("scrollchat");
      div.scrollTop = div.scrollHeight - div.clientHeight;
    }
  };

  handleFocus = () => {
    this._textareaElem.focus();
  };

  componentDidMount() {
    this.scrollToBottom();
    this.handleFocus();
  }

  componentDidUpdate() {
    this.scrollToBottom();
    this.handleFocus();
  }

  render() {
    const { connected, chatDataProducer, chat, me } = this.props;

    const { text, openContacts } = this.state;

    const disabled = !connected || !chatDataProducer;

    return (
      <div data-component="ChatInput">
        <div className="contentTitle">
          {translate("TEXT_CHAT_PANEL")}
        </div>
        <div className="div_iz">
          <Scrollbar className="converse_messages" id="scrollchat">
            {disabled ? (
              <NotAvaibleState />
            ) : (
              <div>
                {chat.messages.map((message, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div
                        className={classnames("msg", {
                          "right-msg": message.iam,
                          "left-msg": !message.iam,
                        })}
                        ref={(el) => {
                          this.messagesEnd = el;
                        }}
                      >
                        <div
                          className={classnames({
                            "space-left": message.iam,
                            "space-right": !message.iam,
                          })}
                        >
                          <Avatar
                            size={48}
                            name={message.peer.displayName}
                            color={message.iam ? "#222A45" : "#3366FF"}
                          />
                        </div>
                        <div className="msg-bubble">
                          <div className="msg-info">
                            <div className="msg-info-name text-muted">
                              {message.peer.displayName} {translate("TEXT_SAY")}
                            </div>
                            <div className="msg-info-time text-muted">
                              <ScheduleIcon />
                              <span className="clock-pos">
                                &nbsp;{helper.getNowTime()}
                              </span>
                            </div>
                          </div>
                          {helper.isHtml(message.text) ? (
                            ReactHtmlParser(
                              helper.formatText(message.text, undefined, true)
                            )
                          ) : (
                            message.text
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </Scrollbar>
          <div className={disabled ? "hide-pan" : "converse_footer"}>
            <div className="txt-input">
              <TextField
                variant="outlined"
                label={isMobile ? translate("WRITE_HERE_MOBILE") : translate("WRITE_HERE_WEB")}
                fullWidth={true}
                ref={(elem) => {
                  this._textareaElem = elem;
                }}
                dir="auto"
                autoComplete="off"
                disabled={disabled}
                value={text}
                onChange={this.handleChange.bind(this)}
                onKeyPress={this.handleKeyPress.bind(this)}
              />
            </div>
            <div className={isMobile ? "btn-send-container" : "hide-pan"}>
              <Fab
                color="primary"
                className="float-button-desing-mobile"
                onClick={(e) => {
                  this.handleSendBtn();
                }}
              >
                <SendIcon />
              </Fab>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange(event) {
    const text = event.target.value;
    this.setState({ text });
  }

  handleKeyPress(event) {
    if (event.key !== "Enter" || event.shiftKey || event.ctrlKey) return;
    event.preventDefault();
    this.handleSendBtn();
  }

  handleSendBtn() {
    let text = this.state.text.trim();
    if (text) {
      const { roomClient } = this.props;
      const match = BotMessageRegex.exec(text);
      if (!match) {
        // Chat message.
        text = text.trim();
        roomClient.sendChatMessage(text);
      } else {
        // Message to the bot.
        text = match[1].trim();
        roomClient.sendBotMessage(text);
      }
    }
    this.setState({ text: "" });
  }

}

ChatInput.propTypes = {
  roomClient: PropTypes.any.isRequired,
  connected: PropTypes.bool.isRequired,
  chatDataProducer: PropTypes.any,
  botDataProducer: PropTypes.any,
  chat: PropTypes.any,
};

const mapStateToProps = (state) => {
  const dataProducersArray = Object.values(state.dataProducers);
  const chatDataProducer = dataProducersArray.find(
    (dataProducer) => dataProducer.label === "chat"
  );
  return {
    connected: state.room.state === "connected",
    chatDataProducer,
    chat: _.get(state, "chat", []),
    me: state.me,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toogleChat: () => {
      toogleChat()(dispatch);
    },
  };
};

const ChatInputContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps)(ChatInput)
);

export default ChatInputContainer;
