//import React, { useEffect, useState } from "react";
import classnames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import constants from "../../../constants/index.js";
import Me from "./Me";
import Peer from "./Peer";
import PeerName from "./PeerName";
import { Appear } from "./transitions";

const ViewGrid = ({
  peers,
  allPeers,
  askingPeers,
  mode = "grid",
  classes = "",
  ...props
}) => {
  const cantElement = peers.length;
  const user = useSelector((store) => store.api.me);
  const me = useSelector((store) => store.me);
  const room = useSelector((store) => store.room);
  const [view, setView] = useState(mode);
  const canListen = (user, peer, asking) => {
    const officialLang = _.get(room, "info.officialLang", "es");
    switch (me.peerType) {
      case constants.PEERS_TYPES.SPEEKER:
        return (
          // Orador que hable mi idioma (Ok)
          // Participante que hable mi idioma y tenga la palabra (Ok)
          // Traductor  con un participante en su idioma
          (peer.peerType === constants.PEERS_TYPES.SPEEKER &&
            peer.peerLang === me.peerLang) ||
          (peer.peerType === constants.PEERS_TYPES.INVITED &&
            peer.asking &&
            peer.peerLang === me.peerLang) ||
          (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
            allPeers.some(
              (i) =>
                i.peerType === constants.PEERS_TYPES.INVITED &&
                i.asking &&
                i.peerLang === peer.peerLang
            ))
        );
      case constants.PEERS_TYPES.INTERPRETER:
        return (
          // Orador en idioma de la sala (OK)
          // Invitado que hable en el idoma del interprete y tenga la palabr (Ok)
          // Interperete que tenga algun participante en su idioma activo (Pendiente de prueba)
          (peer.peerType === constants.PEERS_TYPES.SPEEKER &&
            peer.peerLang === officialLang) ||
          (peer.peerType === constants.PEERS_TYPES.INVITED &&
            peer.asking &&
            (peer.peerLang === me.peerLang ||
              peer.peerLang === officialLang)) ||
          (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
            allPeers.some(
              (i) =>
                ((i.peerType === constants.PEERS_TYPES.INVITED && i.asking) ||
                  i.peerType === constants.PEERS_TYPES.SPEEKER) &&
                i.peerLang === peer.peerLang
            ))
        );

      case constants.PEERS_TYPES.INVITED:
        // Escucha al oradro si tiene mi idioma (Ok )
        // Esucha a un invitado con la palbra y que hable mi idoma (ok)
        // Escucha a un interprete que hable en mi idioma (ok)
        // Si yo hablo el idioma de la sala y Interpete tiene algun participante que hable en su idioma y tenga la palabra(Ok)

        return (
          (peer.peerType === constants.PEERS_TYPES.SPEEKER &&
            peer.peerLang === me.peerLang) ||
          (peer.peerType === constants.PEERS_TYPES.INVITED &&
            peer.asking &&
            peer.peerLang === me.peerLang) ||
          (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
            peer.peerLang === me.peerLang) ||
          (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
            me.peerLang == officialLang &&
            allPeers.some(
              (i) =>
                i.peerType === constants.PEERS_TYPES.INVITED &&
                i.asking &&
                i.peerLang === peer.peerLang
            ))
        );
      default:
        return false;
    }
  };

  return (
    <div data-component="ViewGrid" className={classes}>
      <div
        className={classnames("container", `element_${cantElement}`, {
          grid: view == "grid",
          column: view == "column",
          row: view == "row",
          mobile: view == "mobile",
          customer: view == "customer",
          demo: props.demo,
        })}
      >
        {peers.map((peer, i) => {
          if (props.demo) {
            return <div>{peer}</div>;
          }
          return (
            <Appear key={peer.id} duration={1000}>
              <React.Fragment>
                <div className={classnames("elementView", peer.peerType)}>
                  <div>
                    {!peer.isMe ? (
                      <React.Fragment>
                        <Peer
                          id={peer.id}
                          audioMuted={!canListen(user, peer, askingPeers)}
                          enabledButtons={!(view === "customer")}
                        />
                        <PeerName
                          peer={peer}
                          transformlabel={props.transformlabel}
                          view={view}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Me fullScreen={true} />

                        <PeerName
                          peer={peer}
                          transformlabel={props.transformlabel}
                          view={view}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            </Appear>
          );
        })}
      </div>
    </div>
  );
};

export default ViewGrid;
