const user = window.localStorage.getItem("user");
const initialState = {
  token: window.localStorage.getItem("token"),
  me: user && user !== "undefined" ? JSON.parse(user) : null,
  currentRoom: null,
  rooms: [],
  langs: [],
  users: [],
  access: user && user !== "undefined" ? JSON.parse(user).access : [],
  client: null,
};

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN": {
      const { token, user } = action.payload;
      return { ...state, me: user, token: token };
    }
    case "SET_CURRENT_SPEAKER": {
      const { peerId, peerType } = action.payload;
      const { me } = state;
      return {
        ...state,
        me: { ...me, peerType: state.id === peerId ? "speeker" : "invited" },
      };
    }
    case "TOGGLE_ASKING": {
      const { peerId, isAsking } = action.payload;
      const { me } = state;
      if (peerId === state.id) {
        return { ...state, me: { ...me, asking: isAsking } };
      }
      return state;
    }
    case "TOGGLE_SPEAKER": {
      const { peerId, peerType } = action.payload;
      const { me } = state;
      if (peerId === state.id) {
        return { ...state, me: { ...me, peerType: peerType } };
      }
      return state;
    }
    case "SET_ME": {
      const {
        peerId,
        displayName,
        displayNameSet,
        device,
        peerLang,
        peerType,
        asking,
      } = action.payload;
      return {
        ...state,
        me: {
          ...state.me,
          id: peerId,
          displayName,
          displayNameSet,
          device,
          peerLang,
          peerType,
          asking,
        },
      };
    }
    case "SET_USERS": {
      const { users } = action.payload;
      return { ...state, users };
    }
    case "SET_ALL_ROOMS": {
      const { rooms } = action.payload;
      return { ...state, rooms };
    }
    case "SET_CREATED_ROOM": {
      const { room } = action.payload;
      return { ...state, rooms: [...state.rooms, room] };
    }
    case "SET_CURRENT_ROOM": {
      const { currentRoom } = action.payload;
      return { ...state, currentRoom };
    }
    case "SET_LANGS": {
      const { langs } = action.payload;
      return { ...state, langs };
    }
    default:
      return state;
  }
};

export default apiReducer;
