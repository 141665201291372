

export default {
  module:{
    dashboard:{
      room: {
        uri:"/admin/module/dashboard/room",
      }
    }
  }
};