
import { yupResolver } from '@hookform/resolvers';
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputText from "../../components/forms/InputText";
import translate from "../../../lang/translate";
import Link from "../../components/links/Link";
import configForm from "../../forms/configForm";
import { getUriPage } from "../../../views/RootPages";
import history from "history.js";
let ParticipantForm = (props) => {
  let newSchema = {};
  _.get(props, "room.participantDataFields", []).map((field) => {
    newSchema[field.name] = yup.string();
    if (field.type == "email") {
      newSchema[field.name] = newSchema[field.name].email();
    }
    if (field.required) {
      newSchema[field.name] = newSchema[field.name].required();
    }
  });
  const schema = (yup.object().shape({
    displayName: yup.string().required(),
    "participantData": yup.object().shape({
      ...newSchema
    })
  }));
  // const [schema, setSchema] = useState(null);
  const { register, handleSubmit, watch, errors, control, getValues, setValue, formState, resolver } = useForm(configForm.defaultConfigForm({
    resolver: yupResolver(schema),
  }));
  const participantDataFields = _.get(props, "room.participantDataFields", []);
  const { isSubmitting, isValid } = formState;
  const onSubmit = data => {
    props.onFinishSubmit(data)
  }
  const goToLogin = () => {
    history.push({
      pathname: getUriPage("session.signIn")
    });
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        name="displayName"
        inputRef={register}
        type="text"
        placeholder={translate("INPUT_YOUR_NAME_PLACEHOLDER")}
        label={translate("INPUT_YOUR_NAME")}
        className="mb-8 w-100"
      />
      {participantDataFields.map((field, i) => {
        return (
          <InputText
            key={i}
            name={`participantData.${field.name}`}
            inputRef={register}
            required={field.required}
            type={field.type ? field.type : "text"}
            label={field.label}
            className="mb-8 w-100"
          />
        );
      })}
      <div className={"flex"}>
        <div className={"flex flex-start flex-middle w-100"}>
          <Link onClick={goToLogin}>{translate("TEXT_INTRO_GO_TO_LOGIN")}</Link>
        </div>
        <div className={"flex flex-end flex-middle"}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            {translate("TEXT_LOGIN")}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default ParticipantForm;