import React from "react";

function MenuTopSpacer() {
  return (
    <div data-component="MenuTopSpacer">
      <div className="spacer"></div>
    </div>
  );
}

export default MenuTopSpacer;
