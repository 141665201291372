// import { useSelector } from "react-redux";
// import PropTypes from "prop-types";
import classnames from "classnames";
import React from "react";
import ImageFadeIn from "react-image-fade-in";
// import _ from "lodash";
import IconComponent from "../../../modules/components/generic/Icon";
import constants from "../../../constants";
//import Fab from '@material-ui/core/Fab';
import helper from "../../../utils/helpers";
// import constants from "../../constants/index.js";
import Tooltip from "./../../../modules/components/help/Tooltip";
import translate from "./../../../lang/translate";

const PeerName = ({ peer, ...props }) => {
  const { view = undefined } = props;
  const getIconConfig = (peerPass) => {
    if (!peerPass.asking && peerPass.peerType === constants.PEERS_TYPES.INVITED)
      return { icon: "listener", color: "#3366FF" };
    if (peerPass.asking && peerPass.peerType === constants.PEERS_TYPES.INVITED)
      return { icon: "palabra2", color: "#fd7e14" };
    if (peerPass.peerType === constants.PEERS_TYPES.INTERPRETER)
      return { icon: "traductor", color: "#fdf623" };
    if (peerPass.peerType === constants.PEERS_TYPES.SPEEKER)
      return { icon: "orador", color: "#10c304" };
  };

  const getBorderColor1 = (peerPass) => {
    if (
      !peerPass.asking &&
      peerPass.peerType === constants.PEERS_TYPES.INVITED
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getBorderColor2 = (peerPass) => {
    if (
      peerPass.asking &&
      peerPass.peerType === constants.PEERS_TYPES.INVITED
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getBorderColor3 = (peerPass) => {
    if (peerPass.peerType === constants.PEERS_TYPES.INTERPRETER) {
      return true;
    } else {
      return false;
    }
  };

  const getBorderColor4 = (peerPass) => {
    if (peerPass.peerType === constants.PEERS_TYPES.SPEEKER) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div data-component="PeerName">
      <table
        cellPadding="0"
        cellSpacing="0"
        className={classnames({
          "new-bottom-indicator-special": props.transformlabel,
          "new-bottom-indicator": !props.transformlabel,
          customer: view === "customer",
        })}
      >
        <Tooltip
          title={helper.toTitleCase(peer.displayName)}
          placement="bottom"
        >
          <tbody>
            <tr>
              {view === "customer" ? (
                <React.Fragment>
                  <td
                    className={classnames("identificator-area", {
                      "is-listener": getBorderColor1(peer),
                      "is-has-word": getBorderColor2(peer),
                      "is-interpreter": getBorderColor3(peer),
                      "is-speaker": getBorderColor4(peer),
                    })}
                  >
                    {helper.longTextToSmall(
                      helper.toTitleCase(peer.displayName),
                      26
                    )}
                  </td>
                  <td className={"icons"}>
                    <div
                      className={classnames("area-detail2", {
                        "is-listener": getBorderColor1(peer),
                        "is-has-word": getBorderColor2(peer),
                        "is-interpreter": getBorderColor3(peer),
                        "is-speaker": getBorderColor4(peer),
                      })}
                    >
                      <Tooltip
                        title={`LANG_${peer.peerLang.toUpperCase()}`}
                        placement="top"
                      >
                        <ImageFadeIn
                          alt=""
                          width="16"
                          height="16"
                          src={`/room/images/flags-iso/flat/16/${peer.peerLang.toUpperCase()}.png`}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <td className="spacer"></td>
                  <td className="icons">
                    <div
                      className={classnames("area-detail", {
                        "is-listener": getBorderColor1(peer),
                        "is-has-word": getBorderColor2(peer),
                        "is-interpreter": getBorderColor3(peer),
                        "is-speaker": getBorderColor4(peer),
                      })}
                    >
                      <Tooltip
                        title={`${translate("ROLES_" + peer.peerType)}`}
                        placement="top"
                      >
                        <IconComponent
                          custom={true}
                          children={getIconConfig(peer).icon}
                          size={18}
                          color="#000000"
                          className="icon-pos"
                        ></IconComponent>
                      </Tooltip>
                    </div>
                  </td>
                  <td
                    className={classnames("identificator-area", {
                      "is-listener": getBorderColor1(peer),
                      "is-has-word": getBorderColor2(peer),
                      "is-interpreter": getBorderColor3(peer),
                      "is-speaker": getBorderColor4(peer),
                    })}
                  >
                    {helper.longTextToSmall(
                      helper.toTitleCase(peer.displayName),
                      26
                    )}
                  </td>
                  <td className="icons">
                    <div
                      className={classnames("area-detail2", {
                        "is-listener": getBorderColor1(peer),
                        "is-has-word": getBorderColor2(peer),
                        "is-interpreter": getBorderColor3(peer),
                        "is-speaker": getBorderColor4(peer),
                      })}
                    >
                      <Tooltip
                        title={`LANG_${peer.peerLang.toUpperCase()}`}
                        placement="top"
                      >
                        <ImageFadeIn
                          alt=""
                          width="16"
                          height="16"
                          src={`/room/images/flags-iso/flat/16/${peer.peerLang.toUpperCase()}.png`}
                        />
                      </Tooltip>
                    </div>
                  </td>
                  <td className="spacer"></td>
                </React.Fragment>
              )}
            </tr>
          </tbody>
        </Tooltip>
      </table>
    </div>
  );
};

export default PeerName;
