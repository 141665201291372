import React, { useEffect } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Text from "../Text/Text"
import _ from "lodash";
import FormHelperText from '@material-ui/core/FormHelperText';
const InputSelect = ({ options, label, cancel, name, classes = {}, fieldLabel = "label", labelComponent,fieldId = "id", errors, ...props }) => {

  const getLabel = (option) => {
    if (typeof fieldLabel === 'object') {
      return fieldLabel(option);
    }
    return option[fieldLabel]
  }
  useEffect(() => {

  }, [])
  return (
    <div className={`mb-16 w-100 ${classes.root}`}>
      <FormControl variant="outlined" className={`w-100`} error={_.get(errors, name)}>
        {label && <InputLabel id="demo-simple-select-filled-label"><Text uuid={label} /></InputLabel>}
        <Select
          {...props}
          helperText={_.get(errors, `${name}.message`, '')}
          name={name}
        >

          {cancel !== false && <MenuItem value=""> <em>None</em> </MenuItem>}
          {options.map((option, i) => {
            return (<MenuItem key={i} value={option[fieldId]}>
              {labelComponent?labelComponent(option):<Text uuid={getLabel(option)} /> }
            </MenuItem>)
          })}


        </Select>
        <FormHelperText>{_.get(errors, `${name}.message`, '')}</FormHelperText>

      </FormControl>
    </div>
  );
};


export default InputSelect;
