import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./../modules/dashboard/views/DashboardRoutes";
import meetingRoomsRoutes from "./../modules/meeting/views/meetingRoutes";
import roomRoutes from "./../modules/room/views/RoomRoutes";
import companiesRoutes from "./../modules/companies/views/CompaniesRoutes";
import sessionRoutes from "./../modules/session/views/SessionRoutes";

import activityLog from "./activityLog/Routes";


import monitorRoutes from "./monitor/Routes";
import usersRoutes from "./users/Routes";


const redirectRoute = [
  {

    path: "/",
    exact: true,
    component: () => <Redirect to="/session/signin" />
  },
  {
    path: "/login",
    exact: true,
    component: () => <Redirect to="/session/signin" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...dashboardRoutes,
  ...monitorRoutes,
  ...usersRoutes,
  ...activityLog,
  ...companiesRoutes,
  ...roomRoutes,
  ...sessionRoutes,
  ...meetingRoomsRoutes,
  


  
  ...redirectRoute,
  ...errorRoute
];
export default routes;
