import React, { useContext, useMemo } from "react";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import RoomContext from "app/modules/meeting/RoomContext";
import useRoomStatus from "../../../hooks/useRoomStatus";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";

const ButtonMicrophone = () => {
  const { me, audioProducer, disabledMic, enabledMic } = useRoomStatus();
  const roomClient = useContext(RoomContext);

  const MicrophoneIcon = useMemo(
    () => () => {
      if (enabledMic) {
        return <MicIcon />;
      } else if (!enabledMic) {
        return <MicOffIcon />;
      } else {
        return null;
      }
    },
    [enabledMic]
  );
  return (
    <ButtonMenuTopCustomer
      tip="TOOLTIP_ENABLED_DISABLED_MICROPHONE"
      disabled={disabledMic}
      child={<MicrophoneIcon />}
      actionClick={() => {
        if (!me.canSendMic) roomClient.unmuteMic();
        else if (!audioProducer) roomClient.unmuteMic();
        else if (!audioProducer.paused) roomClient.muteMic();
        else roomClient.unmuteMic();
      }}
    ></ButtonMenuTopCustomer>
  );
};

export default ButtonMicrophone;
