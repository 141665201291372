import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./_index.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./app/App";

// cssVars();

Sentry.init({
    dsn: "https://069e19b558864798aae07047f4f0c55f@o398575.ingest.sentry.io/5254528",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

