import translate from "../lang/translate";

export default class Helper {
  static defineTypes(typeName) {
    switch (typeName) {
      case "eComerceSupport":
        return translate("TEXT_ECOMERSESUPPORT");
      case "event":
        return translate("TEXT_EVENT");
      case "privateEvent":
        return translate("TEXT_ONLYUSERS");
      default:
        return translate("TEXT_UNDEFINED");
    }
  }
  
  static secondsToHms(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var separa =":";
    if (hours === 0) {
      hours = "";
      separa = "";
    }
    if (parseFloat(minutes) < 10) {
      minutes = "0" + minutes;
    }
    if (parseFloat(seconds) < 10) {
      seconds = "0" + seconds;
    } 
    return hours + separa + minutes + ":" + seconds;
  }

  static getNowTime() {
    var date = new Date();
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours + ":" + minutes;
  }

  static formatText(text, noDot, noModify) {
    if (text === undefined || text === "") {
      text = translate("TEXT_NOTHING_TO_SAY");
    } else {
      text = this.urlify(text);
      if (noModify === undefined) {
        var analiza = text.substring(0, 2);
        text = text.substring(0, 1).toUpperCase() + text.substring(1);
        if (noDot !== true) {
          analiza = text.substr(text.length - 1);
          if (analiza !== ".") {
            if (analiza !== "!") {
              if (analiza !== "?") {
                text = text + ".";
              }
            }
          }
        }
      }
    }
    return text;
  }

  static urlify(text) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.replace(urlRegex, function(url,b,c) {
      var url2 = (c === 'www.') ?  'http://' +url : url;
      var cortado = url.substr(0,20) + "...";
      var armado = '<a style="color: yellow; text-decoration: underline;" href="' + url2 + '" target="_blank">' + cortado + '</a>';
      return armado
    }) 
  }

  static isHtml(text) {
    var urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
    return text.match(urlRegex);
  }

  static toTitleCase(phrase) {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  static longTextToSmall(text, spacesCount) {
    if (text.length > spacesCount) {
      var step1 = text.substr(0, spacesCount);
      var step2 = step1.substr(step1.length-1, 1);
      if (step2 === " ") {
        return step1.substr(0, step1.length - 1) + translate("TEXT_DOTS");
      } else {
        return step2 + translate("TEXT_DOTS");
      }
    } else {
      return text;
    } 
  }

}