import {MatxLoadable} from "matx";
import {getUriPage} from "../../../views/RootPages"
const MyRoomsLists = MatxLoadable({
  loader: () => import("./pages/MyRoomsLists")
});
const NewRoom = MatxLoadable({
  loader: () => import("./pages/NewRoom")
});

const myRoomsRoute = [
  
  {
    path: getUriPage("module.rooms.add"),
    component: NewRoom
  },
  {
    path: getUriPage("module.rooms.list"),
    component: MyRoomsLists
  },
];
export default myRoomsRoute;
