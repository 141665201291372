//import React, { useEffect, useRef } from "react";
import React, { useEffect, useState } from "react";
//import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";

import { Appear } from "app/modules/meeting/components/transitions.jsx";
import classnames from "classnames";
import ViewGrid from "app/modules/meeting/components/ViewGrid.jsx";
import constants from "app/constants";
import Contacts from "app/modules/meeting/components/Contacts.jsx";
import ChatInput from "app/modules/meeting/components/ChatInput.jsx";
import AdminPanel from "app/modules/meeting/components/AdminPanel.jsx";
import Presentation from "app/modules/meeting/components/Presentation.jsx";
import _ from "lodash";
import ButtonFloatLang from "app/modules/meeting/components/buttons/customs/ButtonFloatLang.js";
import ImageFadeIn from "react-image-fade-in";
import MenuTopInterpreter from "./MenuTopInterpreter.jsx";
import WaitingState from "app/modules/components/generic/WaitingState.js";
import Menu from "app/modules/meeting/components/Menu.jsx";
import Scrollbar from "react-perfect-scrollbar";
import useRoomStatus from "../../../hooks/useRoomStatus";
import useApi from "../../../hooks/useApi";
import ScrollBar from "react-perfect-scrollbar";
import { PEERS_TYPES } from "app/constants";
//import { TramRounded } from '@material-ui/icons';

const RoomInterpreterContent = () => {
  const {
    room,
    peers,
    participantsState: participants,
    interpretersState,
    speakersState,
  } = useRoomStatus();

  const interpreters = interpretersState;
  const speakers = speakersState;
  const demo = false;
  const showPresentation = _.get(room, "presentation.video");

  const showAdminPanel = room.show_adminPanel;
  const showContact = room.show_contacts;
  const showProduct = room.show_productInfo;
  const showChat = room.show_chat;
  const cantSpeakers = speakers.length;
  const cantInterpreters = interpreters.length;
  const cantParticipants = participants.length;

  const lateralPeers = showPresentation
    ? interpreters.concat(speakers).concat(participants)
    : interpreters.concat(speakers);

  const [isMove, setIsMove] = useState(false);
  let timeout;
  useEffect(() => {});
  const handleMove = (e) => {
    if (isMove) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setIsMove(false);
      }, 4000);
    } else {
      setIsMove(true);
    }
  };
  //TODO Revisar por que participants y lateralpeers vienen vacios
  return (
    <Appear duration={300}>
      <div data-component="RoomInterpreter" onMouseMove={handleMove}>
        <MenuTopInterpreter />
        <div className="wrapper">
          <div
            className={classnames("container-super-grid2", {
              withLateral:
                cantInterpreters > 0 ||
                cantParticipants > 0 ||
                (cantSpeakers > 0 && showPresentation),
            })}
          >
            {showPresentation && (
              <div className={classnames("super-presentation")}>
                <Presentation />
              </div>
            )}

            {!showPresentation && (
              <div className={classnames("super-speaker")}>
                <ViewGrid
                  peers={participants}
                  allPeers={peers}
                  mode={"grid"}
                  demo={demo}
                  transformlabel={false}
                />
                {/* <ViewGrid
                  peers={speakers}
                  allPeers={peers}
                  mode={"grid"}
                  demo={demo}
                  transformlabel={false}
                /> */}
              </div>
            )}
            {(cantParticipants > 0 ||
              cantInterpreters > 0 ||
              (cantSpeakers > 0 && showPresentation)) && (
              <React.Fragment>
                <div className={"lateral"}>
                  <div className="divider-zones"></div>
                  <Scrollbar className="content">
                    <ViewGrid
                      peers={lateralPeers}
                      allPeers={peers}
                      mode={"column"}
                      demo={demo}
                      transformlabel={false}
                    />
                    {/* <ViewGrid
                      peers={lateralPeers}
                      allPeers={peers}
                      mode={"column"}
                      demo={demo}
                      transformlabel={false}
                    /> */}
                  </Scrollbar>
                </div>
              </React.Fragment>
            )}
          </div>
          <div
            className={classnames("panel", {
              hidden:
                !showContact && !showChat && !showAdminPanel && !showProduct,
            })}
          >
            {showContact && <Contacts open={true} />}
          </div>
        </div>
      </div>
    </Appear>
  );
};

export default RoomInterpreterContent;
