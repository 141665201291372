import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
// import Me from './Me';
// import ChatInput from './ChatInput';
import constants from "app/constants";
import translate from "app/lang/translate";
import RoomContext from "app/modules/meeting/RoomContext";
import * as requestActions from "app/redux/requestActions";
//import classnames from 'classnames';
import * as appPropTypes from "app/modules/meeting/components/appPropTypes.js";
import RoomInterpreterContent from "./components/RoomInterpreterContent";
import DisabledRoomModal from "../../../DisabledRoomModal";
import ModalCloseRoom from "../../../ModalCloseRoom";
import ModalEnabledPeer from "../../../ModalEnabledPeer";
import ModalForUserCloseRoom from "../../../ModalForUserCloseRoom";
import ModalForUserEject from "../../../ModalForUserEject";
import ModalForUserMultipleDevice from "../../../ModalForUserMultipleDevice";
import ModalForUserNotInvited from "../../../ModalForUserNotInvited";
// import Invite from './Invite';
// import Speeker from './Speeker';
//import Menu from './Menu';
import Notifications from "../../../Notifications";
import { Appear } from "../../../transitions";
import ModalCloseRoomOnlineSupport from "../../../ModalCloseRoomOnlineSupport";
function RoomInterpreter() {
  const roomClient = useContext(RoomContext);
  useEffect(() => {
    roomClient.join();
  }, []);
  return (
    <div id="app-room-interpreter-container">
      <Appear duration={300}>
        <div data-component="Room">
          <Notifications />
          <RoomInterpreterContent />
          <DisabledRoomModal></DisabledRoomModal>
          <ModalCloseRoomOnlineSupport />
          <ModalForUserCloseRoom />
          <ModalForUserEject />
          <ModalForUserMultipleDevice />
          <ModalForUserNotInvited />
        </div>
      </Appear>
    </div>
  );
}

export default RoomInterpreter;
