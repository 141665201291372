

export default {
  module:{
    users:{
      list: {
        uri:"/admin/module/users/list",
      },
      edit: {
        uri:"/admin/module/users/edit/:userName",
      }
    }
  }
};