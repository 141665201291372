import React, { Component } from 'react';

export default class CustomButton extends Component {
  render() {
    const { transparent, next, className, disabled, icon, alertCount, children } = this.props;
    let classNames = "";

    if (transparent) classNames += " transparent ";
    if (next) classNames += " next ";
    if (icon) classNames += " icon ";
    if (disabled) classNames += " disabled ";
    if (className) classNames += className;
    const totalCounts = alertCount > 99 ? 99 : alertCount;
    return (
      <button
        data-component='Button'
        type="button"
        {...this.props}
        className={classNames}
      >
        {children}
        {(totalCounts && totalCounts > 0)?(
          <span className={"totalCounts"}> {totalCounts}</span>
        ):null}
      </button>
    )
  }
}