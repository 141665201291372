import Dialog from "@material-ui/core/Dialog";
import classnames from "classnames";
import React from "react";
import translate from "app/lang/translate";
import Typography from "@material-ui/core/Typography";

const Modal = ({
  open,
  children,
  background = true,
  title,
  subtitle,
  srcImage,
  buttons,
  modalProps = {},
  onClose = () => {},
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} {...modalProps}>
      <div
        data-component="ModalCustomer"
        className={`${classnames({
          background: background,
        })} p-10`}
      >
        <h2 className="flex flex-center m-auto text-center h4">
          {translate(title)}
        </h2>
        <br />
        {subtitle && (
          <Typography
          variant="p"
          className="text-muted flex flex-center m-auto text-center"
          >
            {translate(subtitle)}
          </Typography>
        )}
        {buttons && (
          <div className="w-100 flex flex-center">
            {buttons.map((button, i) => (
              <div key={i} className={"m-8"}>
                {button}
              </div>
            ))}
          </div>

        )} 
        {children}
      </div>
    </Dialog>
  );
};

export default Modal;
