import { Grid } from "@material-ui/core/";
import { LoaderBounce } from "matx";
import React, { useMemo } from "react";
import translate from "../../../../lang/translate";
import WebcamAudio from "./WebcamAudio";
/**
 * Componente que muestra una pantalla de cargando mientras se cargan los datos de la sala
 * view es el tipo de pantalla que se va a mostrar
 *
 * @param {*} param0
 * @returns
 */
function CustomerRoomIntro({ view }) {
  const text = useMemo(
    () =>
      !view ? translate("TEXT_LOADER_NULL") : translate("TEXT_LOADER_CUSTOMER"),
    [view]
  );

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <LoaderBounce text={text} />
      </Grid>
    </React.Fragment>
  );
}
export default CustomerRoomIntro;
