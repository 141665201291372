import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import translate from "../../../lang/translate";

const StyledMenu = withStyles({
  paper: {
    "border-radius": "8px",
    backgroundColor: "#394364",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function FloatMenu({ anchorEl, handleClose, options, ...props }) {

  return (
    <div>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, i) => {
          const compOp = <StyledMenuItem key={i} onClick={handleClose}>{option}</StyledMenuItem>;
          let compShow = null;
          if (option.props.show !== undefined) {
            switch (typeof option.props.show) {
              case "object":
                compShow = React.cloneElement(option.props.show, {
                  children: compOp,
                });
                break;
              case "boolean":
                if (option.props.show !== false) {
                  compShow = compOp;
                }
                break;
              default:
            }
          } else {
            compShow = compOp;
          }
          return <div key={i}>{compShow}</div>;
        })}
      </StyledMenu>
    </div>
  );
}

export default FloatMenu;
