import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const StyledMenu = withStyles({
  paper: {
    "border-radius": "8px",
    backgroundColor: "#394364",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
/**
 * Menu flotante para botones
 * @param {*} param0
 * @returns
 */
function FloatMenu({ anchorEl, handleClose, options, ...props }) {
  return (
    <StyledMenu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {options.map((option, i) => {
        const compOp = (
          <MenuItem
            onClick={handleClose}
            divider={false}
            style={{
              padding: "8px 16px",
              height: "45px",
              marginRight: "4px",
              marginLeft: "4px",
              borderRadius: "8px",
            }}
            children={option}
          ></MenuItem>
        );

        let compShow = null;
        if (option.props.show !== undefined) {
          switch (typeof option.props.show) {
            case "object":
              compShow = React.cloneElement(option.props.show, {
                children: compOp,
              });
              break;
            case "boolean":
              if (option.props.show !== false) {
                compShow = compOp;
              }
              break;
            default:
          }
        } else {
          compShow = compOp;
        }
        return <div key={i}>{compShow}</div>;
      })}
    </StyledMenu>
  );
}

export default FloatMenu;
