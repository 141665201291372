import React from "react";
import translate from "../../../lang/translate";
import { Tooltip } from "@material-ui/core";
const InputDate = ({ title, children, ...props }) => {
  return (
    <Tooltip title={translate(title)} fontSize="large" {...props}>
      {children}
    </Tooltip>
  );
};

export default InputDate;
