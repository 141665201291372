import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../../constants/index.js';
import translate from '../../../lang/translate';
import { changeStatusRoom } from '../../../redux/actions/roomActions';
import Text from "./../../../modules/components/Text/Text";
import Button from "./../../../modules/components/buttons/Button";
import Modal from "./../../../modules/components/generic/Modal";
import useService from './../../../modules/api/hooks/useService';
import services from "./../../../services";
import ManagerAccess from './ManagerAccess';




const DisabledRoomModal = ({ ...props }) => {

	const dispatch = useDispatch();
	const room = useSelector((store) => store.room);
	const [wasClosed, setWasClosed] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const {
		send: activeRoom,
		loading: loadingActiveRoom,
	} = useService(services.module.rooms.enabled, {
		paramsUrl: { roomId: _.get(room, "info._id") },
		callback: () => {
			dispatch(changeStatusRoom("open"));
		}
	});
	useEffect(() => {
		setIsDisabled(_.get(room, "info.status") == constants.ROOM_STATUS.DISABLED);
	}, [_.get(room, "info.status")]);
	useEffect(() => {
		setOpenModal(isDisabled && !wasClosed)
	}, [isDisabled, wasClosed]);
	return (
		<ManagerAccess action="ENABLED_ROOM">
			<Modal
				open={openModal}
				title={"TEXT_ROOM_DISABLED_ROOM_MODAL_TITLE"}
				subtitle={"TEXT_ROOM_DISABLED_ROOM_MODAL_SUBTITLE"}
				srcImage={"/assets/images/illustrations/blocked.svg"}
				buttons={[
					<Button variant="contained" type="button" onClick={() => setWasClosed(true)}>
						{translate("TEXT_BUTTON_CANCEL")}
					</Button>,

					<Button variant="contained" type="button" color={"primary"} onClick={() => activeRoom(null,{ roomId: _.get(room, "info._id") })}>
						{translate("TEXT_BUTTON_ENABLED")}
					</Button>
				]}
				onClose={() => setOpenModal(false)}
			>
				<React.Fragment>
					<div data-component={"DisabledRoomModal"}>
						<Text uuid={"TEXT_ROOM_DISABLED_ROOM_MODAL_DETAILS"} />
					</div>
				</React.Fragment>
			</Modal>

		</ManagerAccess>
	)
}
export default DisabledRoomModal
