import _ from "lodash";
import moduleRoom from "./../modules/room/services"
import moduleSession from "./../modules/session/services"
export default {
	module: {
		langs: {
			uri: "/api/languages",
			method: "get",
			useToken: true,
		},
		dashboard: {
			uri: "/api/rooms/dashboard",
			method: "get",
			useToken: true,
		},
		users: {
			list: {
				uri: "/api/users",
				method: "get",
				useToken: true,
			},
		},
		users: {
			list: {
				uri: "/api/users",
				method: "get",
				useToken: true,
			},
			getUser: {
				uri: "/api/users/:userName",
				method: "get",
				useToken: true,
			},
			updateUser: {
				uri: "/api/users/:userName",
				method: "put",
				useToken: true
			},
		},
		
		activities: {
			list: {
				uri: "/api/activities",
				method: "get",
				useToken: true,
			}
		},
		monitor: {
			list: {
				uri: "/api/rooms/monitor",
				method: "get",
				useToken: true,
			},
			get: {
				uri: `/api/monitor/:roomId`,
				method: "get",
				useToken: true,
			}
		},
		...moduleRoom,
		...moduleSession
	},
	
	
	CREATE_USER: {
		uri: "/api/users",
		method: "post",
		useToken: false,
		successMessageKey: "NOTIFICATION_CREATE_USER_SUCCESS"
	},
	
	CALL_INTERPRETES: {
		uri: "/api/mailNotifications",
		method: "post",
		useToken: true,
		successMessageKey: "NOTIFICATION_INTERPRETER_INCITED_SUCCESS"
	},
	
}
