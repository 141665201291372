export const toogleOptions = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_OPTIONS", payload: {} });
  };
};

export const toogleChat = (roomId) => {
  return (dispatch) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_CHAT", payload: {} });
    //TODO: remove chat.js in redux
    dispatch({ type: "TOGGLE_CHAT", payload: {} });
  };
};

export const toogleLang = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_LANG", payload: {} });
  };
};
export const toogleAdminPanel = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_ADMIN_PANEL", payload: {} });
  };
};
export const toogleShareScreen = (value) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOOGLE_SHARE_SCREEN", payload: { share: value } });
  };
};

export const toogleContacts = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_CONTACTS", payload: {} });
  };
};
export const toogleInfoRoom = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_INFO_ROOM", payload: {} });
  };
};
export const changeStatusRoom = (status) => {
  return (dispatch) => {
    // redux thunk
    dispatch({ type: "ROOM_CHANGE_STATUS", payload: { status } });
  };
};
export const setRoomInfo = (info) => {
  return (dispatch) => {
    // redux thunk
    dispatch({ type: "SET_INFO_ROOM", payload: { info } });
  };
};

export const updatePresentationVideo = (operation, progessVideo) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({
      type: "UPDATE_PRESENTATION_VIDEO",
      payload: { operation, progessVideo },
    });
  };
};

export const updateIsRecord = (isRecord) => {
  return (dispatch) => {
    // redux thunk
    dispatch({ type: "UPDATE_IS_RECORD", payload: { isRecord } });
  };
};
export const openModal = (type, props) => {
  return (dispatch) => {
    // redux thunk
    dispatch({
      type: "MODAL_CHANGE_STATUS",
      payload: { type, props, open: true },
    });
  };
};
export const closeModal = (type, props) => {
  return (dispatch) => {
    // redux thunk
    dispatch({
      type: "MODAL_CHANGE_STATUS",
      payload: { type, props, open: false },
    });
  };
};

export const toggleProduct = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    dispatch({ type: "TOGGLE_SHOW_PRODUCT", payload: {} });
  };
};
