import React, { Component } from "react";
import {
  Card,
  Grid,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import {login} from '../../../../redux/apiActions';
import SignupForm from '../../forms/SignupForm'
import _ from "lodash"
import { bindActionCreators } from "redux";
import ImageFadeIn from "react-image-fade-in";
import TopDetail from "../../../../modules/components/decorators/TopDetail";
import { isMobile } from 'react-device-detect';

const styles = theme => ({
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class SignUp extends Component {
 


  handleFinishSubmit = (resp) => {
    
    
  };

  render() {
    let { classes } = this.props;
    const { hostname } = window.location;
    return (
      <div data-component={"Signup"} className="signup flex flex-center w-100 h-100vh">
        <div className={isMobile ? "pl-8 pr-8" : "special-width-card"}>
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className={`p-32 flex flex-center flex-middle h-100${isMobile ? ' frame-detail-mobile' : ''}`}>
                  <ImageFadeIn
                    src={_.get(this,"props.client.logo")}
                    alt=""
                    onError={(e) => {
                      e.target.src = `/assets/logos/solunika.png`
                    }}
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className={`h-100${isMobile ? ' pl-20 pr-20 pb-20' : ' p-20 frame-detail'}`}>
                  <TopDetail
                    title="TEXT_NEWUSER"
                    subtitle="TEXT_NEWUSER_PLACEHOLDER"
                    srcImage={isMobile ? "" : "/assets/images/illustrations/newUserDetail.svg"}
                    isMobil={isMobile}
                  />
                  <SignupForm onFinishSubmit={this.handleFinishSubmit} classes={classes} />
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // loginWithEmailAndPassword: PropTypes.func.isRequired,
  // login: state.login,
  client: state.session.client
});

//const mapDispatchToProps = (dispatch) => bindActionCreators({login}, dispatch)
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SignUp)
  )
);