import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import { toggleHand } from "../../../redux/stateActions";
import ManagerAccess from "./ManagerAccess";
import ButtonMicrophone from "./../../../modules/meeting/components/buttons/customs/ButtonMicrophone";
import ButtonCamera from "./../../../modules/meeting/components/buttons/customs/ButtonCamera";
import FloatButton from "../../../modules/components/buttons/FloatButton";
import ButtonClose from "./ButtonClose";
import classnames from "classnames";
import Fade from "@material-ui/core/Fade";

const Menu = ({ toggleHand, room, enabledHandButton, ...props }) => {
  return (
    <div data-component="Menu">
      <Fade in={props.isShow}>
        <div
          className={classnames("app-menu", {
            "app-menu-small": props.small,
          })}
        >
          <ButtonMicrophone />
          <ButtonClose />
          <ButtonCamera />
        </div>
      </Fade>
      {enabledHandButton && (
        <ManagerAccess action="HAND_UP">
          <FloatButton
            iconString={room.handUp ? "handdown" : "handup"}
            toolTip={room.handUp ? "TOOLTIP_DOWN_HAND" : "TOOLTIP_RAISE_HAND"}
            useLeft={true}
            action={toggleHand}
            useIncoMoon={true}
            iconSize="32"
          ></FloatButton>
        </ManagerAccess>
      )}
    </div>
  );
};
Menu.propTypes = {
  roomClient: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleHand }, dispatch);

const MenuContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => prev === next,
  })(Menu)
);

export default MenuContainer;
