import React from "react";
import Box from '@material-ui/core/Box';
import translate from "../../../lang/translate";
import ImageFadeIn from "react-image-fade-in";

export default function NotAvaibleState({
  ...props
}) {
  return (
    <div className="p-60">
      <Box display="flex" justifyContent="center">
        <ImageFadeIn
          src="/assets/images/illustrations/exclamation.svg"
          alt=""
          height="200px"
          width="100px"
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <h5 className="mt-10 font-weight-500 text-muted" align="center">{translate("TEXT_NOTAVAIBLE_STATE")}</h5>
      </Box>
    </div>
  );
};