import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { toogleLang } from "./../../../../../redux/actions/roomActions";
import constants from "./../../../../../constants/index.js";
import FloatButton from "../../../../../modules/components/buttons/FloatButton";
import FloatMenu from "./../../../../../modules/components/menu/FloatMenu";
import ListItem from "./../../../../../modules/components/menu/ListItem";
import translate from "./../../../../../lang/translate";
import { changeLang } from "./../../../../../redux/stateActions";
import ManagerAccess from "./../../../../../modules/meeting/components/ManagerAccess";
const ButtonFloatLang = ({...props}) => {
  const dispatch = useDispatch();
  const room = useSelector((store) => store.room);
  const me = useSelector((store) => store.me);
  const options = useSelector((store) => {
    const peers = Object.values(store.peers);
    const interpreters = peers.filter(
      (p) => p.peerType === constants.PEERS_TYPES.INTERPRETER
    );
    let ops = interpreters.map((element) => {
      return (
        <ListItem
          onClick={() => {
            dispatch(changeLang(element.peerLang));
          }}
          icon={
            <img
              alt=""
              src={`/room/images/flags-iso/flat/16/${element.peerLang.toUpperCase()}.png`}
            />
          }
          text={translate(`LANG_${element.peerLang.toUpperCase()}`)}
          iconAfter={
            me.peerLang == element.peerLang ? (
              <i className={classnames("icon-checkmark2", "check")} />
            ) : null
          }
        />
      );
    });
    ops.push(
      <ListItem
        onClick={() => {
          dispatch(changeLang("es"));
        }}
        icon={<img src={`/room/images/flags-iso/flat/16/${"ES"}.png`} alt=""/>}
        text={translate(`LANG_${"ES"}`)}
        iconAfter={
          me.peerLang == "es" ? (
            <i className={classnames("icon-checkmark2", "check")} />
          ) : null
        }
      />
    );

    return ops;
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openFloatMenu, setOpenFloatMenu] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnlClick = () => {
    dispatch(toogleLang());
  };

  const showAdminPanel = room.show_adminPanel;
  const showContact = room.show_contacts;
  const showChat = room.show_chat;

  return (
    <ManagerAccess action="SELECT_LANG">
      <React.Fragment>
        <FloatButton
          iconString="translate2"
          useIncoMoon={true}
          toolTip="TOOLTIP_SELECT_LANG"
          action={handleClick}
          translate={showAdminPanel || showContact || showChat}
          browserMobile={props.browserMobile}
        ></FloatButton>
        <FloatMenu
          options={options}
          anchorEl={anchorEl}
          open={openFloatMenu}
          handleClose={handleClose}
        ></FloatMenu>
      </React.Fragment>
    </ManagerAccess>
  );
};

export default ButtonFloatLang;
