import React,{useEffect,useState} from "react";
import helper from "../../../../utils/helpers";

const Count = ({ timeStart, ...props }) => {
  const [interval,setLocalInterval]= useState(null);
  const [time,setTime] = useState(Date.now());
  const getSeconds = () => {
    return Math.trunc((Date.now() - timeStart) / 1000)
  }
  useEffect(()=>{
    setLocalInterval(setInterval(() => setTime({ time: Date.now() }), 1000));
    return ()=>{
      clearInterval(interval);
    }
  },[]);
  
  return (
    <React.Fragment>
      {helper.secondsToHms(getSeconds())}
    </React.Fragment>
  );
};

export default Count;