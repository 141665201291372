//import React, { useState, useEffect } from "react";
import React from "react";
//import constants from "../../constants/index.js";
//import services from "./../../services";
//import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
//import * as roomActions from "./../../redux/actions/roomActions";
//import ManagerAccess from "./ManagerAccess";
import { kickPeer } from "../../../redux/stateActions";
import Button from "./../../../modules/components/buttons/Button";
//import _ from "lodash";
import Modal from "./../../../modules/components/generic/Modal";

const ModalKickPeer = ({ roomClient, peer, open, onClose, ...props }) => {
  const dispatch = useDispatch();
  const handleKickPeer = () => {
    dispatch(kickPeer(peer.id));
    onClose();
  };

  return (
    <Modal
      open={open}
      title={"MODAL_KICK_PEER_TITLE"}
      subtitle={"MODAL_KICK_PEER_SUBTITLE"}
      srcImage={"/assets/images/illustrations/eject.svg"}
      buttons={[
        <Button variant="contained" type="button" onClick={onClose}>
          {translate("TEXT_BUTTON_CANCEL")}
        </Button>,
        <Button
          variant="contained"
          type="button"
          color={"primary"}
          onClick={handleKickPeer}
        >
          {translate("TEXT_BUTTON_KICK")}
        </Button>,
      ]}
      onClose={onClose}
    >
      <React.Fragment>
        <div data-component={"ModalKickPeer"}>
          {translate("MODAL_KICK_PEER_TEXT")}
        </div>
      </React.Fragment>
    </Modal>
  );
};
export default withRoomContext(ModalKickPeer);
