import { Grid } from "@material-ui/core/";
import { LoaderBounce } from "matx";
import React, { useMemo } from "react";
import translate from "../../../../lang/translate";
import Card from "@material-ui/core/Card";
import { Button } from "@material-ui/core";
import ModalCustomer from "../Room/roomTypes/customer/components/Modal";
import Modal from "app/modules/components/generic/Modal.js";
import { PEERS_TYPES } from "app/constants";
import { useEffect } from "react";
import useEvent from "../Room/hooks/useEvent";
import { isMobile } from "react-device-detect";
import { useRef } from "react";


const WebcamAudio = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const constraints = { video: true, audio: true };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error('Error al acceder a los dispositivos de medios:', err);
      }
    };

    getUserMedia();
  }, []);

  const videoStyle = {
    maxWidth: '100%',
    maxHeight: '200px',
    borderRadius: '20px',
  };

  return (
    <div style={{width: "100%", height:"auto", display:"flex",justifyContent:"center",alignItems:"center", margin: "20px 0 "}}>
      <video ref={videoRef} autoPlay playsInline muted style={videoStyle} />
    </div>
  );
};


export default WebcamAudio;
