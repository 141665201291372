import {MatxLoadable} from "matx";
import {getUriPage} from "../RootPages"
const List = MatxLoadable({
  loader: () => import("./List")
});
const NewRoom = MatxLoadable({
  loader: () => import("./Edit")
});

const myRoomsRoute = [
  
  {
    path: getUriPage("module.users.edit"),
    component: NewRoom
  },
  {
    path: getUriPage("module.users.list"),
    component: List
  },
];
export default myRoomsRoute;
