import Dialog from '@material-ui/core/Dialog';
import clipboardCopy from 'clipboard-copy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import translate from '../../../lang/translate';
import { withRoomContext } from '../../../modules/meeting/RoomContext';
import Button from './Button';

const InfoRoom = ({currentRoom = {}, room, open, handleClose }) => {
	const copyInvite = event => {
		if (event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)) return;
		// Middle click (IE > 9 and everyone else).
		event.preventDefault();
		clipboardCopy(room.url);
	}
	return (

		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={false}>
			<div data-component='Inforoom' >
				<div className={"headerInfoRoom"}>
					<h2>{translate('TEXT_ROOM_INFO')}</h2>
				</div>
				<div className={"content"}>
					<div>{translate('TEXT_ROOM_NAME_INFO')}: <span>{currentRoom ? currentRoom.title : ""}</span></div>
					<div>{translate('TEXT_ROOM_DESCRIPTION_INFO')}</div>
					<p>{currentRoom ? currentRoom.description : ""}</p>

					<div className='botonera'>
						<Button next onClick={copyInvite}><i className={"icon-copy"} /></Button>
						<Button next onClick={handleClose}>{translate('TEXT_ROOM_CLOSE_INFO')}</Button>
					</div>

				</div>
			</div>
		</Dialog>

	);
};

InfoRoom.propTypes = {
	roomClient: PropTypes.any.isRequired
	// activeSpeakerId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		room: state.room,
		me: state.me,
		currentRoom: state.api.currentRoom

	};
};

const InfoRoomContainer = withRoomContext(connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev == next
			);
		}
	}
)(InfoRoom));

export default InfoRoomContainer;
