import classnames from "classnames";
import _ from "lodash";
import React, { useEffect, useState, useContext, useMemo } from "react";
//import ButtonFloatLang from "../../../modules/components/room/buttons/customs/ButtonFloatLang";
import { useDispatch, useSelector } from "react-redux";
//import MenuTop from './../MenuTop';
import WaitingState from "app/modules/components/generic/WaitingState";
import constants from "../../../../../../constants";
import RoomContext from "../../../../RoomContext";
import { PEERS_TYPES } from "../../../../../../constants";
import Presentation from "../../../Presentation";
import { Appear } from "../../../transitions";
import ViewGrid from "../../../ViewGrid";
import WaitingLoader from "./components/WaitingLoader";
import MenuTopCustomer from "./components/MenuTopCustomer";
import useRoomStatus from "../../hooks/useRoomStatus";
import useApi from "../../hooks/useApi";
import translate from "app/lang/translate";
import { changeLang } from "app/redux/stateActions";
import { lang } from "app/lang/translate";
import useNotification from "../../hooks/useNotification";
const RoomCustomer = ({ enableAsking }) => {
  const {
    room,
    peers,
    participantsState,
    interpretersState,
    speakersState,
    me,
    enabledCam,
    videoProducerShare,
    enabledLangs,
  } = useRoomStatus();
  const roomClient = useContext(RoomContext);
  const [waiting, setWaiting] = useState(true);
  const { setAsking } = useApi();
  const participants = participantsState;
  const interpreters = interpretersState;
  const speakers = speakersState;
  const demo = false;
  const showPresentation = _.get(room, "presentation.video");
  const showAdminPanel = room.show_adminPanel;
  const showContact = room.show_contacts;
  const showChat = room.show_chat;
  const cantSpeakers = speakers.length;
  const cantInterpreters = interpreters.length;
  const cantParticipants = participants.length;
  const lateralPeers = showPresentation
    ? interpreters.concat(speakers).concat(participants)
    : interpreters.concat(participants);
  const { sendChangeLangNotification } = useNotification();
  const [isMove, setIsMove] = useState(true);
  const interpreter = useMemo(
    () => peers.find((p) => p.peerType === PEERS_TYPES.INTERPRETER),
    [peers.length]
  );
  useEffect(() => {
    setTimeout(function () {
      setIsMove(false);
    }, 4000);
    roomClient.join();
    try {
      // document.body.requestFullscreen();
    } catch (e) {
      console.log("No soporta pantalla completa");
    }
  }, []);
  useEffect(() => {
    peers.forEach((peer) => {
      if (peer.peerType === PEERS_TYPES.SPEEKER) {
        setWaiting(false);
      }
    });
  }, [peers.length]);
  const handleMove = () => {
    if (!isMove) {
      setIsMove(true);
    }
    setTimeout(function () {
      setIsMove(false);
    }, 4000);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (interpreter) {
      if (
        _.get(interpreter, "peerLang") &&
        me.peerLang !== interpreter.peerLang
      ) {
        dispatch(changeLang(interpreter.peerLang));
      }
    } else {
      if (
        _.get(room, "info.officialLang") &&
        me.peerLang !== room.info.officialLang
      ) {
        dispatch(changeLang(room.info.officialLang));
      }
    }
  }, [JSON.stringify(enabledLangs)]);
  useEffect(() => {
    if (room.state === "connected") {
      setAsking(me.id);
      if (enableAsking === true && me && !me.asking) {
        roomClient.enableWebcam();
        roomClient.unmuteMic();
      }
    }
  }, [room.state]);
  useEffect(() => {
    if (interpreter && me.peerLang) sendChangeLangNotification();
  }, [me.peerLang]);
  return (
    <div id="app-room-customer-container">
      {/* <ModalEnabledPeer /> */}
      <Appear duration={300}>
        <div data-component="RoomCustomer" onTouchStart={handleMove}>
          <MenuTopCustomer />

          <div className="wrapper">
            {waiting && !(enabledCam || videoProducerShare) ? (
              <WaitingLoader></WaitingLoader>
            ) : (
              <div
                className={classnames("container-super-grid2", {
                  withLateral:
                    cantInterpreters > 0 ||
                    cantParticipants > 0 ||
                    (cantSpeakers > 0 && showPresentation),
                  hidden: showContact || showChat || showAdminPanel,
                })}
              >
                {showPresentation && (
                  <div className={classnames("super-presentation")}>
                    <Presentation />
                  </div>
                )}
                {!showPresentation && cantSpeakers > 0 && (
                  <div className={classnames("super-speaker")}>
                    <ViewGrid
                      peers={speakers}
                      allPeers={peers}
                      mode={"customer"}
                      demo={demo}
                      transformlabel={false}
                    />
                  </div>
                )}
                {(cantInterpreters > 0 ||
                  cantParticipants > 0 ||
                  (cantSpeakers > 0 && showPresentation)) && (
                  <React.Fragment>
                    <div className={"lateral"}>
                      <ViewGrid
                        peers={lateralPeers}
                        allPeers={peers}
                        mode={"customer"}
                        demo={demo}
                        transformlabel={false}
                      />
                    </div>
                  </React.Fragment>
                )}
                {waiting && (enabledCam || videoProducerShare) && (
                  <WaitingLoader></WaitingLoader>
                )}
              </div>
            )}
          </div>
        </div>
      </Appear>
    </div>
  );
};

export default RoomCustomer;
