import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import constants from '../../../constants/index.js';
import * as cookiesManager from '../../../modules/meeting/cookiesManager';
import { withRoomContext } from '../../../modules/meeting/RoomContext';
import * as stateActions from '../../../redux/stateActions';
import * as appPropTypes from './appPropTypes';
import PeerView from './PeerView';
class Me extends React.Component {
	constructor(props) {
		super(props);

		this._mounted = false;
		this._rootNode = null;
	}
	getDefaultPosition() {
		const defualtX = document.body.offsetWidth - 300 - 20;
		const defualtY = document.body.offsetHeight - 200 - 110 - 60;
		const mePositions = this.props.room.mePositions;
		const x = mePositions ? mePositions.x : defualtX;
		const y = mePositions ? mePositions.y : defualtY;
		return { x, y };

	}
	onStop(p1) {
		cookiesManager.setDevices({ "mePositions": { x: p1.clientX, y: p1.clientY } });
	}
	render() {
		const {
			roomClient,
			connected,
			me,
			audioProducer,
			videoProducer,
			faceDetection,
			onSetStatsPeerId,
			fullScreen,
			room,
			shareScreen
		} = this.props;

		let micState;

		if (!me.canSendMic)
			micState = 'unsupported';
		else if (!audioProducer)
			micState = 'unsupported';
		else if (!audioProducer.paused)
			micState = 'on';
		else
			micState = 'off';

		let webcamState;

		if (!me.canSendWebcam)
			webcamState = 'unsupported';
		else if (videoProducer && videoProducer.type !== 'share')
			webcamState = 'on';
		else
			webcamState = 'off';

		let changeWebcamState;

		if (Boolean(videoProducer) && videoProducer.type !== 'share' && me.canChangeWebcam)
			changeWebcamState = 'on';
		else
			changeWebcamState = 'unsupported';

		let shareState;

		if (Boolean(videoProducer) && videoProducer.type === 'share')
			shareState = 'on';
		else
			shareState = 'off';

		const videoVisible = Boolean(videoProducer) && !videoProducer.paused;



		if (fullScreen === true) {
			return (
				<div
					data-component='Me'
					ref={(node) => (this._rootNode = node)}
					className={"fullScreen"}
				>
					<PeerView
						isMe
						shareScreen={shareScreen}
						peer={me}
						audioProducerId={audioProducer ? audioProducer.id : null}
						videoProducerId={videoProducer ? videoProducer.id : null}
						audioRtpParameters={audioProducer ? audioProducer.rtpParameters : null}
						videoRtpParameters={videoProducer ? videoProducer.rtpParameters : null}
						audioTrack={audioProducer ? audioProducer.track : null}
						videoTrack={videoProducer ? videoProducer.track : null}
						videoVisible={videoVisible}
						audioCodec={audioProducer ? audioProducer.codec : null}
						videoCodec={videoProducer ? videoProducer.codec : null}
						audioScore={audioProducer ? audioProducer.score : null}
						videoScore={videoProducer ? videoProducer.score : null}
						faceDetection={faceDetection}
						onChangeDisplayName={(displayName) => {
							roomClient.changeDisplayName(displayName);
						}}
						onChangeMaxSendingSpatialLayer={(spatialLayer) => {
							roomClient.setMaxSendingSpatialLayer(spatialLayer);
						}}
						onStatsClick={onSetStatsPeerId}
					/>

				</div>
			)

		}
		return (

			<React.Fragment>
				{(room.enabledMe && me.peerType !== constants.PEERS_TYPES.SPEEKER) ? (
					<Draggable
						bounds='parent'
						defaultPosition={this.getDefaultPosition()}
						handle='div.draggable'
						onStop={this.onStop}
					>
						<div
							data-component='Me'
							ref={(node) => (this._rootNode = node)}
							className={"draggable float"}
						>

							<PeerView
								isMe
								peer={me}
								audioProducerId={audioProducer ? audioProducer.id : null}
								videoProducerId={videoProducer ? videoProducer.id : null}
								audioRtpParameters={audioProducer ? audioProducer.rtpParameters : null}
								videoRtpParameters={videoProducer ? videoProducer.rtpParameters : null}
								audioTrack={audioProducer ? audioProducer.track : null}
								videoTrack={videoProducer ? videoProducer.track : null}
								videoVisible={videoVisible}
								audioCodec={audioProducer ? audioProducer.codec : null}
								videoCodec={videoProducer ? videoProducer.codec : null}
								audioScore={audioProducer ? audioProducer.score : null}
								videoScore={videoProducer ? videoProducer.score : null}
								faceDetection={faceDetection}
								onChangeDisplayName={(displayName) => {
									roomClient.changeDisplayName(displayName);
								}}
								onChangeMaxSendingSpatialLayer={(spatialLayer) => {
									roomClient.setMaxSendingSpatialLayer(spatialLayer);
								}}
								onStatsClick={onSetStatsPeerId}
							/>

						</div>
					</Draggable>
				) : null}
			</React.Fragment>
		);
	}

	componentDidMount() {
		this._mounted = true;

		setTimeout(() => {
			if (!this._mounted || this.props.me.displayNameSet)
				return;

			ReactTooltip.show(this._rootNode);
		}, 4000);
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.me.displayNameSet && this.props.me.displayNameSet)
			ReactTooltip.hide(this._rootNode);
	}
}

Me.propTypes =
{
	roomClient: PropTypes.any.isRequired,
	connected: PropTypes.bool.isRequired,
	me: appPropTypes.Me.isRequired,
	audioProducer: appPropTypes.Producer,
	videoProducer: appPropTypes.Producer,
	faceDetection: PropTypes.bool.isRequired,
	onSetStatsPeerId: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	const producersArray = Object.values(state.producers);
	const audioProducer =
		producersArray.find((producer) => producer.track.kind === 'audio');
	const videoShareProducer =
		producersArray.find((producer) => producer.track.kind === 'video' && producer.type == "share");
	const videoCameraProducer =
		producersArray.find((producer) => producer.track.kind === 'video' && producer.type != "share");
	const videoProducer = videoShareProducer ? videoShareProducer : videoCameraProducer;
	const shareScreen = videoShareProducer ? true : false;
	return {
		connected: state.room.state === 'connected',
		me: state.me,
		audioProducer: audioProducer,
		videoProducer: videoProducer,
		shareScreen,
		faceDetection: state.room.faceDetection,
		room: state.room
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetStatsPeerId: (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId)),
		setMePosition: (position) => dispatch(stateActions.setMePosition(position))
	};
};

const MeContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Me));

export default MeContainer;
