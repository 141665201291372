import _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../constants/index.js";
import translate from "../../../lang/translate";
import { changeStatusRoom } from "../../../redux/actions/roomActions";
import Text from "./../../../modules/components/Text/Text";
import Button from "./../../../modules/components/buttons/Button";
import Modal from "./../../../modules/components/generic/Modal";
import useService from "./../../../modules/api/hooks/useService";
import services from "./../../../services";
import ModalCustomer from "app/modules/meeting/components/Room/roomTypes/customer/components/Modal.jsx";
import useEvent from "./Room/hooks/useEvent.js";
const DeletedRoom = (props) => {
  const { role } = props;
  const { closeWindow } = useEvent();
  const insideIframe = useMemo(() => window.top !== window.self, []);
  return role === "customer" ? (
    <ModalCustomer
      open={true}
      title={"TEXT_ROOM_DISABLED_ROOM_MODAL_TITLE"}
      subtitle={"TEXT_ROOM_DELETED_SUBTITLE"}
      srcImage={"/assets/images/illustrations/blocked.svg"}
      buttons={[
        insideIframe && (
          <Button
            variant="contained"
            size="small"
            type="button"
            onClick={() => {
              closeWindow();
            }}
          >
            {translate("TEXT_EXIT")}
          </Button>
        ),
      ]}
    />
  ) : (
    <Modal
      open={true}
      title={"TEXT_ROOM_DISABLED_ROOM_MODAL_TITLE"}
      subtitle={"TEXT_ROOM_DELETED_SUBTITLE"}
      srcImage={"/assets/images/illustrations/blocked.svg"}
      buttons={[
        insideIframe && (
          <Button
            variant="contained"
            size="medium"
            type="button"
            onClick={() => {
              closeWindow();
            }}
          >
            {translate("TEXT_EXIT")}
          </Button>
        ),
      ]}
    ></Modal>
  );
};
export default DeletedRoom;
