import React, { useState, useMemo } from "react";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useCallback } from "react";

function ButtonHideMe({ onClick }) {
  const [active, setActive] = useState(false);
  const EyeIcon = useMemo(() => {
    return active ? <VisibilityOffIcon /> : <VisibilityIcon />;
  }, [active]);

  const handleClick = useCallback(() => {
    onClick(!active);
    setActive(!active);
  }, [active, onClick]);

  return (
    <React.Fragment>
      <ButtonMenuTopCustomer
        tip="BUTTON_HIDE_ME"
        child={EyeIcon}
        disabled={false}
        actionClick={handleClick}
      ></ButtonMenuTopCustomer>
    </React.Fragment>
  );
}

export default ButtonHideMe;
