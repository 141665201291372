import React, { useState, useMemo } from "react";
import { MoreVert } from "@material-ui/icons";
import useRoomStatus from "../../../hooks/useRoomStatus";
import FloatMenuTop from "app/modules/meeting/components/FloatMenuTop.jsx";
import PropTypes from "prop-types";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";
import { useEffect } from "react";
/**
 * Componente de boton para mostrar menu flotante con mas botones
 * @param {*} param0
 * @returns
 */
function ButtonToggleMenu({ buttons }) {
  const { room } = useRoomStatus();
  const [openFloatMenu, setOpenFloatMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const options = useMemo(() => buttons.filter((button) => button), [buttons]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {options.length > 0 && (
        <React.Fragment>
          <ButtonMenuTopCustomer
            child={<MoreVert />}
            tip="TOGGLE_SHOW_MENU"
            actionClick={handleClick}
            toggle={room.show_menu}
            tipClose="TOGGLE_HIDE_MENU"
          ></ButtonMenuTopCustomer>
          <FloatMenuTop
            options={options}
            anchorEl={anchorEl}
            open={openFloatMenu}
            handleClose={handleClose}
          ></FloatMenuTop>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
ButtonToggleMenu.propTypes = {
  buttons: PropTypes.array,
};
export default ButtonToggleMenu;
