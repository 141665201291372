import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import constants from "../../../../../constants/index.js";
import ListItem from "app/modules/components/menu/ListItem.jsx";
import { useDispatch } from "react-redux";
import { changeLang } from "app/redux/stateActions.js";
import translate from "app/lang/translate.js";
import classnames from "classnames";
import _ from "lodash";
const useRoomStatus = () => {
  const dispatch = useDispatch();
  const me = useSelector((store) => store.me);
  const producersArray = useSelector((store) => Object.values(store.producers));
  const videoProducer = useSelector((store) => {
    return producersArray.find(
      (producer) => producer.track.kind === "video" && producer.type != "share"
    );
  });

  const disabledCam = useMemo(
    () =>
      !(
        me.peerType === constants.PEERS_TYPES.SPEEKER ||
        me.peerType === constants.PEERS_TYPES.INTERPRETER ||
        me.asking
      ),

    [me]
  );

  const enabledCam = useMemo(() => {
    return (
      me.canSendWebcam &&
      videoProducer &&
      videoProducer.type !== "share" &&
      !videoProducer.paused
    );
  }, [me, videoProducer]);

  const audioProducer = useSelector((store) => {
    return producersArray.find((producer) => producer.track.kind === "audio");
  });

  const disabledMic = useMemo(
    () =>
      !(
        me.peerType === constants.PEERS_TYPES.SPEEKER ||
        me.peerType === constants.PEERS_TYPES.INTERPRETER ||
        me.asking
      ),

    [me]
  );

  const enabledMic = useMemo(
    () => me.canSendMic && audioProducer && !audioProducer.paused,
    [me, audioProducer]
  );

  const room = useSelector((store) => store.room);

  const peers = useSelector((store) => Object.values(store.peers));

  const options = useSelector((store) => {
    const interpreters = peers.filter(
      (p) => p.peerType === constants.PEERS_TYPES.INTERPRETER
    );
    let ops = interpreters.map((element) => {
      return (
        <ListItem
          onClick={() => {
            dispatch(changeLang(element.peerLang));
          }}
          icon={
            <img
              alt=""
              src={`/room/images/flags-iso/flat/16/${element.peerLang.toUpperCase()}.png`}
            />
          }
          text={translate(`LANG_${element.peerLang.toUpperCase()}`)}
          iconAfter={
            me.peerLang == element.peerLang ? (
              <i className={classnames("icon-checkmark2", "check")} />
            ) : null
          }
        />
      );
    });
    //TODO: Ver con Agus si esto lo hizo para pruebas
    // ops.push(
    //   <ListItem
    //     onClick={() => {
    //       dispatch(changeLang("es"));
    //     }}
    //     icon={<img src={`/room/images/flags-iso/flat/16/${"ES"}.png`} alt="" />}
    //     text={translate(`LANG_${"ES"}`)}
    //     iconAfter={
    //       me.peerLang == "es" ? (
    //         <i className={classnames("icon-checkmark2", "check")} />
    //       ) : null
    //     }
    //   />
    // );

    return ops;
  });

  const participantsState = useSelector((store) => {
    if (
      store.me.peerType === constants.PEERS_TYPES.INVITED &&
      !peers.some((p) => p.id === store.me.id)
    ) {
      peers.push({ ...store.me, isMe: true });
    }
    return peers.filter(
      (peer) => peer.peerType === constants.PEERS_TYPES.INVITED && peer.asking
    );
  });

  const interpretersState = useSelector((store) => {
    if (
      store.me.peerType === constants.PEERS_TYPES.INTERPRETER &&
      !peers.some((p) => p.id === store.me.id)
    ) {
      peers.push({ ...store.me, isMe: true });
    }
    const officialLang = _.get(room, "info.officialLang", "es");
    return peers.filter((peer) => {
      switch (me.peerType) {
        case constants.PEERS_TYPES.SPEEKER:
          return (
            peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
            peers.some(
              (i) =>
                i.peerType === constants.PEERS_TYPES.INVITED &&
                i.asking &&
                i.peerLang === peer.peerLang
            )
          );
        case constants.PEERS_TYPES.INTERPRETER:
          return (
            peer.isMe ||
            (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
              peers.some(
                (i) =>
                  ((i.peerType === constants.PEERS_TYPES.INVITED && i.asking) ||
                    i.peerType === constants.PEERS_TYPES.SPEEKER) &&
                  i.peerLang === peer.peerLang
              ))
          );

        case constants.PEERS_TYPES.INVITED:
          return (
            (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
              peer.peerLang === me.peerLang) ||
            (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
              me.peerLang == officialLang &&
              peers.some(
                (i) =>
                  i.peerType === constants.PEERS_TYPES.INVITED &&
                  i.asking &&
                  i.peerLang === peer.peerLang
              ))
          );
        default:
          return false;
      }
    });
  });

  const speakersState = useSelector((store) => {
    if (
      store.me.peerType === constants.PEERS_TYPES.SPEEKER &&
      !peers.some((p) => p.id === store.me.id)
    ) {
      peers.push({ ...store.me, isMe: true });
    }
    return peers.filter(
      (peer) => peer.peerType === constants.PEERS_TYPES.SPEEKER
    );
  });

  const img = useSelector((store) => {
    return _.get(
      store,
      "room.info.img",
      _.get(store, "session.client.logo", "")
    );
  });

  const imgStyle = useSelector((store) => {
    try {
      return JSON.parse(_.get(room, "info.imgStyle", {}));
    } catch (e) {
      return {};
    }
  });
  const disableShare = () => {
    const aux = !(me.peerType === constants.PEERS_TYPES.SPEEKER || me.asking);
    return aux;
  };
  const videoProducerShare = producersArray.find(
    (producer) => producer.track.kind === "video" && producer.type == "share"
  );

  const enabledLangs = useMemo(() => {
    const officialLang = _.get(room, "info.officialLang", "es");
    let langs = [];
    if (!langs.includes(officialLang)) langs.push(officialLang);
    peers.forEach((peer) => {
      if (peer.peerLang && !langs.includes(peer.peerLang)) {
        langs.push(peer.peerLang);
      }
    });
    return langs;
  }, [JSON.stringify(peers)]);
  return {
    dispatch,
    me,
    videoProducer,
    enabledCam,
    disabledCam,
    audioProducer,
    disabledMic,
    enabledMic,
    room,
    options,
    peers,
    participantsState,
    interpretersState,
    speakersState,
    disableShare,
    producersArray,
    videoProducerShare,
    enabledLangs,
  };
};
export default useRoomStatus;
