import {MatxLoadable} from "matx";
import {getUriPage} from "../RootPages"
const MyRoomsLists = MatxLoadable({
  loader: () => import("./Lists")
});
const myRoomsRoute = [
  {
    path: getUriPage("module.activity.list"),
    component: MyRoomsLists
  },
];
export default myRoomsRoute;
