//import React, { useEffect, useRef } from "react";
import React, { useState } from "react";
//import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { Appear } from "../../../../transitions";
import classnames from "classnames";
import ViewGrid from "../../../../ViewGrid";
import constants from "../../../../../../../constants";
import Contacts from "../../../../Contacts";
import ChatInput from "../../../../ChatInput";
import AdminPanel from "../../../../AdminPanel";
import Presentation from "../../../../Presentation";
import _ from "lodash";
import ButtonFloatLang from "../../../../buttons/customs/ButtonFloatLang";
import ImageFadeIn from "react-image-fade-in";
import MenuTop from "../../../../MenuTop";
import WaitingState from "../../../../../../components/generic/WaitingState";
import Menu from "../../../../Menu";
import Scrollbar from "react-perfect-scrollbar";
//import { TramRounded } from '@material-ui/icons';

const RoomDesktop = ({ roomClient }) => {
  const room = useSelector((store) => {
    return store.room;
  });
  const me = useSelector((store) => store.me);
  const peers = useSelector((store) => Object.values(store.peers));
  const participantsState = useSelector((store) => {
    const peers = Object.values(store.peers);
    if (store.me.peerType === constants.PEERS_TYPES.INVITED) {
      peers.push({ ...store.me, isMe: true });
    }
    return peers.filter(
      (peer) => peer.peerType === constants.PEERS_TYPES.INVITED && peer.asking
    );
  });

  const interpretersState = useSelector((store) => {
    const peers = Object.values(store.peers);
    if (store.me.peerType === constants.PEERS_TYPES.INTERPRETER) {
      peers.push({ ...store.me, isMe: true });
    }
    const officialLang = _.get(room, "info.officialLang", "es");
    return peers.filter((peer) => {
      switch (me.peerType) {
        case constants.PEERS_TYPES.SPEEKER:
          return (
            peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
            peers.some(
              (i) =>
                i.peerType === constants.PEERS_TYPES.INVITED &&
                i.asking &&
                i.peerLang === peer.peerLang
            )
          );
        case constants.PEERS_TYPES.INTERPRETER:
          return (
            peer.isMe ||
            (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
              peers.some(
                (i) =>
                  ((i.peerType === constants.PEERS_TYPES.INVITED && i.asking) ||
                    i.peerType === constants.PEERS_TYPES.SPEEKER) &&
                  i.peerLang === peer.peerLang
              ))
          );

        case constants.PEERS_TYPES.INVITED:
          return (
            (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
              peer.peerLang === me.peerLang) ||
            (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
              me.peerLang == officialLang &&
              peers.some(
                (i) =>
                  i.peerType === constants.PEERS_TYPES.INVITED &&
                  i.asking &&
                  i.peerLang === peer.peerLang
              ))
          );
        default:
          return false;
      }
    });
  });

  const speakersState = useSelector((store) => {
    const peers = Object.values(store.peers);
    peers.push({ ...store.me, isMe: true });
    return peers.filter(
      (peer) => peer.peerType === constants.PEERS_TYPES.SPEEKER
    );
  });

  const img = useSelector((store) => {
    return _.get(
      store,
      "room.info.img",
      _.get(store, "session.client.logo", "")
    );
  });

  const imgStyle = useSelector((store) => {
    try {
      return JSON.parse(_.get(room, "info.imgStyle", {}));
    } catch (e) {
      return {};
    }
  });

  const participants = participantsState;
  const interpreters = interpretersState;
  const speakers = speakersState;
  const demo = false;
  const showPresentation = _.get(room, "presentation.video");

  const showAdminPanel = room.show_adminPanel;
  const showContact = room.show_contacts;
  const showChat = room.show_chat;
  const cantSpeakers = speakers.length;
  const cantInterpreters = interpreters.length;
  const cantParticipants = participants.length;

  const lateralPeers = showPresentation
    ? interpreters.concat(speakers).concat(participants)
    : interpreters.concat(participants);

  const [isMove, setIsMove] = useState(false);
  let timeout;

  const handleMove = (e) => {
    if (isMove) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setIsMove(false);
      }, 4000);
    } else {
      setIsMove(true);
    }
  };

  return (
    <Appear duration={300}>
      <div data-component="RoomDesktop" onMouseMove={handleMove}>
        <MenuTop />
        <ImageFadeIn
          className={"roomImage"}
          style={imgStyle}
          src={img}
          alt=""
        />
        <div className="wrapper">
          <div
            className={classnames("container-super-grid2", {
              withLateral:
                cantInterpreters > 0 ||
                cantParticipants > 0 ||
                (cantSpeakers > 0 && showPresentation),
            })}
          >
            {!showPresentation && cantSpeakers == 0 && (
              <div className={classnames("super-empty")}>
                <WaitingState isMobile={false} />
              </div>
            )}
            {showPresentation && (
              <div className={classnames("super-presentation")}>
                <Presentation />
              </div>
            )}
            {!showPresentation && cantSpeakers > 0 && (
              <div className={classnames("super-speaker")}>
                <ViewGrid
                  peers={speakers}
                  allPeers={peers}
                  mode={"grid"}
                  demo={demo}
                  transformlabel={false}
                />
              </div>
            )}
            {(cantParticipants > 0 ||
              cantInterpreters > 0 ||
              (cantSpeakers > 0 && showPresentation)) && (
              <React.Fragment>
                <div className={"lateral"}>
                  <div className="divider-zones"></div>
                  <Scrollbar className="content">
                    <ViewGrid
                      peers={lateralPeers}
                      allPeers={peers}
                      mode={"column"}
                      demo={demo}
                      transformlabel={false}
                    />
                  </Scrollbar>
                </div>
              </React.Fragment>
            )}
          </div>
          <div
            className={classnames("panel", {
              hidden: !showContact && !showChat && !showAdminPanel,
            })}
          >
            {showChat && <ChatInput open={true} />}
            {showContact && <Contacts open={true} />}
            <div className={!showAdminPanel ? "hidden" : ""}>
              <AdminPanel open={true} />
            </div>
          </div>
        </div>
        <ButtonFloatLang browserMobile={false} />
        <Menu
          small={showAdminPanel || showContact || showChat}
          isShow={isMove}
        ></Menu>
      </div>
    </Appear>
  );
};

export default RoomDesktop;
