import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers/RootReducer";
const initialState = {
  session: undefined,

  layout: undefined,
  consumers: undefined,
  chat: undefined,
  room: undefined,
  me: undefined,
  peers: undefined,
  producers: undefined,
  dataProducers: undefined,
}

const middlewares = [thunk];
const store = createStore(
  RootReducer,
  initialState,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ ?
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    ) : compose(applyMiddleware(...middlewares))
);
export const Store = store;