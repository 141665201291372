import React from "react";
import ReactAvatar from 'react-avatar';

const Avatar = ({ src, name, size = 32, ...props }) => {

    return (
        <React.Fragment>
            {src ? (
                <img
                    className="mx-8 text-middle circular-image-small cursor-pointer"
                    src={src}
                    alt={name}
                />
            ) : (
                    <ReactAvatar name={name} size={size} round={true} color={props.color}/>
                )}

        </React.Fragment>
    );
};


export default Avatar;
