import { reset } from "redux-form";
import { notify } from "./requestActions";
import translate from "../lang/translate";
import { setRoomInfo } from "./actions/roomActions";
const errorHandler = (dispatch, error) => {
  if (error === 401) {
  }
};
const setSessionExpired = () => {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
  window.location = window.location.origin;
};

export const getUsers = () => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(process.env.REACT_APP_API_URI + "/api/users", {
      method: "get",
      headers: { Authorization: `Bearer ${getState().api.token}` },
    })
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) => {
        dispatch({
          type: "SET_USERS",
          payload: {
            users: resp,
          },
        });
      })
      .catch((e) => errorHandler(dispatch, e));
  };
};

export const getOrganizationData = () => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(process.env.REACT_APP_API_URI + "/api/organizations")
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) => {
        document.title = resp.title;
        dispatch({
          type: "SET_ORGANIZATION",
          payload: {
            name: resp.name,
            logo: resp.mainLogo,
            icon: resp.mainLogo,
            termsAndConditions: resp.termsAndConditions,
          },
        });
      })
      .catch((e) => errorHandler(dispatch, e));
  };
};

export const updateUserData = () => {
  return (dispatch, getState) => {
    // redux thunk
    if (getState().api.token)
      fetch(process.env.REACT_APP_API_URI + "/api/users/current", {
        method: "get",
        headers: { Authorization: `Bearer ${getState().api.token}` },
      })
        .then((r) => {
          if (r.status === 401) setSessionExpired();
          else return r.json();
        })
        .then((resp) => {
          localStorage.setItem("user", JSON.stringify(resp));
          dispatch({
            type: "SET_CURRENT_USER",
            payload: { user: resp },
          });
        })
        .catch((e) => errorHandler(dispatch, e));
  };
};

export const getRooms = () => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(process.env.REACT_APP_API_URI + "/api/rooms", {
      method: "get",
      headers: { Authorization: `Bearer ${getState().api.token}` },
    })
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) => {
        dispatch({
          type: "SET_ALL_ROOMS",
          payload: {
            rooms: resp,
          },
        });
      })
      .catch((e) => errorHandler(dispatch, e));
  };
};

export const setRoomEnabled = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(`${process.env.REACT_APP_API_URI}/api/rooms/${roomId}`, {
      method: "put",
      headers: { Authorization: `Bearer ${getState().api.token}` },
    })
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) =>
        dispatch(notify({ type: "info", text: translate("ROOM_ENABLED") }))
      )
      .catch((e) => errorHandler(dispatch, e));
  };
};

export const createRoom = (roomData) => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(process.env.REACT_APP_API_URI + "/api/rooms", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().api.token}`,
      },
      body: JSON.stringify(roomData),
    })
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) => {
        dispatch({ type: "SET_CREATED_ROOM", payload: { room: resp } });
        dispatch(reset("roomCreation")); // requires form name
      })
      .catch((e) => errorHandler(dispatch, e));
  };
};

export const getRoom = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(`${process.env.REACT_APP_API_URI}/api/rooms/getRoom/${roomId}`)
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) => {
        dispatch({ type: "SET_CURRENT_ROOM", payload: { currentRoom: resp } });
        dispatch(setRoomInfo(resp));
      })
      .catch((e) => errorHandler(dispatch, e));
  };
};
export const getLangs = (roomId) => {
  return (dispatch, getState) => {
    // redux thunk
    fetch(`${process.env.REACT_APP_API_URI}/api/languages`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().api.token}`,
      },
    })
      .then((r) => {
        if (r.status === 401) setSessionExpired();
        else return r.json();
      })
      .then((resp) => {
        dispatch({ type: "SET_LANGS", payload: { langs: resp } });
      })
      .catch((e) => errorHandler(dispatch, e));
  };
};
