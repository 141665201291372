import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import formHelper from "./formHelper";
import JsonView from "../components/dev/JsonView/JsonView";
import {FormProvider as FormCustomProvider} from './FormContext'
// import Loading from "../../generic/Loading/Loading";

const Form = ({ children, loading, initialValues, onSubmit, onCancel, schema, watch, observerWatch, optionsForm = {} }) => {
    const methods = useForm(formHelper.getDefaultConfigForm(initialValues, schema, optionsForm));
    const watchFields = methods.watch(watch);
    const [prevWatchFields, setPrevWatchFields] = useState({})
    const handleSubmit = (data) => {
        onSubmit && onSubmit(data);
    };
    const handleCancel = (data) => {
        onCancel && onCancel(data);
    };
    useEffect(() => {
        if (observerWatch && JSON.stringify(prevWatchFields) != JSON.stringify(watchFields)) {
            setPrevWatchFields(watchFields)
            observerWatch(watchFields);
        }
    }, [watchFields])


    return (
        <React.Fragment>
            <FormProvider {...methods} >
                <FormCustomProvider value={methods.formState}>
                    <form autoComplete={"off"} data-component={"Form"} onSubmit={methods.handleSubmit(handleSubmit)}>
                        {/* <Loading showChildren={true} loading={loading}> */}
                            <React.Fragment>
                                {children}
                            </React.Fragment>
                        {/* </Loading> */}
                    </form>
                </FormCustomProvider>
                <JsonView json={methods.getValues()} title={"Values"}> </JsonView>
                <JsonView json={methods.errors} title={"Error"}> </JsonView>
            </FormProvider>
        </React.Fragment >
    )
}

export default Form;