import {
  Card,
  Grid,
  withStyles
} from "@material-ui/core";
import history from "history.js";
import _ from "lodash";
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import ImageFadeIn from "react-image-fade-in";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import TopDetail from "../../../../modules/components/decorators/TopDetail";
import ResetForm from '../../forms/ResetForm';

import { getUriPage } from "./../../../../views/RootPages";
const styles = theme => ({
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class Reset extends Component {
  state = {
    success: false,

  };

  handleFinishSubmit = (resp) => {
    this.setState({
      success:true
    })
  };

  goToSingIn = ()=>{
    history.push({
      pathname: getUriPage("session.reset-link"),
    });
  }

  render() {
    let { classes } = this.props;
    const { success } = this.state;
    const { hostname } = window.location;
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className={isMobile ? "pl-8 pr-8" : "special-width-card"}>
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className={`p-32 flex flex-center flex-middle h-100${isMobile ? ' frame-detail-mobile' : ''}`}>
                  <ImageFadeIn
                    src={_.get(this, "props.client.logo")}
                    alt=""
                    onError={(e) => {
                      e.target.src = `/assets/logos/solunika.png`
                    }}
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className={`h-100${isMobile ? ' pl-20 pr-20 pb-20' : ' p-20 frame-detail'}`}>
                  <TopDetail
                    title="TEXT_RESET"
                    subtitle={!success?"TEXT_RESET_PLACEHOLDER":""}
                    srcImage={isMobile ? "" : "/assets/images/illustrations/resetDetail.svg"}
                    isMobil={isMobile}
                  />
                  {<ResetForm handleSubmit={this.goToSingIn} classes={classes} />}
                 
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // loginWithEmailAndPassword: PropTypes.func.isRequired,
  // login: state.login,
  client: state.session.client
});

//const mapDispatchToProps = (dispatch) => bindActionCreators({login}, dispatch)
const mapDispatchToProps = (dispatch) => bindActionCreators({  }, dispatch)

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Reset)
  )
);