export default {
  ACTIVE_ROOMS: "Salas activas",
  ACTIVE_ROOMS_NAME: "Nombre de la sala: ",
  ADD_NEW_APP: "Crear nueva app",
  ADD_NEW_ROOM: "Crear nueva sala",
  ADD_NEW_USER: "Crear Usuario",
  APPS: "Mis apps",
  BUTTON_BACK_LOGIN: "Volver",
  CAN_TALK: "Tienes la palabra",
  CHANGED_HIS_LANGUAJE: "ha cambiado si lenguaje de interpretación",
  CHANGED_HIS_NAME_TO: "ha cambiado su nombre a",
  CHANGE_PASSWORD_TEXT_SUBTITLE:
    "Complete los datos, para cambiar su contraseña.",
  CHAT_UNAVAILABLE: "El chat no está disponible.",
  CLOSE_CHAT: "Cerrar ventana de chat",
  CONNECTED: "Conectado",
  CONNECTING: "Conectando",
  COULD_NOT_CHANGE_WEBCAM_RESOLUTION:
    "Error al cambiar la resolución de la cámara",
  CREATE_APP: "Crear app",
  CREATE_ROOM: "Crear sala",
  DASHBOARD_ACTIVE_MEETINGS: "Salas disponibles",
  DASHBOARD_ACTIVE_MEETINGS_PLACEHOLDER:
    "Este listado muestra las salas que has creado o has sido invitado",
  DASHBOARD_CLOSED_MEETINGS: "Reuniones anteriores",
  DASHBOARD_CLOSED_MEETINGS_PLACEHOLDER:
    "En estas salas se hicieron las reuniones a las que asistió o no participó",
  DASHBOARD_FUTURE_MEETINGS: "Reuniones futuras",
  DASHBOARD_FUTURE_MEETINGS_PLACEHOLDER:
    "En estas salas están programadas sus próximas reuniones",
  DASHBOARD_GOTO_AREA: "Ir al listado",
  DATA_CONSUMER_CLOSED: "Consumidor de datos cerrado.",
  DATA_CONSUMER_ERROR: "Error de consumidor de datos",
  ERROR_CLOSING_SERVER_SIDE_SHARE_PRODUCER:
    "Error cerrando el PCDP del lado del servidor",
  ERROR_CLOSING_SERVER_SIDE_WEBCAM_PRODUCER:
    "Error cerrando el productor de video del lado del servidor",
  ERROR_CODE_0001: "El usuario y la contraseña no coinciden",
  ERROR_CODE_0002: "El usuario no está habilitado",
  ERROR_CODE_0003: "El usuario no existe",
  ERROR_CODE_0004: "El token no existe",
  ERROR_CODE_0005: "La sesión ha expirado",
  ERROR_CODE_0006: "El usuario ya ha iniciado sesión",
  ERROR_CODE_0007: "Ha iniciado sesión en otro dispositivo",
  ERROR_CODE_0008: "Fue expulsado por el administrador",
  ERROR_CODE_0009: "La sala fue cerrada",
  ERROR_CODE_0010: "El usuario no está invitado",
  ERROR_CODE_0011: "La sesión ha expirado",
  ERROR_CREATING_CONSUMER: "Error creando un consumidor de datos.",
  ERROR_CREATING_DATA_CONSUMER: "Error creando consumidor de datos",
  ERROR_ENABLING_MIC: "Error habilitando el micrófono",
  ERROR_ENABLING_WEBCAM: "Se produjo un error al habilitar la cámara",
  ERROR_PAUSING_SERVER_SIDE_MIC_PRODUCER:
    "Error pausando el productor de sonido del lado del servidor",
  ERROR_SHARING: "Error compartiendo pantalla",
  HAS_BEEN_GRANTED_SPEAK:
    "tiene la palabra. Se han habilitado su cámara y micrófono.",
  HAS_BEEN_REVOKED_SPEAK:
    " ya no tiene la palabra. Se han deshabilitado su cámara y micrófono.",
  HAS_ENTERED_THE_ROOM: "ha ingresado a la sala",
  HAS_RAISED_HIS_HAND: "ha levantado la mano",
  HIDE_PARTICIPANTS_LIST: "Ocultar panel de participantes",
  HIDE_PRODUCT_INFO: "Ocultar información del producto",
  INPUT_YOUR_NAME: "Nombre",
  INPUT_YOUR_NAME_PLACEHOLDER: "Escriba su nombre para identificarlo",
  INTERPRETER_LENGUAGE_SELECTION: "Lenguaje de interpretación",
  LABEL_LOGOUT_APP: "Salir de la sala",
  LANG_EN: "Ingles",
  LANG_ES: "Español",
  LANG_FR: "Francés",
  LANG_IT: "Italiano",
  LANG_LSA: "Lengua de señas Argentinas",
  LANG_LSB: "Lengua de señas Boliviana",
  LANG_LSC: "Lengua de señas Colombiana",
  LANG_LSCH: "Lengua de señas Chilenas",
  LANG_LSE: "Lengua de señas Español",
  LANG_LSEC: "Lengua de señas Ecuatoriana",
  LANG_LSI: "Lengua de señas Internacional",
  LANG_LSM: "Lengua de señas Mexicanas",
  LANG_LSP: "Lengua de señas Peruana",
  LANG_LSV: "Lengua de señas Venezolana",
  LANG_NL: "Holandés",
  LANG_PT: "Portugués",
  LANG_ZH: "Chino",
  LINK_ACCEPT_TERMS: "Aceptar ",
  LINK_ACCEPT_TERMS_LINK: "términos y condiciones",
  LINK_CHANGE_PASSWORD: "Cambiar contraseña",
  LINK_COPIED_TO_CLIPBOARD:
    "El link de la sala ha sido copiado al portapapeles",
  LOGIN_ERROR: "Credenciales inválidas.",
  LOGOUT_DEFAULT: "Te agradecemos por usar esta nueva manera de comunicarse.",
  LOGOUT_DEFAULT_SUBTITLE: "Por usar nuestra plataforma de reuniones",
  LOGOUT_DEFAULT_TITLE: "¡Gracias!",
  MENU_ACTIVITIES: "Registro de actividad",
  MENU_DASHBOARD: "Tablero",
  MENU_MONITOR: "Monitoreo",
  MENU_MY_APPS: "Mis apps",
  MENU_MY_APPS_PLACEHOLDER: "Estas son las apps que usted ha creado",
  MENU_MY_ROOMS: "Mis salas",
  MENU_MY_ROOMS_PLACEHOLDER: "Estas son las salas que usted ha creado",
  MENU_NEW_APP: "Nueva app",
  MENU_NEW_APP_PLACEHOLDER:
    "Complete los datos requeridos para crear la nueva app.",
  MENU_NEW_ROOM: "Nueva sala",
  MENU_NEW_ROOM_PLACEHOLDER:
    "Complete los datos requeridos para crear la nueva sala.",
  MENU_UPDATE_USER: "Modificar usuario",
  MENU_USERS: "Usuarios",
  MENU_USER_NAME: "Lista de Usuarios",
  MIC_DISCONNECTED: "¡Micrófono desconectado!",
  MODAL_BUTTON_CAMERA: "Cámara",
  MODAL_BUTTON_CAMERA_MICRO: "Cámara y micrófono",
  MODAL_BUTTON_DISABLED: "Ninguno",
  MODAL_BUTTON_MICRO: "Micrófono",
  MODAL_CLOSE_CUSTOMER_TITLE: "¿Estas seguro de que deseas salir?",
  MODAL_CLOSE_PEER_SUBTITLE: "Te han hecho abandonar el sitio.",
  MODAL_CLOSE_PEER_TITLE: "Has sido desconectado",
  MODAL_CLOSE_ROOM_SUBTITLE: "¡Esperamos que vuelvas pronto!",
  MODAL_CLOSE_ROOM_TITLE: "¿Ya te vas?",
  MODAL_ENABLEDPEER_SUBTITLE: "Ahora otras personas podrán verlo y escucharlo.",
  MODAL_ENABLEDPEER_TITLE: "Tienes permiso para hablar",
  MODAL_FORUSER_CLOSEROOM:
    "El administrador ha cerrado esta sala y por lo tanto ya no puede estar en ella, no podrá ingresar nuevamente hasta que este activa.",
  MODAL_FORUSER_CLOSEROOM_SUBTITLE: "Ya no hay nadie aquí...",
  MODAL_FORUSER_CLOSEROOM_TITLE: "La sala se ha cerrado",
  MODAL_FORUSER_EJECT:
    "Por alguna razón, el administrador ha decidido expulsarlo de esta sala, comuníquese con el administrador para más detalles.",
  MODAL_FORUSER_EJECT_SUBTITLE: "No puede seguir participando",
  MODAL_FORUSER_EJECT_TITLE: "Ha sido expulsado",
  MODAL_FORUSER_MULTIPLEDEVICE:
    "Has entrado en la misma sala desde otro dispositivo, por ese motivo se te ha sacado de la sala. Podrás participar desde el otro dispositivo.",
  MODAL_FORUSER_MULTIPLEDEVICE_SUBTITLE: "Accediste desde otro dispositivo",
  MODAL_FORUSER_MULTIPLEDEVICE_TITLE: "¿Entraste de nuevo?",
  MODAL_FORUSER_NOTINVITED:
    "El administrador de la sala no ha permitido que entres en ella, comunícate con él para que te permita el acceso.",
  MODAL_FORUSER_NOTINVITED_SUBTITLE: "No estás invitado a esta sala",
  MODAL_FORUSER_NOTINVITED_TITLE: "No puedes entrar",
  MODAL_KICK_PEER_SUBTITLE:
    "Estas a punto de echar a esa persona de la reunión",
  MODAL_KICK_PEER_TEXT:
    "¿Estás seguro de que deseas expulsar de la sala al participante?",
  MODAL_KICK_PEER_TITLE: "Expulsar participante",
  MODAL_TERMS_SUBTITLE: "Por favor lea las condiciones del servicio",
  MODAL_TERMS_TITLE: "Términos y condiciones",
  NEWUSER_INCORRECT_PASSWORD:
    "La contraseña tiene que tener de 8 a 16 caracteres, un número, una mayúscula y una minúscula.",
  NEWUSER_INVALID_EMAIL: "El email tiene que ser válido",
  NEWUSER_PASSWORD_NOT_MACH: "Las contraseñas no coinciden",
  NEWUSER_PASSWORD_REQUIRED: "Es necesaria la contraseña",
  NOTIFICATION_CREATE_ROOM_SUCCESS: "Sala creada con éxito",
  NOTIFICATION_DISABLED_ROOM_SUCCESS: "Sala desactivada",
  NOTIFICATION_ENABLED_ROOM_SUCCESS: "Sala activada con éxito",
  NOTIFICATION_ERROR: "Error en el server",
  NOTIFICATION_INTERPRETER_INCITED_SUCCESS:
    "Un intérprete pronto se sumará a la sala...",
  NOTIFICATION_SUCCESS: "Operación realizada con éxito",
  NO_ACTIVE_ROOMS: "No hay salas activas",
  PAGE_PASSWORD_RESETED:
    "Te hemos enviado, a tu correo electrónico, una contraseña temporal con la cual podrás iniciar sesión. Recuerda que una vez que ingreses deberás cambiarla por una de tu elección.",
  PAGE_PASSWORD_RESETED_SUBTITLE: "Ha completado el proceso",
  PAGE_PASSWORD_RESETED_TITLE: "Nueva contraseña",
  PAGE_RESET_LINK:
    "Te hemos enviado un correo electrónico a la cuenta que especificaste con los pasos a seguir para generar una nueva contraseña de acceso. Revisa tu correo y abre el link que te hemos enviado.",
  PAGE_RESET_LINK_SUBTITLE: "Lea con atención para seguir el proceso",
  PAGE_RESET_LINK_TITLE: "Cambio aceptado",
  PEER_ASKING: "HABILITADO",
  PEER_NO_ASKING: "DESHABILITADO",
  RAISE_HAND: "MANO LEVANTADA",
  READ_MORE: "Leer más",
  RECORD_WARNING:
    "Tenga en cuenta que para grabar el audio y video de todos los participantes deberá elegir como dispositivo a compartir la pestaña actual de su navegador y activar la opción de compartir audio.",
  REQUIRED_FIELD: "El campo es obligatorio",
  RESET_TEXT_SEND_BY_EMAIL:
    "Se ha enviado un mail, para poder recuperar la contraseña",
  ROLES_ADMIN: "Admin",
  ROLES_INTERPRETER: "Intérprete",
  ROLES_INVITED: "Participante",
  ROLES_SPEEKER: "Orador",
  ROOM_ENABLED: "La sala ya se encuentra habilitada.",
  SHARE_DISCONNECTED: "Se ha desconectado la pantalla compartida",
  SHARE_MY_SCREEN_OFF: "Dejar de compartir mi pantalla",
  SHARE_MY_SCREEN_ON: "Compartir mi pantalla",
  SHOW_HIDE_PARTICIPANTS_VIDEO: "Mostrar / ocultar mi pantalla",
  SHOW_PARTICIPANTS_LIST: "Panel de participantes",
  SHOW_PRODUCT_INFO: "Información del producto",
  SNACK_COPY_API_KEY: "La api key ha sido copiada al portapapeles",
  SNACK_COPY_LINK: "El link ha sido copiado al portapapeles",
  STOP_RAISE_HAND: "MANO BAJA",
  TEXT_404: "Lo siento, la página que estás buscando no existe",
  TEXT_ACCEPT: "Cerrar sala",
  TEXT_ACTION: "Acciones",
  TEXT_ACTIVITY_STATUS: "Estado",
  TEXT_ADDITIONAL_DATA: "Configuración Avanzada",
  TEXT_ADD_CUSTOM_FIELD: "Agregar dato solicitado",
  TEXT_ADD_PLURAL1: "es",
  TEXT_ADD_PLURAL2: "s",
  TEXT_ADD_PLURAL3: "n",
  TEXT_ADD_USER: "Agregar usuario",
  TEXT_ADMIN_PANEL: "Panel del administrador",
  TEXT_API_KEY: "Api key",
  TEXT_APP_NAME: "Nombre de la app",
  TEXT_APP_NAMES_PLACEHOLDER: "Nombre descriptivo para la sala",
  TEXT_APP_WEBHOOK_INTERPRETER: "Webhook del intérprete",
  TEXT_APP_WEBHOOK_INTERPRETER_PLACEHOLDER: "Ingrese el webhook del intérprete",
  TEXT_APP_WEBHOOK_SUPPORT: "Webhook del soporte",
  TEXT_APP_WEBHOOK_SUPPORT_PLACEHOLDER: "Ingrese el webhook del soporte",
  TEXT_AVAILABLE: "Disponible",
  TEXT_BUTTON_ADD_FIELD_PARTICIPANT: "Agregar campo de participante",
  TEXT_BUTTON_CANCEL: "Cancelar",
  TEXT_BUTTON_CLOSE: "Cerrar",
  TEXT_BUTTON_CONFIRM: "Aceptar",
  TEXT_BUTTON_ENABLED: "Habilitar",
  TEXT_BUTTON_ERASE: "Borrar",
  TEXT_BUTTON_EXIT: "Salir",
  TEXT_BUTTON_KICK: "Expulsar",
  TEXT_BUTTON_SOLICITE: "Solicitar",
  TEXT_CAM_OFF: "Cámara apagado",
  TEXT_CAM_ON: "Cámara encendido",
  TEXT_CHAT_PANEL: "Panel de mensajes",
  TEXT_CLOSE_PANEL: "Cerrar panel",
  TEXT_CLOSE_PEER: "Cerrar",
  TEXT_CLOSE_ROOM: "Cerrar sala",
  TEXT_CONECTOR1: "para",
  TEXT_CONECTOR2: "de",
  TEXT_COPY_API_KEY: "Copiar api key",
  TEXT_COPY_LINK_ROOM: "Copiar link de la sala",
  TEXT_CREATE_ACCOUNT: "Enviar",
  TEXT_CREATE_ROOM: "Crear sala",
  TEXT_CREATE_ROOMS: "Crear",
  TEXT_CUSTOMER_CANCEL_BUTTON: "No",
  TEXT_CUSTOMER_EXIT_BUTTON: "Si",
  TEXT_DATE: "Fecha",
  TEXT_DATE_OF_MEETING_ACTIVE: "Fecha y hora de la reunión",
  TEXT_DATE_OF_MEETING_FUTURE: "Fecha y hora acordada",
  TEXT_DATE_OF_MEETING_PAST: "Fecha y hora en que se realizó",
  TEXT_DELETE_ROOM: "Borrar sala",
  TEXT_DISABLED_ROOM: "Deshabilitar Sala",
  TEXT_DOTS: "...",
  TEXT_DURATION: "Duración",
  TEXT_ECOMERSESUPPORT: "Soporte online",
  TEXT_EDIT: "Editar",
  TEXT_EDIT_USER: "Editar datos de Usuario",
  TEXT_EJECT_PARTICIPANTS_ROOM: "Expulsar participantes",
  TEXT_EMPTY_STATE: "Parece que no hay nada aquí...",
  TEXT_ENABLED: "Habilitado",
  TEXT_ENABLED_ROOM: "Habilitar sala",
  TEXT_ERROR_URL_VIDEO: "La URL del video es incorrecta",
  TEXT_EVENT: "Evento con traductor",
  TEXT_EXIT_ROOM: "Abandonar la sala",
  TEXT_EXIT_ROOM_TEXT:
    "Puedes abandonar la sala, pero la reunión seguirá sin tu presencia.",
  TEXT_EXIT_ROOM_TEXT_ADMIN:
    "Como eres el dueño de la sala, puedes cerrarla y sacar a todos los participantes.",
  TEXT_FIRSTNAME: "Nombre",
  TEXT_FIRSTNAME_PLACEHOLDER: "Escriba su nombre",
  TEXT_GOTO_ROOM: "Ir a la sala",
  TEXT_GOTO_START: "Ir al inicio",
  TEXT_GOTO_TOP: "Ir al principio",
  TEXT_HAVE: "Tiene",
  TEXT_HEADER_PLACEHOLDER: "No te pierdas ninguna de tus reuniones",
  TEXT_INFO_ROOM: "Información de la sala",
  TEXT_INFO_SPEEKER: "Información del orador",
  TEXT_INIT_FORGOT_PASSWORD: "Recuperar contraseña",
  TEXT_INIT_SESSION_ENTER: "Ingresar",
  TEXT_INIT_SESSION_PLACEHOLDER: "Ingrese los datos solicitados",
  TEXT_INIT_SIGN_UP: "¿Ingresa por primera vez?",
  TEXT_INTERPRETER_GOTO_PARTICIPANTE: "Entrar como participante",
  TEXT_INTRO_GO_TO_LOGIN: "Ingresar con mi cuenta",
  TEXT_IS_ADMIN: "Administrador",
  TEXT_LABEL_FIELD: "Etiqueta del campo",
  TEXT_LASTNAME: "Apellido",
  TEXT_LASTNAME_PLACEHOLDER: "Escriba su apellido",
  TEXT_LISTENER: "Participante",
  TEXT_LOADER_CUSTOMER: "Esperando al operador",
  TEXT_LOADER_NULL: "Esperando",
  TEXT_LOADER_SUPPORT: "Esperando al cliente",
  TEXT_LOGIN: "Entrar",
  TEXT_LOGOUT: "Cerrar sesión",
  TEXT_MAIL: "Correo electrónico",
  TEXT_MAXIMIZAR: "Maximizar",
  TEXT_ME: "Yo",
  TEXT_MENU_ADD_SPEAKER: "Asignarlo como orador",
  TEXT_MENU_DISABLED_PARTICIPANT: "Quitarle la palabra",
  TEXT_MENU_EJECT: "Expulsar",
  TEXT_MENU_ENABLED_PARTICIPANT: "Darle la palabra",
  TEXT_MENU_MUTE: "Silenciar",
  TEXT_MENU_REMOVE_SPEAKER: "Quitarlo como orador",
  TEXT_MENU_TITLE_CHAT: "Mensajes",
  TEXT_MENU_TITLE_CONTACTS: "Contactos",
  TEXT_MENU_TITLE_LANG: "Idioma",
  TEXT_MENU_TITLE_OPTIONS: "Opciones",
  TEXT_MENU_UNIQUE_SPEAKER: "Hacer orador único",
  TEXT_MIC_OFF: "Micrófono apagado",
  TEXT_MIC_ON: "Micrófono encendido",
  TEXT_MINIMIZAR: "Minimizar",
  TEXT_MODAL_YOU_WORD:
    "Se la ha dado la palabra, por favor seleccione el dispositivo a utilizar.",
  TEXT_MODAL_YOU_WORD_CUSTOMER: "Ya puede activar cámara y micrófono",
  TEXT_MODAL_YOU_WORD_CUSTOMER_CLOSE: "Cerrar",
  TEXT_MODAL_YOU_WORD_CUSTOMER_YES: "Si",
  TEXT_MODAL_YOU_WORD_CUSTOMER_NO: "No",
  TEXT_MORE_ACTIONS: "Más acciones",
  TEXT_NAME_FIELD: "Nombre del campo",
  TEXT_NEWROOM_DESING: "Diseño",
  TEXT_NEWROOM_LENGUAJES: "Idioma e interpretación",
  TEXT_NEWUSER: "Activar usuario",
  TEXT_NEWUSER_PLACEHOLDER: "Complete los datos solicitados",
  TEXT_NEW_PASSWORD: "Nueva contraseña",
  TEXT_NEW_PASSWORD_PLACEHOLDER: "Escriba una nueva contraseña",
  TEXT_NOTAVAIBLE_STATE: "Esta característica no está disponible...",
  TEXT_NOTHING_TO_SAY: "No digo nada...",
  TEXT_NO_INTERPRETER: "En este momento, no hay intérpretes disponibles.",
  TEXT_ONLYUSERS: "Conferencia privada",
  TEXT_ORGANIZATION: "Organización",
  TEXT_PARTICIPANT_PANEL: "Listado de participantes",
  TEXT_PASSWORD: "Contraseña",
  TEXT_PASSWORD_PLACEHOLDER: "Escriba la contraseña actual",
  TEXT_PASSWORD_VERIFY: "Verificación de contraseña",
  TEXT_PASSWORD_VERIFY_PLACEHOLDER: "Repita la nueva contraseña",
  TEXT_PERMISSIONS: "Permiso",
  TEXT_PIP: "Picture in picture",
  TEXT_PROFILE: "Rol",
  TEXT_RECORD_VIDEO: "Grabador",
  TEXT_REQUIRED_FIELD: "Debe completar este dato",
  TEXT_RESET: "Cambiar contraseña",
  TEXT_RESET_BYMAIL: "Cambiar contraseña",
  TEXT_RESET_PLACEHOLDER: "Ingrese su mail de usuario",
  TEXT_ROOM: "Sala",
  TEXT_ROOMS: "Mis salas",
  TEXT_ROOM_ADD_FIELDS_REGISTERS: "Información adicional a solicitar",
  TEXT_ROOM_ALIAS: "Alias de la sala",
  TEXT_ROOM_ALIAS_PLACEHOLDER: "Alias de la sala",
  TEXT_ROOM_AT: "a las",
  TEXT_ROOM_BLOCK_ROOM_MESSAGE: "Mensaje cuando la sala bloqueada",
  TEXT_ROOM_BLOCK_ROOM_MESSAGE_PLACEHOLDER:
    "Este mensaje aparecerá cuando se intente entrar a esta sala y esté bloqueada",
  TEXT_ROOM_CALL_INTERPRETER_COMMENT: "Comentario",
  TEXT_ROOM_CALL_INTERPRETER_LANGUAGE: "Seleccione un idioma",
  TEXT_ROOM_CLOSE_INFO: "Cerrar",
  TEXT_ROOM_DATA_INIT: "Complete los datos",
  TEXT_ROOM_DATA_INIT_INTERPRETER: "Deberá seleccionar el idioma",
  TEXT_ROOM_DATE: "Fecha del evento",
  TEXT_ROOM_DATE_PLACEHOLDER:
    "Especifique la fecha en que se realizará el evento",
  TEXT_ROOM_DEFAULT_LANG: "Idioma principal",
  TEXT_ROOM_DESCRIPTION: "Descripción",
  TEXT_ROOM_DESCRIPTION_INFO: "Descripción de la sala",
  TEXT_ROOM_DESCRIPTION_PLACEHOLDER: "Breve descripción de la sala",
  TEXT_ROOM_DISABLED_ROOM_MODAL_DETAILS:
    "Está ingresando a una sala que no se encuentra disponible. Para que otras personas puedan ingresar tiene que ser habilitada.",
  TEXT_ROOM_DISABLED_ROOM_MODAL_SUBTITLE:
    "En este estado los participantes no pueden ingresar.",
  TEXT_ROOM_DISABLED_ROOM_MODAL_TITLE: "Sala deshabilitada",
  TEXT_ROOM_IMAGE_STYLE: "CSS para la imagen de la sala",
  TEXT_ROOM_IMAGE_STYLE_PLACEHOLDER: "Estilos para la imagen de la sala",
  TEXT_ROOM_IMG: "URL de la imagen para la sala",
  TEXT_ROOM_IMG_PLACEHOLDER:
    "Dirección URL de la imagen que se mostrará como identificación de la sala",
  TEXT_ROOM_INFO: "Información de la Sala",
  TEXT_ROOM_INIT: "Ingresa en la sala",
  TEXT_ROOM_INITE_NOT_AVAILABLE: "No hay participantes activos",
  TEXT_ROOM_INIT_SUBTITLE: "¡Todo listo para la reunión!",
  TEXT_ROOM_INTERPRETER_NOT_AVAILABLE: "No hay intérpretes activos",
  TEXT_ROOM_LANGS_SUPPORT: "Idiomas de interpretación",
  TEXT_ROOM_NAME: "Nombre de la sala",
  TEXT_ROOM_NAMES: "Nombre de la sala",
  TEXT_ROOM_NAMES_PLACEHOLDER: "Nombre descriptivo para la sala",
  TEXT_ROOM_NAME_INFO: "Nombre de la sala",
  TEXT_ROOM_NOT_AVAILABLE:
    "La sala aún no se encuentra habilitada, puede que el evento no haya comenzado todavía.",
  TEXT_ROOM_NOT_AVAILABLE_SUBTITLE: "No puedes entrar",
  TEXT_ROOM_NOT_AVAILABLE_TITLE: "Hay un problema...",
  TEXT_ROOM_NO_DESCRIPTION: "(Sala sin descripción)",
  TEXT_ROOM_OWNER: "Propietario",
  TEXT_ROOM_ROLES: "Roles",
  TEXT_ROOM_SELECT_LANGUAGE: "Solicitar un intérprete",
  TEXT_ROOM_TYPE: "Tipo de sala",
  TEXT_ROOM_UNTITLED: "(Sala sin nombre)",
  TEXT_ROOM_URL_FINISH: "URL de fin de sala",
  TEXT_ROOM_URL_FINISH_PLACEHOLDER:
    "Al cerrar o salir de la sala se abrirá esta URL",
  TEXT_ROOM_USERNAME: "Nombre de usuario / email",
  TEXT_ROOM_USERS: "Usuarios",
  TEXT_ROTATE: "Rotar",
  TEXT_SAY: "dice:",
  TEXT_SEARCH: "Buscar",
  TEXT_SHARE_SCREEN: "Compartiendo pantalla",
  TEXT_SHOULD_UPDATE_PASSWORD: "Debe actualizar la contraseña",
  TEXT_START_RECORD: "Iniciar grabación",
  TEXT_STOP_RECORD: "Detener grabación",
  TEXT_THE_WORD: "la palabra",
  TEXT_TODO_UPDATE: "Actualizando...",
  TEXT_TOTAL: "Total",
  TEXT_TRANSMIT_DISABLE_TOOLTIP: "Desactivar transmisión",
  TEXT_TRANSMIT_ENABLE_TOOLTIP: "Activar transmisión",
  TEXT_TRANSMIT_VIDEO: "Transmitir video",
  TEXT_TRANSMIT_VIDEO_URL_PLACEHOLDER: "URL del video",
  TEXT_TYPE_FIELD: "Tipo de campo",
  TEXT_UNDEFINED: "(No definido)",
  TEXT_UPDATE: "Actualizar",
  TEXT_UPDATE_USER: "Guardar cambios",
  TEXT_USER: "Usuario",
  TEXT_USERNAME: "Nombre de usuario / email",
  TEXT_USERNAME_MAIL: "Dirección de email",
  TEXT_USERNAME_MAIL_PLACEHOLDER: "Correo electrónico",
  TEXT_USERNAME_PLACEHOLDER: "Nombre de usuario / email",
  TEXT_USER_NAME: "Nombre de usuarios",
  TEXT_VALUE_REQUIRED: "Valor requerido",
  TEXT_VIDEO_CHANGE: "Cambiar video",
  TEXT_VIDEO_LOAD: "Cargar video",
  TEXT_WAITING_STATE: "¡Espera un poco! ya estaremos contigo",
  TEXT_WELCOME_TOBOARD: "¡Bienvenido al tablero!",
  TEXT_YOU_ARE: "Estas en la sala",
  TOOLTIP_CANT_SPEAK: "No tienes la palabra",
  TOOLTIP_CAN_SPEAK: "Puede hablar",
  TOOLTIP_CHANGE_CAMERA: "Cambiar cámara",
  TOOLTIP_CONNECTED: "Conectado",
  TOOLTIP_DISCONNECTED: "Desconectado",
  TOOLTIP_DOWN_HAND: "Bajar la mano",
  TOOLTIP_ENABLED_DISABLED_MICROPHONE: "Activar / desactivar micrófono",
  TOOLTIP_ENABLED_DISABLED_SPEAK:
    "Habilitar / deshabilitar sonido participante",
  TOOLTIP_ENABLED_DISABLED_SPEAKER: "Hacer orador",
  TOOLTIP_HIDE_ADMIN: "Ocultar panel del administrador",
  TOOLTIP_HIDE_CHAT: "Ocultar panel de mensajes",
  TOOLTIP_INVITE_INTERPRETE: "Solicitar intérprete",
  TOOLTIP_LOGOUT_ROOM: "Salir de la sala",
  TOOLTIP_MIC: "Micrófono encendido",
  TOOLTIP_RAISE_HAND: "Levantar la mano",
  TOOLTIP_RAISE_HANDUP: "Levantaste la mano",
  TOOLTIP_REMOVE_USER: "Borrar usuario",
  TOOLTIP_SELECT_LANG: "Seleccionar idioma",
  TOOLTIP_SHOW_ADMIN: "Panel del administrador",
  TOOLTIP_SHOW_CHAT: "Panel de mensajes",
  TOOLTIP_SHOW_HIDE_ME: "Activar / desactivar cámara",
  TOOLTIP_SHOW_HIDE_PARTICIPANTS: "Mostrar participantes activos",
  TOOLTIP_SHOW_SETTINGS: "Más opciones",
  TOOLTIP_STOP_MIC: "Micrófono apagado",
  TOOLTIP_STOP_RAISE_HAND: "No ha levantado la mano",
  WEBCAM_DISCONNECTED: "¡Cámara desconectada!",
  WEB_SOCKET_CONNECTION_FAILED: "Conexión de WebSocket fallida",
  WEB_SOCKET_DISCONECTED: "La conexión de WebSocket ha sido desconectada.",
  WRITE_HERE_MOBILE: "Escriba aquí",
  WRITE_HERE_WEB: "Escriba aquí y presione enter",
  YOUARE_INTERPRETER: "Eres Intérprete",
  YOUARE_LISTENER: "Eres participante",
  YOUARE_SPEAKER: "Eres orador",
  connected: "Conectado",
  connecting: "Conectando",
  TEXT_ROOM_DELETED_SUBTITLE:
    "Esta sala no se encuentra disponible o no existe",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_ON: "Si",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_OFF: "No",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_TITLE: "Soporte Técnico ",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_SUBTITLE:
    "Quiere entrar con la cámara y el micrófono activados?",
  BUTTON_FULL_SCREEN: "Pantalla completa",
  SHARE_MY_SCREEN_ON_OFF: "Compartir / Dejar de compartir pantalla",
  TEXT_EXIT: "Salir",
  TEXT_PROCESSING_VIDEO: "Procesando grabación",
  TOOLTIP_ENABLED_MICROPHONE: "Micrófono",
  TOOLTIP_DISABLED_MICROPHONE: "Desactivar micrófono",
  TOOLTIP_ENABLED_CAMERA: "Cámara",
  TOOLTIP_DISABLED_CAMERA: "Desactivar cámara",
  BUTTON_DISABLED_FULL_SCREEN: "Desactivar pantalla completa",
  TOGGLE_SHOW_MENU: "Menú",
  TOGGLE_LANG: "Cambiar idioma",
  CHANGED_LANG_NOTIFICATION: "Idioma cambiado",
  BUTTON_HIDE_ME: "Ocultar / Mostrar mi cámara",

};
