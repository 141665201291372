import _ from "lodash";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import ButtonToggleMenu from "./ButtonToggleMenu";
//import classnames from "classnames";
import { changeLang } from "../../../../../../../redux/stateActions";
//import ButtonMenuTop from "../ButtonMenuTop";
import translate from "app/lang/translate";
import { PEERS_TYPES } from "app/constants";
import StatusBar from "../../event/desktop/StatusBar";
//import StatusBar from "../Room/roomTypes/event/desktop/StatusBar";
import ListItem from "app/modules/components/menu/ListItem";

import ButtonCamera from "./ButtonCamera";
import ButtonMicrophone from "./ButtonMicrophone";
import ButtonClose from "./ButtonClose";

import ButtonShareScreen from "./ButtonShareScreen";
import ButtonFullScreen from "./ButtonFullScreen";
import { Translate } from "@material-ui/icons";
import useRoomStatus from "../../../hooks/useRoomStatus";
import useApi from "../../../hooks/useApi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const MenuTopCustomer = () => {
  const [setWebcamState] = useState(null);
  const [canSendWebcam, setCanSendWebcam] = useState(null);
  const { me, dispatch, videoProducerShare, room, peers, enabledLangs } =
    useRoomStatus();
  const interpreter = useMemo(
    () => peers.find((p) => p.peerType === PEERS_TYPES.INTERPRETER),
    [peers.length]
  );
  useEffect(() => {
    if (canSendWebcam && !me.canSendWebcam) {
      if (!me.canSendWebcam) setWebcamState(null);
      else if (videoProducerShare) setWebcamState(true);
      else setWebcamState(false);
      setCanSendWebcam(me.canSendWebcam);
    }
  });
  const store = useSelector((store) => store);

  const toggleLang = useCallback(() => {
    if (interpreter) {
      if (
        _.get(interpreter, "peerLang") &&
        me.peerLang !== interpreter.peerLang
      ) {
        dispatch(changeLang(interpreter.peerLang));
      }
      if (
        _.get(room, "info.officialLang") &&
        me.peerLang !== room.info.officialLang
      ) {
        dispatch(changeLang(room.info.officialLang));
      }
    }
  }, [me.peerLang, interpreter, room, dispatch]);
  const { search } = useLocation();

  const hideFullScreenButton = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const hideFullScreenButtonParam = searchParams.get("hideFullScreenButton");
    console.log(hideFullScreenButtonParam);
    return hideFullScreenButtonParam;
  }, [search]);

  return (
    <div data-component="MenuTopCustomer">
      <div className={"botoneraLeft"}>
        <StatusBar showDescriptions={false} showPersonIcon={false} />
      </div>

      <div className={"botoneraRight"}>
        <ButtonToggleMenu
          buttons={[
            interpreter && (
              <ListItem
                onClick={toggleLang}
                icon={<Translate />}
                text={translate("TOGGLE_LANG")}
              />
            ),
          ]}
        ></ButtonToggleMenu>
        {!hideFullScreenButton && <ButtonFullScreen></ButtonFullScreen>}
        <ButtonCamera></ButtonCamera>
        <ButtonMicrophone></ButtonMicrophone>
        <ButtonShareScreen></ButtonShareScreen>
        <ButtonClose></ButtonClose>
      </div>
    </div>
  );
};

export default MenuTopCustomer;
