import React, { useContext, useMemo } from "react";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import useRoomStatus from "../../../hooks/useRoomStatus";
import RoomContext from "app/modules/meeting/RoomContext";

function ButtonCamera() {
  const { me, videoProducer, disabledCam, enabledCam } = useRoomStatus();
  const roomClient = useContext(RoomContext);

  const CameraIcon = useMemo(
    () => () => {
      if (enabledCam) {
        return <VideocamIcon />;
      } else if (!enabledCam) {
        return <VideocamOffIcon />;
      } else {
        return null;
      }
    },
    [enabledCam]
  );

  return (
    <ButtonMenuTopCustomer
      tip="TOOLTIP_SHOW_HIDE_ME"
      disabled={disabledCam}
      child={<CameraIcon />}
      actionClick={() => {
        if (
          me.canSendWebcam &&
          videoProducer &&
          videoProducer.type !== "share"
        ) {
          roomClient.disableWebcam();
        } else {
          roomClient.enableWebcam();
        }
      }}
    ></ButtonMenuTopCustomer>
  );
}

export default ButtonCamera;
