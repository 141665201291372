import React from "react";
import { Card } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import translate from "../../../app/lang/translate";
import TopDetail from "../../../app/modules/components/decorators/TopDetail";

import {
  IconButton,
  Icon,
} from "@material-ui/core";

const SimpleCard = ({ 
  children, 
  title, 
  subtitle,
  update = false,
  toTop = false,
  useImage = false,
  isMobil = false,
  toUpdate,
  srcImage,
  actionToTop
}) => {
  return (
    <Card elevation={6} className="px-24 py-20 frame-detail-special">
      {useImage ? (
        <TopDetail
          title={title}
          subtitle={subtitle}
          srcImage={srcImage}
          isMobil={isMobil}
        />
      ) : (
        <div style={{ width: '100%' }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <h2 className="capitalize-flfw">{title}</h2>
              <div className="card-subtitle mb-24">{subtitle}</div>
            </Box>
            <Box>
              {toTop ? (
                <Tooltip title={translate("TEXT_GOTO_TOP")}>
                  <IconButton onClick={() => actionToTop()}>
                    <Icon>
                      keyboard_arrow_up
                    </Icon>
                  </IconButton>
                </Tooltip>
              ) : null}
              {update ? (
                <Tooltip title={translate("TEXT_UPDATE")}>
                  <IconButton onClick={() => toUpdate()}>
                    <Icon>
                      cached
                    </Icon>
                  </IconButton>
                </Tooltip>
              ) : null }
            </Box>          
          </Box>
        </div>
      )}
      {children}
    </Card>
  );
};

export default SimpleCard;
