import React, { Component } from "react";
//import React from "react";
import { Card, Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

// import LoginForm from "../../forms/LoginForm";
import _ from "lodash";
import { bindActionCreators } from "redux";
import ImageFadeIn from "react-image-fade-in";
import TopDetail from "../../../../modules/components/decorators/TopDetail";
import { isMobile } from "react-device-detect";
import translate from "../../../../lang/translate";
import Button from "./../../../../modules/components/buttons/Button";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class ResetLink extends Component {
  // state = {
  //   email: "",
  //   password: "",
  //   agreement: "",
  // };
  // handleFinishSubmit = (resp) => {
  //   this.props.login(resp.token, resp.user);
  // };

  gotoStart = () => {
    window.location = "/";
  };

  render() {
    // let { classes } = this.props;
    // const { hostname } = window.location;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className={isMobile ? "pl-8 pr-8" : "special-width-card"}>
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div
                  className={`p-32 flex flex-center flex-middle h-100${
                    isMobile ? " frame-detail-mobile" : ""
                  }`}
                >
                  <ImageFadeIn
                    src={_.get(this, "props.client.logo")}
                    onError={(e) => {
                      e.target.src = `/assets/logos/solunika.png`;
                    }}
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12} className="room-name">
                <div
                  className={`h-100${
                    isMobile ? " pl-20 pr-20 pb-20" : " p-20 frame-detail"
                  }`}
                >
                  <TopDetail
                    title="PAGE_RESET_LINK_TITLE"
                    subtitle="PAGE_RESET_LINK_SUBTITLE"
                    srcImage={
                      isMobile
                        ? ""
                        : "/assets/images/illustrations/ResetLink.svg"
                    }
                    isMobil={isMobile}
                  />
                  <div className="mb-16 recuadre">
                    <div className="room-name">
                      {translate("PAGE_RESET_LINK")}
                    </div>
                  </div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      type="button"
                      color={"primary"}
                      onClick={() => this.gotoStart()}
                    >
                      {translate("TEXT_GOTO_START")}
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,

  client: state.session.client,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetLink))
);
