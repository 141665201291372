import React from "react";
import translate from "app/lang/translate";
import LinkComponent from "app/modules/components/links/Link";
import Scrollbar from "react-perfect-scrollbar";
import _ from "lodash";

function ProductPanel({ extraData }) {
  const elements = _.get(extraData,"elements",[]);

  return (
    <Scrollbar data-component="ProductPanel" option={{ suppressScrollX: true }}>
      {elements.map((el, index) => {
        if (el.type === "html")
          return (
            <div style={{ padding: "15px" }} key={"html-" + index}>
              {_.get(el, "content", false) && (
                <div dangerouslySetInnerHTML={{ __html: el.content }}></div>
              )}
              {_.get(el, "props.link", false) && (
                <LinkComponent
                  href={el.props.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate("READ_MORE")}
                </LinkComponent>
              )}
            </div>
          );
      })}
    </Scrollbar>
  );
}

export default ProductPanel;
