import React from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
const InputText = ({ classes = {}, name, error, errorMessage, ...props }) => {
  return (
    <div className={`mb-16 w-100 ${classes.root}`}>
      <TextField
        className={`mb-16 w-100`}
        variant="outlined"
        error={error}
        helperText={errorMessage}
        name={name}
        {...props}
      />
    </div>
  );
};

export default InputText;