//import React, { useEffect, useRef } from "react";
import React, { useEffect, useState, useCallback, useMemo } from "react";
//import { useDispatch, useSelector } from "react-redux";
import ProductPanel from "./ProductPanel";
import { Appear } from "app/modules/meeting/components/transitions.jsx";
import classnames from "classnames";
import ViewGrid from "app/modules/meeting/components/ViewGrid.jsx";
import Contacts from "app/modules/meeting/components/Contacts.jsx";
import AdminPanel from "app/modules/meeting/components/AdminPanel.jsx";
import Presentation from "app/modules/meeting/components/Presentation.jsx";
import _ from "lodash";
import MenuTopSupport from "./MenuTopSupport";
import Scrollbar from "react-perfect-scrollbar";
import useRoomStatus from "../../../hooks/useRoomStatus";
import useApi from "../../../hooks/useApi";
import { useContext } from "react";
import RoomContext from "app/modules/meeting/RoomContext";
import { isMobile } from "react-device-detect";

const RoomHubeetSupportContent = ({ enableAsking }) => {
  const { extraData, setAsking } = useApi();
  const {
    room,
    peers,
    participantsState: participants,
    interpretersState,
    speakersState,
    me,
    enabledCam,
  } = useRoomStatus();
  const roomClient = useContext(RoomContext);

  const interpreters = interpretersState;
  const demo = false;
  const showPresentation = _.get(room, "presentation.video");

  const showAdminPanel = room.show_adminPanel;
  const showContact = room.show_contacts;
  const showProduct = room.show_productInfo;
  const showChat = room.show_chat;
  const cantInterpreters = interpreters.length;
  const cantParticipants = participants.length;
  const [hideMe, setHideMe] = useState(false);
  const handleHideMe = useCallback(
    (hide) => {
      setHideMe(hide);
    },
    [setHideMe]
  );
  const speakers = useMemo(
    () => (!hideMe ? [...speakersState] : speakersState.filter((p) => !p.isMe)),
    [speakersState, hideMe]
  );
  const cantSpeakers = speakers.length;

  const lateralPeers = useMemo(() => {
    if (showPresentation) {
      return interpreters.concat(speakers).concat(participants);
    } else {
      return interpreters.concat(speakers);
    }
  }, [participants, interpreters, speakers, showPresentation]);

  const [isMove, setIsMove] = useState(false);
  let timeout;
  useEffect(() => {});
  const handleMove = (e) => {
    if (isMove) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setIsMove(false);
      }, 4000);
    } else {
      setIsMove(true);
    }
  };
  useEffect(() => {
    if (room.state === "connected") {
      if (enableAsking === true && me && !me.asking) {
        setAsking(me.id);
        roomClient.enableWebcam();
        roomClient.unmuteMic();
      }
    }
  }, [room.state]);

  return (
    <Appear duration={300}>
      <div
        data-component={
          isMobile ? "RoomHubeetSupportMobile" : "RoomHubeetSupport"
        }
        onMouseMove={handleMove}
      >
        <MenuTopSupport
          extraData={extraData}
          onToggleHideMe={handleHideMe}
          canShareScreen={!isMobile}
        />
        <div className="wrapper">
          <div
            className={classnames("container-super-grid2", {
              withLateral:
                cantInterpreters > 0 ||
                cantSpeakers > 0 ||
                (cantParticipants > 0 && showPresentation),
            })}
            /* style={{ gridTemplateColumns: "50% 50%" }} */
          >
            {showPresentation && (
              <div className={classnames("super-presentation")}>
                <Presentation />
              </div>
            )}

            {!showPresentation && (
              <div className={classnames("super-speaker")}>
                <ViewGrid
                  peers={participants}
                  allPeers={peers}
                  mode={isMobile ? "customer" : "support"}
                  demo={demo}
                  transformlabel={false}
                />
              </div>
            )}
            {(cantSpeakers > 0 ||
              cantInterpreters > 0 ||
              (cantParticipants > 0 && showPresentation)) && (
              <React.Fragment>
                <div className={"lateral"}>
                  <div className="divider-zones"></div>
                  <Scrollbar className="content">
                    <ViewGrid
                      peers={lateralPeers}
                      allPeers={peers}
                      mode={isMobile ? "customer" : "support"}
                      demo={demo}
                      transformlabel={false}
                      classes={"hiddenScrollX"}
                    />
                  </Scrollbar>
                </div>
              </React.Fragment>
            )}
          </div>
          <div
            className={classnames("panel", {
              hidden:
                !showContact && !showChat && !showAdminPanel && !showProduct,
            })}
          >
            {showContact && <Contacts open={true} />}
            {_.get(extraData, "elements", false) && showProduct && (
              <ProductPanel extraData={extraData} open={true}></ProductPanel>
            )}
            <div className={!showAdminPanel ? "hidden" : ""}>
              <AdminPanel open={true} />
            </div>
          </div>
        </div>
      </div>
    </Appear>
  );
};

export default RoomHubeetSupportContent;
