import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { connect } from "react-redux";
import IconComponent from "../../../modules/components/generic/Icon";
import constants from "../../../constants/index.js";
import translate from "../../../lang/translate";
import helper from "../../../utils/helpers";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import * as stateActions from "../../../redux/stateActions";
import * as appPropTypes from "./appPropTypes";
import PeerView from "./PeerView";
import _ from "lodash";
const Peer = ({
  roomClient,
  peer,
  audioConsumer,
  audioShareConsumer,
  videoConsumer,
  audioMuted,
  faceDetection,
  onSetStatsPeerId,
  hideVideo,
  enabledButtons = true,
}) => {
  const audioEnabled =
    Boolean(audioConsumer) &&
    !audioConsumer.locallyPaused &&
    !audioConsumer.remotelyPaused;
  const audioShareEnabled =
    Boolean(audioShareConsumer) &&
    !audioShareConsumer.locallyPaused &&
    !audioShareConsumer.remotelyPaused;
  const videoVisible =
    Boolean(videoConsumer) &&
    !videoConsumer.locallyPaused &&
    !videoConsumer.remotelyPaused;
  const handle = useFullScreenHandle();

  const [rotateStatus, setRotateStatus] = useState(0);

  const handleRotate = (status) => {
    if (status === 3) {
      setRotateStatus(0);
    } else {
      setRotateStatus(rotateStatus + 1);
    }
  };

  return (
    <div
      data-component="Peer"
      className={classnames({
        speeker: constants.PEERS_TYPES.SPEEKER === peer.peerType,
        invited: constants.PEERS_TYPES.INVITED === peer.peerType,
        admin: constants.PEERS_TYPES.ADMIN === peer.peerType,
        interpreter: constants.PEERS_TYPES.INTERPRETER === peer.peerType,
      })}
    >
      <FullScreen handle={handle}>
        <div className="top-area">
          <div className="status-area">
            {peer.handUp && (
              <div className="space-indicator">
                <Tooltip
                  title={helper.formatText(
                    translate("HAS_RAISED_HIS_HAND"),
                    true
                  )}
                  placement="right"
                >
                  <div className="indicator">
                    <IconComponent
                      custom={true}
                      children="handup"
                      size={18}
                      className="icon-pos parpadeo"
                      color="#4285F4"
                    ></IconComponent>
                  </div>
                </Tooltip>
              </div>
            )}
            {/* {(audioEnabled) && (
							<div className="space-indicator">
								<Tooltip title={translate("TEXT_MIC_ON")} placement="right">
									<div className="indicator">
										<IconComponent
											custom={true}
											children="micmate"
											size={18}
											className="icon-pos"
										>
										</IconComponent>
									</div>
								</Tooltip>
							</div>
						)} */}
            {!audioEnabled && (
              <div className="space-indicator">
                <Tooltip title={translate("TEXT_MIC_OFF")} placement="right">
                  <div className="indicator">
                    <IconComponent
                      custom={true}
                      children="micmateoff"
                      size={18}
                      className="icon-pos"
                      color="#FF4F30"
                    ></IconComponent>
                  </div>
                </Tooltip>
              </div>
            )}
            {/* {(videoConsumer) && (
							<div className="space-indicator">
								<Tooltip title={translate("TEXT_CAM_ON")} placement="right">
									<div className="indicator">
										<IconComponent
											custom={true}
											children="videocamera"
											size={18}
											className="icon-pos"
										>
										</IconComponent>
									</div>
								</Tooltip>
							</div>
						)}
						{(!videoConsumer) && (
							<div className="space-indicator">
								<Tooltip title={translate("TEXT_CAM_OFF")} placement="right">
									<div className="indicator">
										<IconComponent
											custom={true}
											children="camoff"
											size={18}
											className="icon-pos"
											color="#FF4F30"
										>
										</IconComponent>
									</div>
								</Tooltip>
							</div>	
						)}
						{videoShareConsumer && (
							<div className="space-indicator">
								<Tooltip title={translate("TEXT_SHARE_SCREEN")} placement="right">
									<div className="indicator">
										<IconComponent
											custom={true}
											children="screenshare"
											size={18}
											className="icon-pos"
											color="#4285F4"
										>
										</IconComponent>
									</div>
								</Tooltip>
							</div>
						)} */}
          </div>
          {enabledButtons && (
            <div className="buttons-area">
              <div className="space-indicator2">
                <Tooltip
                  title={translate(
                    !handle.active ? "TEXT_MAXIMIZAR" : "TEXT_MINIMIZAR"
                  )}
                  placement="left"
                >
                  <Fab
                    size="small"
                    className="actions-icons"
                    onClick={!handle.active ? handle.enter : handle.exit}
                  >
                    <IconComponent
                      custom={true}
                      children={handle.active ? "normarscr" : "fullscr"}
                      size={16}
                    ></IconComponent>
                  </Fab>
                </Tooltip>
              </div>
              <div className="space-indicator2">
                <Tooltip title={translate("TEXT_ROTATE")} placement="left">
                  <Fab
                    size="small"
                    disabled={!videoConsumer}
                    className="actions-icons"
                    onClick={() => handleRotate(rotateStatus)}
                  >
                    <IconComponent
                      custom={true}
                      children="rotation"
                      size={16}
                    ></IconComponent>
                  </Fab>
                </Tooltip>
              </div>
              {/* <div className="space-indicator2">
							<Tooltip title={translate("TEXT_PIP")} placement="left">
								<Fab size="small" className="actions-icons">
									<IconComponent
										custom={true}
										children="pip"
										size={16}
									>
									</IconComponent>
								</Fab>
							</Tooltip>
						</div> */}
            </div>
          )}
        </div>
        <PeerView
          peer={peer}
          hideVideo={hideVideo}
          audioConsumerId={audioConsumer ? audioConsumer.id : null}
          audioShareConsumerId={
            audioShareConsumer ? audioShareConsumer.id : null
          }
          videoConsumerId={videoConsumer ? videoConsumer.id : null}
          audioRtpParameters={
            audioConsumer ? audioConsumer.rtpParameters : null
          }
          audioShareRtpParameters={
            audioShareConsumer ? audioShareConsumer.rtpParameters : null
          }
          videoRtpParameters={
            videoConsumer ? videoConsumer.rtpParameters : null
          }
          consumerSpatialLayers={
            videoConsumer ? videoConsumer.spatialLayers : null
          }
          consumerTemporalLayers={
            videoConsumer ? videoConsumer.temporalLayers : null
          }
          consumerCurrentSpatialLayer={
            videoConsumer ? videoConsumer.currentSpatialLayer : null
          }
          consumerCurrentTemporalLayer={
            videoConsumer ? videoConsumer.currentTemporalLayer : null
          }
          consumerPreferredSpatialLayer={
            videoConsumer ? videoConsumer.preferredSpatialLayer : null
          }
          consumerPreferredTemporalLayer={
            videoConsumer ? videoConsumer.preferredTemporalLayer : null
          }
          consumerPriority={videoConsumer ? videoConsumer.priority : null}
          audioTrack={audioConsumer ? audioConsumer.track : null}
          audioShareTrack={audioShareConsumer ? audioShareConsumer.track : null}
          videoTrack={videoConsumer ? videoConsumer.track : null}
          audioMuted={audioMuted}
          videoVisible={videoVisible}
          videoMultiLayer={videoConsumer && videoConsumer.type !== "simple"}
          audioCodec={audioConsumer ? audioConsumer.codec : null}
          audioShareCodec={audioShareConsumer ? audioShareConsumer.codec : null}
          videoCodec={videoConsumer ? videoConsumer.codec : null}
          audioScore={audioConsumer ? audioConsumer.score : null}
          audioShareScore={audioShareConsumer ? audioShareConsumer.score : null}
          videoScore={videoConsumer ? videoConsumer.score : null}
          faceDetection={faceDetection}
          onChangeVideoPreferredLayers={(spatialLayer, temporalLayer) => {
            roomClient.setConsumerPreferredLayers(
              videoConsumer.id,
              spatialLayer,
              temporalLayer
            );
          }}
          onChangeVideoPriority={(priority) => {
            roomClient.setConsumerPriority(videoConsumer.id, priority);
          }}
          onRequestKeyFrame={() => {
            roomClient.requestConsumerKeyFrame(videoConsumer.id);
          }}
          onStatsClick={onSetStatsPeerId}
          onRotate={rotateStatus}
        />
      </FullScreen>
    </div>
  );
};

Peer.propTypes = {
  roomClient: PropTypes.any.isRequired,
  peer: appPropTypes.Peer.isRequired,
  audioConsumer: appPropTypes.Consumer,
  audioShareConsumer: appPropTypes.Consumer,
  videoConsumer: appPropTypes.Consumer,
  audioMuted: PropTypes.bool,
  faceDetection: PropTypes.bool.isRequired,
  onSetStatsPeerId: PropTypes.func.isRequired,
};

const mapStateToProps = (store, { id }) => {
  const peer = store.peers[id];

  const consumersArray = _.get(peer, "consumers", []).map((consumerId) =>
    _.get(store, `consumers.${consumerId}`)
  );
  const audioConsumer = consumersArray.find(
    (consumer) => consumer.track.kind === "audio" && !consumer.share
  );
  const audioShareConsumer = consumersArray.find(
    (consumer) => consumer.track.kind === "audio" && consumer.share
  );
  const videoShareConsumer = consumersArray.find(
    (consumer) => consumer.track.kind === "video" && consumer.share
  );
  const videoCameraConsumer = consumersArray.find(
    (consumer) => consumer.track.kind === "video" && !consumer.share
  );
  const videoConsumer = videoShareConsumer
    ? videoShareConsumer
    : videoCameraConsumer;
  return {
    peer,
    audioConsumer,
    audioShareConsumer,
    videoConsumer,
    // audioMuted    : me.audioMuted,
    faceDetection: store.room.faceDetection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStatsPeerId: (peerId) =>
      dispatch(stateActions.setRoomStatsPeerId(peerId)),
  };
};

const PeerContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps)(Peer)
);

export default PeerContainer;
