import React from "react";
import { PARENT_EVENTS } from "app/constants/index.js";
import _ from "lodash";

const useEventHandler = ({ onDragResizeOn, onDragResizeOff }) => {
  const handler = (event) => {
    //TODO validar que venga event y sus propiedades

    if (_.get(event, "data.source", false) === "react-meet-viewer")
      switch (event.data.key) {
        case PARENT_EVENTS.DRAG_RESIZE_ON:
          if (_.isFunction(onDragResizeOn)) onDragResizeOn(event);
          break;
        case PARENT_EVENTS.DRAG_RESIZE_OFF:
          if (_.isFunction(onDragResizeOff)) onDragResizeOff(event);
          break;
        default:
          break;
      }
  };
  return handler;
};

export default useEventHandler;
