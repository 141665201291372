import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import _ from "lodash";

const ControllerInput = React.memo(
  ({ name, onChange, defaultValue, ...props }) => {
    const { control, errors, watch } = useFormContext();
    const nameWatch = watch(name);
    
    useEffect(() => {
      onChange && onChange(nameWatch);
    }, [nameWatch]);

    return (
      <React.Fragment>
        <Controller
          control={control}
          name={name}
          errorMessage={_.get(errors, `${name}.message`)}
          error={_.get(errors, `${name}.message`)}
          {...props}
        />
      </React.Fragment>
    );
  }
);

export default ControllerInput;