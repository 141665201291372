import CallEndIcon from "@material-ui/icons/CallEnd";
import React from "react";
import { useDispatch } from "react-redux";
import * as roomActions from "../../../../../../../redux/actions/roomActions";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";
import ModalCloseRoom from "./ModalCloseRoom";

const ButtonClose = ({ backgroundColor }) => {
  const dispatch = useDispatch();

  const handleCloseRoom = () => {
    dispatch(roomActions.openModal("closeRoom", {}));
  };

  return (
    <div data-component="ButtonCloseCustomer">
      <ButtonMenuTopCustomer
        tip="TOOLTIP_LOGOUT_ROOM"
        actionClick={handleCloseRoom}
        child={<CallEndIcon />}
        disabled={false}
        buttonColor="var(--secondary)"
      ></ButtonMenuTopCustomer>
      <ModalCloseRoom />
    </div>
  );
};
export default ButtonClose;
