import { Grid } from "@material-ui/core";
import history from "history.js";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import InputText from "../../../modules/components/forms/InputText";
import useService from "../../../modules/api/hooks/useService";
import translate from "../../../lang/translate";
import services from "../../../services";
import SubmitButton from "./../../../modules/components/buttons/SubmitButton";
import Form from "../../forms/Form";
import ControllerInput from "../../forms/ControllerInput";
import Link from "./../../../modules/components/links/Link";
import { getUriPage } from "./../../../views/RootPages";
import { login as loginAction } from "./../redux/actions";

const schema = yup.object().shape({
  userName: yup.string().required(translate("TEXT_REQUIRED_FIELD")),
  password: yup.string().required(translate("TEXT_REQUIRED_FIELD")),
});
let LoginForm = (props) => {
  const dispatch = useDispatch();
  const afterLogin = (resp) => {
    const token = resp.token;
    const user = resp.user;
    dispatch(loginAction(token, user));
  };
  const { send: login } = useService(services.module.session.login, {
    callback: afterLogin,
  });
  const onSubmit = (data) => {
    login(data);
  };
  const goToSignUp = () => {
    history.push({
      pathname: getUriPage("session.signup"),
    });
  };
  const goToForgot = () => {
    history.push({
      pathname: getUriPage("session.forgot-password"),
    });
  };
  return (
    <Form initialValues={{}} schema={schema} onSubmit={onSubmit}>
      <ControllerInput
        as={InputText}
        name="userName"
        placeholder={translate("TEXT_USERNAME")}
        label={translate("TEXT_USERNAME")}
      />
      <ControllerInput
        as={InputText}
        name="password"
        type="password"
        placeholder={translate("TEXT_PASSWORD")}
        label={translate("TEXT_PASSWORD")}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={"mb-20"}
      >
        <Link onClick={goToForgot}>
          {translate("TEXT_INIT_FORGOT_PASSWORD")}
        </Link>
        <span className={"mr-20 ml-20"}>| </span>
        <Link onClick={goToSignUp}>{translate("TEXT_INIT_SIGN_UP")}</Link>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <SubmitButton>{translate("TEXT_INIT_SESSION_ENTER")}</SubmitButton>
      </Grid>
    </Form>
  );
};
export default LoginForm;
