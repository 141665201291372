import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from "react-router";
import { getUriPage, hasAccessByPath, getDefaultPageByUser } from "./../../../views/RootPages"
import _ from "lodash"
import configParams from "./../../../../app/utils/config"

let AuthGuard = (props) => {
  const session = useSelector((store) => store.session);
  const access = useSelector((store) => {
    
    const aux = _.concat(configParams.publicPages, store.session.access);
    return aux;
  });
  const me = useSelector((store) => store.me);
  const location = useLocation();
  const history = useHistory();;
  const isRoom = () => {
    const pathname = _.get(location, "pathname", "");
    return (pathname.indexOf("/room/") >= 0);
  }
  const getNeedLogger = () => {
    const pathname = _.get(location, "pathname", "");
    return (pathname.indexOf("/admin/") >= 0);
  }
  const is404 = () => {
    const pathname = _.get(location, "pathname", "");
    return (pathname.indexOf("/404") >= 0);
  }
  const isDevPageWithAccess = () => {
    if (process.env.REACT_APP_NODE_ENV === "dev") {
      const pathname = _.get(location, "pathname", "");
      return (pathname.indexOf("/dev/") >= 0);
    }
    return false;
  }
  const needChangePass = () => {
    return _.get(session, "user.shouldChangePassword", false);
  }
  const isPageToChangePass = () => {
    const pathname = _.get(location, "pathname", "");
    return (pathname.indexOf("/change-password") >= 0);
  }

  const hasAccess = () => {
    if (is404()) {
      return true;
    }
    if (isDevPageWithAccess()) {
      return true;
    }
    if (isRoom()) {
      return true
    }
    let pathname = _.get(location, "pathname", "");
    if (pathname.includes("edit")) pathname = pathname.substr(0, pathname.lastIndexOf("/")) // dejo la key al final del edit para comparar los accesos

    let needLogger = getNeedLogger();
    const isLoggedIn = !!session.token;

    /* Solo si es  una sala de chat lo deja pasar sin validar*/
    if (needLogger && !isLoggedIn) {
      return false;
    } else if (needLogger && isLoggedIn) {
      const isNeedChangePass = needChangePass()
      if (isNeedChangePass) {
        return isPageToChangePass();
      } else {
        return hasAccessByPath(pathname, access);
      }

    } else if (!needLogger && isLoggedIn) {
      return false;
    } else if (!needLogger && !isLoggedIn) {
      return hasAccessByPath(pathname, access)
    }
    /* Si tengo acceso a la pagina evaluo si tengo que cambiar el pass */

  }
  const getRedirectTo = () => {
    let needLogger = getNeedLogger();
    const isLoggedIn = !!session.token;
    if (needLogger && !isLoggedIn) {
      return getUriPage("session.signIn")
    } else if (needLogger && isLoggedIn) {
      const isNeedChangePass = needChangePass()
      if (isNeedChangePass) {
        return getUriPage("session.change-password")
      } else {
        return getUriPage("session.404")
      }
    } else if (!needLogger && isLoggedIn) {
      return getDefaultPageByUser(me, access)
    } else if (!needLogger && !isLoggedIn) {
      return getUriPage("session.404")
    }

  }
  const redirectPage = () => {
    console.error("No tienes permisos.")
    history.push(getRedirectTo());
  }
  
  return (<>
    {session.client && <React.Fragment>
      {hasAccess(props) ? (
        <React.Fragment>
          {props.children}
        </React.Fragment>
      ) : (
          <React.Fragment>
            {redirectPage()}
          </React.Fragment>
      )}
    </React.Fragment>}
  </>

  )
}
export default (AuthGuard)