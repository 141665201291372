import MeetingRoomPages from "./meetingPages"
import RoomIntro from "./RoomIntro"
const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};


const meetingRoomsRoute = [
  
  {
    path: MeetingRoomPages.MEETINGROOM_ROOM,
    component: RoomIntro,
    settings
  }
];

export default meetingRoomsRoute;
