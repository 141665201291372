import { useEffect, useMemo, useState } from "react";
import StandardDefaultRoomIntro from "./StandardDefaultRoomIntro";
import InterpreterRoomIntro from "./InterpreterRoomIntro";
import { PEERS_TYPES, ROOM_VIEWS } from "../../../../../app/constants/index.js";
import CustomerRoomIntro from "./CustomerRoomIntro";
import OnlineSupportIntro from "./OnlineSupportIntro";

const useRoomIntro = ({ view }) => {
  const ComponentRoomIntro = useMemo(() => {
    // return OnlineSupportIntro;
    console.log(view)
    switch (view) {
      case ROOM_VIEWS.INTRO_ONLINE_SUPPORT:
        return OnlineSupportIntro;
      case ROOM_VIEWS.CUSTOMER_INTRO:
        return CustomerRoomIntro;
      case PEERS_TYPES.INTERPRETER:
        return InterpreterRoomIntro;
      default:
        return StandardDefaultRoomIntro;
    }
  }, [view]);

  return {
    ComponentRoomIntro,
  };
};
export default useRoomIntro;
