import React, { useState, useRef } from "react";
import translate from "../../../../lang/translate";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import _ from "lodash";
import ReactPlayer from "react-player";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from "react-redux";
import { sendVideo } from "../../../../redux/stateActions";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Fab from '@material-ui/core/Fab';
import CastIcon from '@material-ui/icons/Cast';
import PauseIcon from '@material-ui/icons/Pause';
import CastConnectedIcon from '@material-ui/icons/CastConnected';
import helper from "../../../../utils/helpers";
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import ImageFadeIn from "react-image-fade-in";

export default function Transmit({ ...props }) {
  const [step, setstep] = useState(1);
  const [videoUrl, setVideoUrl] = useState("");
  const refVideo = useRef();
  const dispatch = useDispatch();
  const [enabledVideo, setEnabledVideo] = useState(false);
  const [showControls, setshowControls] = useState(false);
  const [showError, setshowError] = useState(false);
  const [secondsPlay, setsecondsPlay] = useState("00:00");
  const [secondsTotal, setsecondsTotal] = useState(translate("TEXT_DOTS"));
  const [activeTootip, setactiveTootip] = useState("");
  const [btnPlayController, setbtnPlayController] = useState(false);
  const [video, setVideo] = useState({
    url: "",
    //url: "https://www.youtube.com/watch?v=DS_0xlwsnK4",
    playing: false,
    controls: false,
    muted: true,
    loop: false,
    pip: false,
    light: false,
    volume: 0.8,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
  });

  const handleChangeUrlVideo = (event) => {
    setVideoUrl(event.target.value);
	};

  const handleLoadVideo = () => {
    if (videoUrl.length > 5) {
      let config = { ...video };
      config.url = videoUrl;
      setVideo(config);
      setstep(2);
    }
  };

  const handleReady = () => {
    setshowControls(true);
  };
  
  const handleBackToLoad = () => {
    let config = { ...video };
    config.url = "";
    setVideo(config);
    setshowControls(false);
    setsecondsPlay("00:00");
    setshowError(false);
    setstep(1);
  };

  const handleProgress = (state) => {
    const { played, playedSeconds, loaded, loadedSeconds } = state;
    const { url, playing } = video;
    let config = { ...video, ...state };
    setVideo(config);
    setsecondsPlay(helper.secondsToHms(playedSeconds));
    if (enabledVideo) {
      dispatch(
        sendVideo({
          played,
          playedSeconds,
          loaded,
          loadedSeconds,
          url,
          playing,
        })
      );
    }
  };

  const handlePlay = (state) => {
    let config = { ...video };
    config.playing = true;
    setVideo(config);
    setbtnPlayController(true);
    if (enabledVideo) {
      dispatch(sendVideo(config));
    }
  };

  const handlePause = (state) => {
    let config = { ...video };
    config.playing = false;
    setVideo(config);
    setbtnPlayController(false);
    if (enabledVideo) {
      dispatch(sendVideo( config));
    }
	};
	
  const handleStart = (state) => {
    setEnabledVideo(true);
    const { url } = video;
    dispatch(sendVideo({ played: 0, playedSeconds: 0, url, playing: false }));
	};
	
  const handleEnded = (state) => {
    setEnabledVideo(false);
    dispatch(sendVideo(null));
  };

  const handleSeekMouseDown = (e) => {
    let config = { ...video };
    config.seeking = true;
    setVideo(config);
  };

  const handleSeekChange = (e) => {
    setactiveTootip(helper.secondsToHms(secondsTotal * e.target.value));
    let config = { ...video };
    config.played = parseFloat(e.target.value);
    setVideo(config);
  };

  const handleSeekMouseUp = (e) => {
    setactiveTootip("");
    let config = { ...video };
    config.seeking = false;
    setVideo(config);
    refVideo.current.seekTo(parseFloat(e.target.value));
  };

  const handleDuration = (duration) => {
    setsecondsTotal(duration)
  }

  const handleErrorToLoad = (e) => {
    console.log("Error", e);
    setshowError(true);
  };

  const handleFocus = (event) => event.target.select();

  return (
    <div data-component="Transmit">
      {step===1 ? (
        <div>
          <TextField
            variant="outlined"
            label={translate("TEXT_TRANSMIT_VIDEO_URL_PLACEHOLDER")}
            fullWidth={true}
            onChange={handleChangeUrlVideo}
            value={videoUrl}
            onFocus={handleFocus}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleLoadVideo();
                ev.preventDefault();
              }
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            fullWidth={true}
            className="btn-space-top"
            onClick={() => handleLoadVideo()}
            disableElevation
          >
            {translate("TEXT_VIDEO_LOAD")}
          </Button>
        </div>
      ) : null}
      {step===2 ? (
        <div>
          {showControls || showError ? (
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              className="btn-space-bottom"
              startIcon={<ArrowBackIcon />}
              onClick={() => handleBackToLoad()}
            >
              {translate("TEXT_VIDEO_CHANGE")}
            </Button>
          ) : (
            <LinearProgress />
          )}
          {showError ? (
            <div>
              <Box display="flex" justifyContent="center">
                <ImageFadeIn
                  src="/assets/images/illustrations/noVideo.svg"
                  alt=""
                  height="60px"
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <h5 className="mt-10 font-weight-500 text-muted">{translate("TEXT_ERROR_URL_VIDEO")}</h5>
              </Box>
            </div>
          ):null}
          <div className="video-container">
            <div className="transmit-mask"></div>
            <ReactPlayer
              url={_.get(video, "url")}
              ref={refVideo}
              className="react-player"
              width="100%"
              height={showControls ? "100%" : "0px"}
              pip={_.get(video, "pip")}
              playing={_.get(video, "playing")}
              controls={_.get(video, "controls")}
              light={_.get(video, "light")}
              loop={_.get(video, "loop")}
              playbackRate={_.get(video, "playbackRate")}
              volume={_.get(video, "volume")}
              muted={_.get(video, "muted")}
              //onError={(e) => console.log("onError", e)}
              onError={handleErrorToLoad}
              onProgress={handleProgress}
              onReady={()=> handleReady()}
              onDuration={handleDuration}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding:1,
                    nologo:1
                  }
                },
              }}
            />
          </div>
          {showControls ? (
            <div>
              <Tooltip title={activeTootip} placement="top">
                <input
                  type="range"
                  min={0}
                  max={0.999999}
                  step="any"
                  value={parseFloat(_.get(video, "played", 0))}
                  onMouseDown={handleSeekMouseDown}
                  onChange={handleSeekChange}
                  onMouseUp={handleSeekMouseUp}
                  className="custom-range"
                />
              </Tooltip>
              <div className="btn-space-top btn-container">
                <span className="counter1">{secondsPlay}</span>
                <Fab 
                  size="medium"
                  color="primary"
                  onClick={btnPlayController ? handlePause : handlePlay}
                >
                  {btnPlayController ? (
                    <PauseIcon></PauseIcon>
                  ) : (
                    <PlayArrowIcon></PlayArrowIcon>
                  )}
                </Fab>
                <span className="counter2">{helper.secondsToHms(secondsTotal)}</span>
              </div>
              <Button
                variant={enabledVideo ? "contained" : "outlined"}
                color="primary"
                fullWidth={true}
                className="btn-space-top"
                startIcon={enabledVideo ? (
                    <CastConnectedIcon></CastConnectedIcon>
                  ) : (
                    <CastIcon></CastIcon>
                  )}
                onClick={enabledVideo ? handleEnded : handleStart}
              >
                {enabledVideo ? (
                  translate("TEXT_TRANSMIT_DISABLE_TOOLTIP")
                ) : (
                  translate("TEXT_TRANSMIT_ENABLE_TOOLTIP")
                )}
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}