import React from "react";
import { useSelector } from "react-redux";
import { withRoomContext } from "../../../../../modules/meeting/RoomContext";
import constants from "../../../../../constants/index.js";
import { Fab } from "@material-ui/core";
import Tooltip from "../../../../../modules/components/help/Tooltip";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

const ButtonMicrophone = ({ roomClient, browserMobile=false }) => {
  const me = useSelector((store) => store.me);

  const audioProducer = useSelector((store) => {
    const producersArray = Object.values(store.producers);
    return producersArray.find((producer) => producer.track.kind === "audio");
  });

  const disabledMic = () => {
    const aux = !(me.peerType === constants.PEERS_TYPES.SPEEKER || me.peerType === constants.PEERS_TYPES.INTERPRETER|| me.asking);
    return aux;
  };

  return (
    <div data-component="ButtonMicrophone">
      <div className={browserMobile ? "movile-pos" : "desktop-pos"}>
        <Tooltip
          title={"TOOLTIP_ENABLED_DISABLED_MICROPHONE"}
          disableFocusListener={browserMobile}
          disableHoverListener={browserMobile}
          disableTouchListener={browserMobile}
        >
          <div> 
            <Fab
              disabled={disabledMic()}
              size="medium"
              onClick={() => {
                if (!me.canSendMic) roomClient.unmuteMic();
                else if (!audioProducer) roomClient.unmuteMic();
                else if (!audioProducer.paused) roomClient.muteMic();
                else roomClient.unmuteMic();
              }}
            >
              {me.canSendMic && audioProducer && !audioProducer.paused ? (
                <MicIcon fontSize="large"></MicIcon>
              ) : null}
              {!(me.canSendMic && audioProducer && !audioProducer.paused) ? (
                <MicOffIcon fontSize="large"></MicOffIcon>
              ) : null}
            </Fab>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default withRoomContext(ButtonMicrophone);
