

export default {
  module:{
    monitor:{
      list: {
        uri:"/admin/module/monitor/list",
      }
    }
  }
};