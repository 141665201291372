import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from "lodash";
import { Switch } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const InputSwitch = ({ classes = {}, onChange, checkbox = false, label, name, ...props }) => {
    const handleChange = (e, value) => {
        onChange(value);
    }
    return (
        <div className={`mb-16 w-100 flex flex-middle`}>
            {!checkbox && (
                <React.Fragment>
                    {label}
                    <Switch
                        {...props}
                        checked={props.value}
                        name={name}
                        onChange={handleChange}
                    />
                </React.Fragment>
            )}
            {checkbox && (
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            {...props}
                            checked={props.value}
                            name={name}
                            onChange={handleChange}
                        />
                    }
                    label={label}
                />

            )}
        </div>
    );
};


export default InputSwitch;
