export const login = (token, user) => {
    let serializedData = JSON.stringify(user)
    localStorage.setItem("user", serializedData)
    localStorage.setItem("token", token)
    return (dispatch, getState) => { // redux thunk
        dispatch({ type: "LOGIN", payload: { token, user: user } })
    }
}
export const changePass = (token, user) => {
    return (dispatch, getState) => { // redux thunk
      dispatch({ type: "CHANGE_PASS", payload: { token, user: user } })
    }
  }
