import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
// import Me from './Me';
// import ChatInput from './ChatInput';
import constants from "../../../constants/index.js";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import * as requestActions from "../../../redux/requestActions";
//import classnames from 'classnames';
import * as appPropTypes from "./appPropTypes";
import RoomDesktop from "./Room/roomTypes/event/desktop/RoomDesktop";
import DisabledRoomModal from "./DisabledRoomModal";
import ModalCloseRoom from "./ModalCloseRoom";
import ModalEnabledPeer from "./ModalEnabledPeer";
import ModalForUserCloseRoom from "./ModalForUserCloseRoom";
import ModalForUserEject from "./ModalForUserEject";
import ModalForUserMultipleDevice from "./ModalForUserMultipleDevice";
import ModalForUserNotInvited from "./ModalForUserNotInvited";
// import Invite from './Invite';
// import Speeker from './Speeker';
//import Menu from './Menu';
import Notifications from "./Notifications";
import { Appear } from "./transitions";

class Room extends React.Component {
  render() {
    const { room, me, mode } = this.props;
    return (
      <Appear duration={300}>
        <div data-component="Room">
          <Notifications />
          <RoomDesktop />
          <DisabledRoomModal></DisabledRoomModal>
          <ModalEnabledPeer />
          <ModalCloseRoom />
          <ModalForUserCloseRoom />
          <ModalForUserEject />
          <ModalForUserMultipleDevice />
          <ModalForUserNotInvited />
        </div>
      </Appear>
    );
  }

  componentDidMount() {
    const { roomClient } = this.props;
    roomClient.join();
  }
}

Room.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: appPropTypes.Room.isRequired,
  me: appPropTypes.Me.isRequired,
  amActiveSpeaker: PropTypes.bool.isRequired,
  onRoomLinkCopy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const peers = Object.values(state.peers);
  const inviteds = peers.filter(
    (peer) => peer.peerType === constants.PEERS_TYPES.INVITED && peer.asking
  );
  const interpreters = peers.filter(
    (peer) =>
      peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
      (peer.peerLang === state.me.peerLang ||
        inviteds.some((i) => i.peerLang === peer.peerLang))
  );
  // && (inviteds.some(i => i.peerLang === peer.peerLang)||speakers.some(s=>s.peerLang === peer.peerLang))
  const askingPeers = peers.filter(
    (peer) =>
      (peer.peerType === constants.PEERS_TYPES.INTERPRETER &&
        inviteds.some((i) => i.peerLang === peer.peerLang)) ||
      peer.peerType === constants.PEERS_TYPES.SPEEKER
  );

  return {
    inviteds,
    interpreters,
    askingPeers,
    room: state.room,
    me: state.me,
    client: state.session.client,
    amActiveSpeaker: state.me.id === state.room.activeSpeakerId,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onRoomLinkCopy: () =>
    dispatch(
      requestActions.notify({ text: translate("LINK_COPIED_TO_CLIPBOARD") })
    ),
});

const RoomContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps)(Room)
);

export default RoomContainer;
