import classnames from "classnames";
import _ from "lodash";
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
//import ButtonFloatLang from "../../../modules/components/room/buttons/customs/ButtonFloatLang";
import { useDispatch } from "react-redux";
//import MenuTop from './../MenuTop';
import RoomContext from "../../../../RoomContext";
import { PEERS_TYPES } from "../../../../../../constants";
import Presentation from "../../../Presentation";
import { Appear } from "../../../transitions";
import ViewGrid from "../../../ViewGrid";
//import ViewGrid from "./components/ViewGrid";
import MenuTopCustomer from "./components/MenuTopCustomer";
import useRoomStatus from "../../hooks/useRoomStatus";
import useApi from "../../hooks/useApi";
import { changeLang } from "app/redux/stateActions";
import useNotification from "../../hooks/useNotification";
import ScrollBar from "react-perfect-scrollbar";
import { isMobile } from "react-device-detect";
const RoomCustomer = ({ enableAsking }) => {
  const {
    room,
    peers,
    participantsState,
    interpretersState,
    speakersState,
    me,
    enabledCam,
    videoProducerShare,
    enabledLangs,
  } = useRoomStatus();
  const roomClient = useContext(RoomContext);
  const { setAsking } = useApi();
  const [hideMe, setHideMe] = useState(false);

  const participants = useMemo(
    () =>
      !hideMe
        ? [...participantsState]
        : participantsState.filter((p) => !p.isMe),
    [participantsState, hideMe]
  );

  const interpreters = interpretersState;
  const speakers = speakersState;
  const demo = false;
  const showPresentation = _.get(room, "presentation.video");
  const showAdminPanel = room.show_adminPanel;
  const showContact = room.show_contacts;
  const showChat = room.show_chat;
  const cantSpeakers = speakers.length;
  const cantInterpreters = interpreters.length;
  const cantParticipants = participants.length;

  const { sendChangeLangNotification } = useNotification();
  const [isMove, setIsMove] = useState(true);
  const interpreter = useMemo(
    () => peers.find((p) => p.peerType === PEERS_TYPES.INTERPRETER),
    [peers.length]
  );
  useEffect(() => {
    setTimeout(function () {
      setIsMove(false);
    }, 4000);
    roomClient.join();
    try {
      // document.body.requestFullscreen();
    } catch (e) {
      console.log("No soporta pantalla completa");
    }
  }, []);

  const handleMove = () => {
    if (!isMove) {
      setIsMove(true);
    }
    setTimeout(function () {
      setIsMove(false);
    }, 4000);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (interpreter) {
      if (
        _.get(interpreter, "peerLang") &&
        me.peerLang !== interpreter.peerLang
      ) {
        dispatch(changeLang(interpreter.peerLang));
      }
    } else {
      if (
        _.get(room, "info.officialLang") &&
        me.peerLang !== room.info.officialLang
      ) {
        dispatch(changeLang(room.info.officialLang));
      }
    }
  }, [JSON.stringify(enabledLangs)]);
  useEffect(() => {
    if (room.state === "connected") {
      setAsking(me.id);
      if (enableAsking === true && me && !me.asking) {
        roomClient.enableWebcam();
        roomClient.unmuteMic();
      }
    }
  }, [room.state]);
  useEffect(() => {
    if (interpreter && me.peerLang) sendChangeLangNotification();
  }, [me.peerLang]);

  const handleHideMe = useCallback(
    (hide) => {
      setHideMe(hide);
    },
    [setHideMe]
  );
  const lateralPeers = useMemo(() => {
    if (showPresentation) {
      return interpreters.concat(speakers).concat(participants);
    } else {
      return interpreters.concat(participants);
    }
  }, [participants, interpreters, speakers, showPresentation]);
  return (
    <div id="app-room-hubeet-customer-container">
      {/* <ModalEnabledPeer /> */}
      <Appear duration={300}>
        <div data-component="RoomHubeetCustomer" onTouchStart={handleMove}>
          <MenuTopCustomer
            onToggleHideMe={handleHideMe}
            canShareScreen={!isMobile}
          />

          <div className="wrapper">
            <div
              className={classnames("container-super-grid2", {
                withLateral:
                  cantInterpreters > 0 ||
                  cantParticipants > 0 ||
                  (cantSpeakers > 0 && showPresentation),
                hidden: showContact || showChat || showAdminPanel,
              })}
            >
              {showPresentation && (
                <div className={classnames("super-presentation")}>
                  <Presentation />
                </div>
              )}
              {!showPresentation && cantSpeakers > 0 && (
                <div className={classnames("super-speaker")}>
                  <ViewGrid
                    peers={speakers}
                    allPeers={peers}
                    mode={"customer"}
                    demo={demo}
                    transformlabel={false}
                  />
                </div>
              )}
              {(cantInterpreters > 0 ||
                cantParticipants > 0 ||
                (cantSpeakers > 0 && showPresentation)) && (
                <React.Fragment>
                  <div className={"lateral"}>
                    <div className="divider-zones"></div>
                    <ScrollBar className="content">
                      <ViewGrid
                        peers={lateralPeers}
                        allPeers={peers}
                        mode={"customer"}
                        demo={demo}
                        transformlabel={false}
                        classes={"hiddenScrollX"}
                      />
                    </ScrollBar>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </Appear>
    </div>
  );
};

export default RoomCustomer;
