import { IconButton, Paper } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import React, { useState } from "react";
import Scrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconComponent from "../../../modules/components/generic/Icon";
import constants from "../../../constants/index.js";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import { setAsking } from "../../../redux/stateActions";
import RowContact from "./RowContact";

const useStyles = makeStyles({
  hideBorder: {
    "&.MuiExpansionPanel-root:before": {
      display: "none",
    },
    "&.MuiExpansionPanel-rounded": {
      "border-radius": "8px",
    },
    "&.MuiPaper-root": {
      "margin-bottom": "8px",
    },
  },
  bestDetailsPan: {
    "&.MuiExpansionPanelDetails-root": {
      "padding-left": "8px !important",
      "padding-right": "8px !important",
      "padding-top": "0px !important",
    },
  },
});

const Contacts = ({
  setAsking,
  me,
  meApi,
  open,
  speakers,
  interpreters,
  participants_asking,
  participants_noasking,
}) => {
  //const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const classes = useStyles();

  // const toogleContactsPanel = () => {
  // 	dispatch(toogleContacts())
  // }

  const getFilterData = (collection) => {
    if (search && search != "") {
      return _.filter(collection, (e) => {
        return e.displayName.toUpperCase().indexOf(search.toUpperCase()) >= 0;
      });
    } else {
      return collection;
    }
  };
  const handleChangeSearch = (event) => {
    const value = event.target.value;

    setSearch(value);
  };

  return (
    <div data-component="Contacts">
      <div className="contentTitle">{translate("TEXT_PARTICIPANT_PANEL")}</div>
      <div className="search-content">
        <Paper className="contentSearch">
          <InputBase
            className={"input"}
            placeholder={translate("TEXT_SEARCH")}
            onChange={handleChangeSearch}
          />
          <IconButton className={"iconButton"} aria-label="Search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <Scrollbar className="div-scroll">
        {speakers && speakers.length > 0 && (
          <ExpansionPanel className={classes.hideBorder} defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className="expansion-desing"
            >
              <table cellPadding="0" cellSpacing="0" className="w-100">
                <tbody>
                  <tr>
                    <td className="w-30px text-left">
                      <IconComponent
                        custom={true}
                        children="orador"
                        size={22}
                        color="#10c304"
                      ></IconComponent>
                    </td>
                    <td>
                      <h5 className="m-0 marg-6px">
                        {translate("ROLES_SPEEKER")}
                        {speakers && speakers.length > 1
                          ? translate("TEXT_ADD_PLURAL1")
                          : ""}
                      </h5>
                    </td>
                    <td className="w-30px text-center">
                      <div className="tags">
                        <span className="resalt text-muted">
                          {speakers.length}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.bestDetailsPan}>
              <div className="extend-data">
                {getFilterData(speakers).map((peer, i) => {
                  return <RowContact peer={peer} />;
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {interpreters && interpreters.length > 0 && (
          <ExpansionPanel className={classes.hideBorder} defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className="expansion-desing"
            >
              <table cellPadding="0" cellSpacing="0" className="w-100">
                <tbody>
                  <tr>
                    <td className="w-30px text-left">
                      <IconComponent
                        custom={true}
                        children="traductor"
                        size={22}
                        color="#fdf623"
                      ></IconComponent>
                    </td>
                    <td>
                      <h5 className="m-0 marg-6px">
                        {translate("ROLES_INTERPRETER")}
                        {interpreters && interpreters.length > 1
                          ? translate("TEXT_ADD_PLURAL2")
                          : ""}
                      </h5>
                    </td>
                    <td className="w-30px text-center">
                      <div className="tags">
                        <span className="resalt text-muted">
                          {interpreters.length}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.bestDetailsPan}>
              <div className="extend-data">
                {getFilterData(interpreters).map((peer, i) => {
                  return <RowContact peer={peer} />;
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {participants_asking && participants_asking.length > 0 && (
          <ExpansionPanel className={classes.hideBorder} defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className="expansion-desing"
            >
              <table cellPadding="0" cellSpacing="0" className="w-100">
                <tbody>
                  <tr>
                    <td className="w-30px text-left">
                      <IconComponent
                        custom={true}
                        children="palabra2"
                        size={22}
                        color="#fd7e14"
                      ></IconComponent>
                    </td>
                    <td>
                      <h5 className="m-0 marg-6px">
                        {translate("TEXT_HAVE")}
                        {participants_asking && participants_asking.length > 1
                          ? translate("TEXT_ADD_PLURAL3") + " "
                          : " "}
                        {translate("TEXT_THE_WORD")}
                      </h5>
                    </td>
                    <td className="w-30px text-center">
                      <div className="tags">
                        <span className="resalt text-muted">
                          {participants_asking.length}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.bestDetailsPan}>
              <div className="extend-data">
                {getFilterData(participants_asking).map((peer, i) => {
                  return <RowContact peer={peer} />;
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {participants_noasking && participants_noasking.length > 0 && (
          <ExpansionPanel className={classes.hideBorder} defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className="expansion-desing"
            >
              <table cellPadding="0" cellSpacing="0" className="w-100">
                <tbody>
                  <tr>
                    <td className="w-30px text-left">
                      <IconComponent
                        custom={true}
                        children="listener"
                        size={22}
                        color="#3366FF"
                      ></IconComponent>
                    </td>
                    <td>
                      <h5 className="m-0 marg-6px">
                        {translate("TEXT_LISTENER")}
                        {participants_noasking &&
                        participants_noasking.length > 1
                          ? translate("TEXT_ADD_PLURAL2")
                          : ""}
                      </h5>
                    </td>
                    <td className="w-30px text-center">
                      <div className="tags">
                        <span className="resalt text-muted">
                          {participants_noasking.length}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.bestDetailsPan}>
              <div className="extend-data">
                {getFilterData(participants_noasking).map((peer, i) => {
                  return <RowContact peer={peer} />;
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </Scrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  //let peersArray = [];
  const peers = Object.values(state.peers);
  const me = state.me;
  const speakers = peers.filter(
    (p) => p.peerType === constants.PEERS_TYPES.SPEEKER
  );
  const interpreters = peers.filter(
    (p) => p.peerType === constants.PEERS_TYPES.INTERPRETER
  );
  const participants_asking = peers.filter(
    (p) => p.peerType === constants.PEERS_TYPES.INVITED && p.asking === true
  );
  const participants_noasking = peers.filter(
    (p) => p.peerType === constants.PEERS_TYPES.INVITED && p.asking !== true
  );
  if (me.peerType === constants.PEERS_TYPES.SPEEKER) {
    speakers.push({ ...me, isMe: true });
  } else if (me.peerType === constants.PEERS_TYPES.INTERPRETER) {
    interpreters.push({ ...me, isMe: true });
  } else if (
    me.peerType === constants.PEERS_TYPES.INVITED &&
    me.asking === true
  ) {
    participants_asking.push({ ...me, isMe: true });
  } else {
    participants_noasking.push({ ...me, isMe: true });
  }
  return {
    me: state.me,
    meApi: state.api.me,
    speakers,
    interpreters,
    participants_asking,
    participants_noasking,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setAsking }, dispatch);

const ContactsContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return prev == next;
    },
  })(Contacts)
);

export default ContactsContainer;
