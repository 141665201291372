import { Fab } from "@material-ui/core";
import CallEndIcon from "@material-ui/icons/CallEnd";
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import Tooltip from "../../../modules/components/help/Tooltip";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import { closeRoom } from "../../../redux/stateActions";
import * as roomActions from "./../../../redux/actions/roomActions";

const ButtonClose = ({ api, room, closeRoom, browserMobile = false }) => {
  const dispatch = useDispatch();

  const handleCloseRoom = () => {
    dispatch(roomActions.openModal("closeRoom", {}));
  };

  return (
    <div data-component="ButtonClose">
      <Tooltip
        title={"TOOLTIP_LOGOUT_ROOM"}
        disableFocusListener={browserMobile}
        disableHoverListener={browserMobile}
        disableTouchListener={browserMobile}
      >
        <Fab color="secondary" className="end-call" onClick={handleCloseRoom}>
          <CallEndIcon fontSize="large"></CallEndIcon>
        </Fab>
      </Tooltip>
    </div>
  );
};

ButtonClose.propTypes = {
  roomClient: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    room: state.room,
    me: state.me,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ closeRoom }, dispatch);

const MenuContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => prev === next,
  })(ButtonClose)
);

export default MenuContainer;
