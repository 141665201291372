import React, { useContext } from "react";
import RoomContext from "app/modules/meeting/RoomContext";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import useRoomStatus from "../../../hooks/useRoomStatus";

const ButtonShareScreen = () => {
  const roomClient = useContext(RoomContext);
  const { disableShare, room, videoProducerShare } = useRoomStatus();

  const handleDesktopShare = () => {
    if (videoProducerShare) {
      roomClient.disableShare();
    } else {
      roomClient.enableShare();
    }
  };
  return (
    <ButtonMenuTopCustomer
      child={<ScreenShareIcon />}
      tip="SHARE_MY_SCREEN_ON_OFF"
      actionClick={handleDesktopShare}
      toggle={room.show_shareScreen}
      disabled={disableShare()}
    ></ButtonMenuTopCustomer>
  );
};

export default ButtonShareScreen;
