export default {

    session: {
        login: {
            uri: "/api/users/login",
            method: "post",
            useToken: false,
            defaultErrorMessageKey: "LOGIN_ERROR"
        },
        forgot_pass: {
            uri: "/api/users/linkResetPassword?mail=:email",
            method: "get",
            useToken: false,
            successMessageKey: "NOTIFICATION_SUCCESS"
        },
        change_pass: {
            uri: "/api/users/changePassword",
            method: "put",
            useToken: true,
            successMessageKey: false
        },

    },

}
