import React from "react";
import { LoaderBounce } from "matx";
import translate from "app/lang/translate";
function WaitingLoader() {
  return (
    <div
      className="flex flex-center flex-middle text-center"
      style={{ width: "100%" }}
    >
      <LoaderBounce text={translate("TEXT_LOADER_CUSTOMER")}></LoaderBounce>
    </div>
  );
}

export default WaitingLoader;
