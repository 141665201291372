import Dialog from "@material-ui/core/Dialog";
import classnames from "classnames";
import React from "react";

const ModalOnlineSupport = ({
  open,
  background = true,
  children,
  title,
  srcImage = "",
  buttons,
  modalProps = {},
  onClose = () => {},
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} {...modalProps}>
      <div
        data-component="ModalOnlineSupport"
        className={classnames({
          background: background,
        })}
      >
        <h2 className="h3" style={{ marginBottom: "1em" }}>
          {title}
        </h2>
        {buttons && (
          <div className="mt-8 flex flex-center">
            {buttons.map((button, i) => (
              <div key={i} className={"ml-8"}>
                {button}
              </div>
            ))}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ModalOnlineSupport;
