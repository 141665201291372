import React from "react";
import IcoMoon from "react-icomoon";
import { Icon } from "@material-ui/core";
const iconSet = require("../../../../styles/icons/selection.json");

const IconComponent = ({ custom, children, size = "1.5em", ...props }) => {
  return (
    <React.Fragment>
      {custom ? (
        <IcoMoon
          iconSet={iconSet}
          size={size}
          color="#ffffff"
          icon={children}
          {...props}
        />
      ) : (
        <Icon {...props}>{children}</Icon>
      )}
    </React.Fragment>
  );
};

export default IconComponent;
