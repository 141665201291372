import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRoom as getRoomAction } from "app/redux/apiActions";
import * as stateActions from "app/redux/stateActions";

const useApi = () => {
  const dispatch = useDispatch();
  const getRoom = (...params) => dispatch(getRoomAction(...params));
  const setMe = (params) => dispatch(stateActions.setMe(params));
  const setRoomUrl = (url) => dispatch(stateActions.setRoomUrl(url));
  const setAsking = (peerId) => dispatch(stateActions.setAsking(peerId));
  const store = useSelector((store) => store);
  const meLogin = useSelector((store) => store.api.me);
  const token = useSelector((store) => store.api.token);
  const currentRoom = useSelector((store) => store.api.currentRoom);
  const rooms = useSelector((store) => store.api.rooms);
  let extraData = currentRoom && currentRoom.extra;
  if (!extraData || Object.keys(extraData).length === 0) extraData = false;

  return {
    getRoom,
    setMe,
    setRoomUrl,
    setAsking,
    store,
    meLogin,
    token,
    currentRoom,
    rooms,
    extraData,
  };
};

export default useApi;
