import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import classnames from "classnames";
import _ from "lodash";
import React, { useEffect, useRef, useState, useCallback } from "react";
import ApngComponent from "react-apng";
import { useSelector } from "react-redux";
import translate from "../../../../lang/translate";
import helper from "../../../../utils/helpers";
import Count from "./Count";
import ReactPlayer from "react-player";
import constants from "../../../../constants/index.js";
let rec;
let stream;
let audioAdded = [];
let context;
let destination;
const Record = ({ ...props }) => {
  const room = useSelector((store) => store.room.info);
  const consumers = useSelector((store) => store.consumers);
  const producers = useSelector((store) => store.producers);
  const [timeStart, setTimeStart] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const peers = useSelector((store) => Object.values(store.peers));
  const me = useSelector((store) => store.me);
  const addAudioToMerge = (audio) => {
    if (audio && !audioAdded[audio.id]) {
      const stream = new MediaStream();
      stream.addTrack(audio.track);
      const source1 = context.createMediaStreamSource(stream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
    }
  };
  const addConsumers = () => {
    _.forEach(peers, (peer, i) => {
      if (peer.peerType !== constants.PEERS_TYPES.INTERPRETER) {
        const consumersArray = peer.consumers.map(
          (consumerId) => consumers[consumerId]
        );
        const audioConsumers = consumersArray.filter(
          (consumer) => consumer.track.kind === "audio"
        );
        _.forEach(audioConsumers, (audioConsumer) => {
          addAudioToMerge(audioConsumer);
        });
      }
    });
  };
  const addProducers = () => {
    const audios = _.filter(
      producers,
      (producer) => producer.track.kind === "audio"
    );
    _.forEach(audios, (audio) => {
      addAudioToMerge(audio);
    });
  };
  useEffect(() => {
    if (isRecording) {
      addConsumers();
    }
  }, [consumers]);
  useEffect(() => {
    if (isRecording) {
      addProducers();
    }
  }, [producers]);
  const startRecord = () => {
    rec.start();
    setIsRecording(true);
    setTimeStart(Date.now());
  };

  const stopRecord = () => {
    rec.stop();
    stream.getTracks().forEach((s) => s.stop());
    stream = null;
    setIsRecording(false);
  };
  const mergeAudioStreams = (desktopStream, voiceStream) => {
    audioAdded = [];
    context = new AudioContext();
    destination = context.createMediaStreamDestination();

    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
    }
    addConsumers();
    addProducers();

    return destination ? destination.stream.getAudioTracks() : [];
  };
  const capture = async () => {
    let desktopStream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
    });
    let voiceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
    const tracks = [
      ...desktopStream.getVideoTracks(),

      ...mergeAudioStreams(desktopStream, voiceStream),
    ];
    stream = new MediaStream(tracks);
    let blobs = [];
    rec = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp8,opus",
    });
    rec.ondataavailable = (e) => blobs.push(e.data);
    rec.onstop = async () => {
      let blob = new Blob(blobs, { type: "video/webm" });
      let finalUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = finalUrl;
      link.setAttribute("download", `${_.get(room, "title", "video")}.webm`);
      document.body.appendChild(link);
      link.onclick = () => {
        setIsLoading(false);
      };
      link.click();
      link.parentNode.removeChild(link);
    };
  };

  const startDeviceRecord = async () => {
    await capture();
    startRecord();
  };

  const stopDeviceRecord = () => {
    setIsLoading(true);
    stopRecord();
  };

  return (
    <React.Fragment>
      <div data-component="Record">
        {isLoading ? (
          <div
            className="flex flex-column flex-center flex-middle text-center"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%", paddingBottom: "1em" }}>
              {translate("TEXT_PROCESSING_VIDEO")}
            </div>
            <LinearProgress
              style={{ width: "100%", height: "10px", borderRadius: "1em" }}
            />
          </div>
        ) : null}
        <div className={isLoading ? "is-hide" : ""}>
          <div className={isRecording ? "" : "is-hide"}>
            <div>
              <ReactPlayer
                url={stream}
                className="react-player"
                width="100%"
                height="100%"
                playing={true}
                controls={false}
                light={false}
                loop={false}
                muted={true}
              />
            </div>
            <div className="btn-space-top btn-container">
              <span className="counter2">REC</span>
              <ApngComponent
                autoPlay={true}
                src="/assets/animations/record.png"
                className="animate-size2"
              />
              <span className="counter1">
                <Count timeStart={timeStart} />
              </span>
            </div>
          </div>
          <div className={isRecording ? "btn-space-top" : ""}>
            <div
              className={classnames("record-warning", {
                "is-hide": isRecording,
              })}
            >
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="detail">&#9679;</td>
                    <td>{translate("RECORD_WARNING")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Button
              variant={isRecording ? "contained" : "outlined"}
              color="primary"
              fullWidth={true}
              startIcon={
                !isRecording ? (
                  <VideocamIcon></VideocamIcon>
                ) : (
                  <VideocamOffIcon></VideocamOffIcon>
                )
              }
              onClick={isRecording ? stopDeviceRecord : startDeviceRecord}
            >
              {isRecording
                ? translate("TEXT_STOP_RECORD")
                : translate("TEXT_START_RECORD")}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Record;
