import history from "history.js";
import React, { useRef } from "react";
import * as yup from "yup";
import Button from "../../../modules/components/buttons/Button";
import SubmitButton from "../../../modules/components/buttons/SubmitButton";
import Form from "../../forms/Form";
import ControllerInput from "../../forms/ControllerInput";
import InputSwitch from "../../../modules/components/forms/InputSwitch";
import InputText from "../../../modules/components/forms/InputText";
import Link from "../../../modules/components/links/Link";
import useService from "../../../modules/api/hooks/useService";
import translate from "../../../lang/translate";
import services from "../../../services";
import ModalTerms from "./../../../modules/components/modals/ModalTerms";
import { getUriPage } from "./../../../views/RootPages";


const schema = yup.object().shape({
  mail: yup.string().email(translate("NEWUSER_INVALID_EMAIL")).required(translate("REQUIRED_FIELD")),
  firstName: yup.string().required(translate("REQUIRED_FIELD")),
  lastName: yup.string().required(translate("REQUIRED_FIELD")),
  acceptTermsConditions: yup.bool().oneOf([true], translate("REQUIRED_FIELD"))
});

let RegisterForm = ({ initialValues, handleSubmit, handleClose }) => {
  const goToSingIn = ()=>{
    history.push({
      pathname: getUriPage("session.signIn"),
    });
  }
  const { send: createUser, loading: loadingCreateUser } = useService(
    services.CREATE_USER,
    {
      callback: goToSingIn,
    }
  );
  let modalTerms = useRef();
  const openModalTerms = ()=>{
    modalTerms.open();
  } 
  const onSubmit = (values) => {
    delete values["passwordValidation"];
    createUser(values);
  };

  return (
    <React.Fragment>
      <Form initialValues={initialValues} schema={schema} onSubmit={onSubmit}>
        <ControllerInput
          as={InputText}
          defaultValue=""
          name="mail"
          placeholder={translate("TEXT_USERNAME_MAIL_PLACEHOLDER")}
          label={translate("TEXT_USERNAME_MAIL")}
        />

        <ControllerInput
          as={InputText}
          defaultValue=""
          name="firstName"
          placeholder={translate("TEXT_FIRSTNAME_PLACEHOLDER")}
          label={translate("TEXT_FIRSTNAME")}
        />
        <ControllerInput
          as={InputText}
          defaultValue=""
          name="lastName"
          placeholder={translate("TEXT_LASTNAME_PLACEHOLDER")}
          label={translate("TEXT_LASTNAME")}
        />
        <ControllerInput
          as={InputSwitch}
          defaultValue={false}
          checkbox={true}
          name="acceptTermsConditions"
          placeholder={translate("TEXT_LASTNAME_PLACEHOLDER")}
          label={<span className={"terms"}>
            {translate("LINK_ACCEPT_TERMS")}
            <Link onClick={openModalTerms}>{translate("LINK_ACCEPT_TERMS_LINK")}</Link>
            </span>}
        />
        <div className={"flex flex-end flex-middle"}>
          <div className="mr-8">
            <Button variant="contained" type="button" onClick={goToSingIn}>
              {translate("BUTTON_BACK_LOGIN")}
            </Button>
          </div>
          <div>
          <SubmitButton>{translate("TEXT_CREATE_ACCOUNT")}</SubmitButton>
          </div>
        </div>
      </Form>
      <ModalTerms ref={node => modalTerms = node}  />
    </React.Fragment>
  );
};
export default RegisterForm;
