import history from "history.js";
import React from "react";
import * as yup from "yup";
import Button from "../../../modules/components/buttons/Button";
import InputText from "../../../modules/components/forms/InputText";
import useService from "../../../modules/api/hooks/useService";
import translate from "../../../lang/translate";
import services from "../../../services";
import ControllerInput from "../../forms/ControllerInput";
import SubmitButton from "./../../../modules/components/buttons/SubmitButton";
import Form from "../../forms/Form";
import { getUriPage } from "./../../../views/RootPages";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

let ResetForm = ({ initialValues, handleSubmit, handleClose }) => {
  const { send: forgotPassword, loading: loadingCreateUser } = useService(
    services.services.module.session.forgot_pass,
    {
      callback: handleSubmit,
    }
  );

  const onSubmit = (values) => {
    forgotPassword(null,values);
  };
  const goToSingIn = ()=>{
    history.push({
      pathname: getUriPage("session.signIn"),
    });
  }

  return (
    <React.Fragment>
      <Form initialValues={initialValues} schema={schema} onSubmit={onSubmit}>
        <ControllerInput
          as={InputText}
          defaultValue=""
          name="email"
          placeholder={translate("TEXT_USERNAME_MAIL_PLACEHOLDER")}
          label={translate("TEXT_USERNAME_MAIL")}
        />
        <div className={"flex flex-end flex-middle"}>
          <div className="mr-8 ">
            <Button variant="contained" type="button" onClick={goToSingIn}>
              {translate("BUTTON_BACK_LOGIN")}
            </Button>
          </div>
          <div>
          <SubmitButton>{translate("TEXT_CREATE_ACCOUNT")}</SubmitButton>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};
export default ResetForm;
