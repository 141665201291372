import React from "react";
import { useSelector } from "react-redux";
import { withRoomContext } from "../../../../../modules/meeting/RoomContext";
import constants from "../../../../../constants/index.js";
import { Fab } from "@material-ui/core";
import Tooltip from "../../../../../modules/components/help/Tooltip";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";

const ButtonCamera = ({ roomClient, browserMobile = false }) => {
  const me = useSelector((store) => store.me);

  const videoProducer = useSelector((store) => {
    const producersArray = Object.values(store.producers);
    return producersArray.find((producer) => producer.track.kind === "video");
  });

  const disabledCam = () => {
    const aux = !(me.peerType === constants.PEERS_TYPES.SPEEKER || me.peerType === constants.PEERS_TYPES.INTERPRETER|| me.asking);
    return aux;
  };

  return (
    <div data-component="ButtonCamera">
      <div className={browserMobile ? "movile-pos" : "desktop-pos"}>
        <Tooltip
          title={"TOOLTIP_SHOW_HIDE_ME"}
          disableFocusListener={browserMobile}
          disableHoverListener={browserMobile}
          disableTouchListener={browserMobile}
        >
          <div>
            <Fab
              disabled={disabledCam()}
              size="medium"
              onClick={() => {
                if (me.canSendWebcam && (videoProducer && videoProducer.type !== 'share')) {
                  roomClient.disableWebcam();
                }
                else {
                  roomClient.enableWebcam();
                }
              }}
            >
              {me.canSendWebcam &&
                videoProducer &&
                videoProducer.type !== "share" &&
                !videoProducer.paused ? (
                  <VideocamIcon fontSize="large"></VideocamIcon>
                ) : null}
              {!(
                me.canSendWebcam &&
                videoProducer &&
                videoProducer.type !== "share" &&
                !videoProducer.paused
              ) ? (
                  <VideocamOffIcon fontSize="large"></VideocamOffIcon>
                ) : null}
            </Fab>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default withRoomContext(ButtonCamera);
