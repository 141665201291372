import { Card, Button } from "@material-ui/core";
import _ from "lodash";

import React, { useState, useMemo } from "react";

import TopDetail from "app/modules/components/decorators/TopDetail";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import translate, { setLang, lang } from "app/lang/translate";
import { useCallback } from "react";
import useEvent from "app/modules/meeting/components/Room/hooks/useEvent";
function LogoutCustomer() {
  const insideIframe = useMemo(() => window.top !== window.self, []);
  const { closeWindow } = useEvent();
  const { lang: langParam } = useParams();
  const [langTranslate, setLangTranslate] = useState(lang);
  useEffect(() => {
    if (langParam && langParam !== lang) {
      setLang(langParam);
      setLangTranslate(langParam);
    }
  }, [langParam]);
  return (
    <div className="signup flex flex-center flex-middle w-100 h-100vh">
      <div style={{ width: "80%" }}>
        <Card>
          <div
            className={`${
              insideIframe ? "pb-20" : "pb-0"
            } px-20 pt-20 frame-detail text-center`}
          >
            <TopDetail
              title="LOGOUT_DEFAULT_TITLE"
              subtitle="LOGOUT_DEFAULT_SUBTITLE"
              srcImage=""
            />
            {insideIframe && (
              <Button
                variant="contained"
                size="small"
                type="button"
                onClick={() => {
                  closeWindow();
                }}
              >
                {translate("TEXT_EXIT")}
              </Button>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default LogoutCustomer;
