import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReactNotifications from "react-browser-notifications";
import { connect, useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";
import * as stateActions from "../../../redux/stateActions";
import constants from "./../../../constants/index.js";
import * as appPropTypes from "./appPropTypes";

const Notifications = function () {
  const dispatch = useDispatch();
  const [play] = useSound("/sound/bell.mp3");
  const notificationRef = useRef();
  const notifications = useSelector((store) => store.notifications);
  const me = useSelector((store) => store.me);
  const meApi = useSelector((store) => store.api.me);
	const [noty, setNoty] = useState();
	
  const onClickNotification = (notificationId) => {
    dispatch(stateActions.removeNotification(notificationId));
  };

  const showNotifications = () => {
    
    if (
      (meApi.isAdmin || me.peerType == constants.PEERS_TYPES.SPEEKER) &&
      notificationRef &&
      notificationRef.current &&
      notificationRef.current.supported()
    ) {
      play();
      notificationRef.current.show();
    }
  };

  const handleClick = (event) => {
    _.get(notificationRef, "current.close", () => {})(event.target.tag);
	};
	
  useEffect(() => {
    const notification = _.find(notifications, (notification) => {
      return notification.type == "browser";
    });
    setNoty(notification);
	}, [notifications]);
	
  useEffect(() => {
    if (noty && notificationRef) {
      showNotifications();
    }
  }, [noty]);

  return (
    <React.Fragment>
      <ReactNotifications
        onRef={(ref) => (notificationRef.current = ref)} // Required
        title={_.get(noty, "title", "")} // Required
        body={_.get(noty, "text", "")} //{notification.title}
        icon="/img/hand.png"
        timeout="5000"
        onClick={(event) => handleClick(event)}
      />
      <div data-component="Notifications">
        {notifications.map((notification, i) => {
          return (
            <React.Fragment key={i}>
              {/* {notification.type == "browser" ? null : (
                <Appear key={notification.id} duration={250}>
                  <div
                    className={classnames("notification", notification.type)}
                    onClick={() => onClickNotification(notification.id)}
                  >
                    <div className="icon">
                      <IconComponent
                        custom={true}
                        children="info"
                        size={24}
                      >
											</IconComponent>
                    </div>
                    <div className="body">
                      {notification.title ? (
                        <p className="title">{notification.title}</p>
                      ) : null}

                      <p className="text">{notification.text}</p>
                    </div>
                  </div>
                </Appear>
              )} */}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(appPropTypes.Notification).isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { notifications } = state;
  // notifications.push({
  // 	id: "dopozw",
  // 	text: "Seba ha levantado la mano",
  // 	timeout: undefined,
  // 	title: undefined,
  // 	type: "info",
  // })
  return { notifications };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (notificationId) => {
      dispatch(stateActions.removeNotification(notificationId));
    },
  };
};

const NotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);

export default NotificationsContainer;
