import React, { useContext, useEffect } from "react";
// import Me from './Me';
// import ChatInput from './ChatInput';
import RoomContext from "app/modules/meeting/RoomContext";
//import classnames from 'classnames';
import RoomHubeetSupportContent from "./components/RoomHubeetSupportContent";
import DisabledRoomModal from "../../../DisabledRoomModal";
import ModalForUserCloseRoom from "../../../ModalForUserCloseRoom";
import ModalForUserEject from "../../../ModalForUserEject";
import ModalForUserMultipleDevice from "../../../ModalForUserMultipleDevice";
import ModalForUserNotInvited from "../../../ModalForUserNotInvited";
// import Invite from './Invite';
// import Speeker from './Speeker';
//import Menu from './Menu';
import Notifications from "../../../Notifications";
import { Appear } from "../../../transitions";
import ModalCloseRoomOnlineSupport from "../../../ModalCloseRoomOnlineSupport";

function RoomHubeetSupport({ enableAsking }) {
  const roomClient = useContext(RoomContext);
  useEffect(() => {
    roomClient.join();
  }, []);
  return (
    <div id="app-room-hubeet-support-container">
      <Appear duration={300}>
        <div data-component="Room">
          <Notifications />
          <RoomHubeetSupportContent enableAsking={enableAsking} />
          <DisabledRoomModal></DisabledRoomModal>
          <ModalCloseRoomOnlineSupport />
          <ModalForUserCloseRoom />
          <ModalForUserEject />
          <ModalForUserMultipleDevice />
          <ModalForUserNotInvited />
        </div>
      </Appear>
    </div>
  );
}

export default RoomHubeetSupport;
