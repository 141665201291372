// import _ from 'lodash';
// import glob from "glob";
// import genericEs from './traductions/es.js'
// import sessionEs from "./../modules/**/lang/es"
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// let es = genericEs; 
// sessionEs.forEach(path => {
//     es = { ...es, ...require(path).default };

// })
// export default (key = "", params = {}, keyDefault = "") => {
//     try {
//         return ReactHtmlParser(_.get(es, key.toUpperCase()) || _.get(es, keyDefault.toUpperCase()) || key)[0];
//     } catch (e) {
//         console.log(`Error when translating entry - key: ${key} params: ${params} keyDefault: ${keyDefault}`);
//     }

// } 
import _ from 'lodash';
import genericEs from './traductions/es.js'
import sessionEs from "./../modules/session/lang/es"
import genericEn from './traductions/en.js'
import sessionEn from "../modules/session/lang/en"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
let lang = "es";
const es = _.merge(sessionEs,genericEs);
const en = _.merge(sessionEn,genericEn);
const setLang =(newLang) =>{
    lang = newLang;
};
export  {setLang,lang};
export default  (key = "", params={}, keyDefault="") => {  
    try{
        if (lang==="en"){
            return ReactHtmlParser(_.get(en, key.toUpperCase() ) || _.get(en, keyDefault.toUpperCase() ) || key)[0];
        }
        return ReactHtmlParser(_.get(es, key.toUpperCase() ) || _.get(es, keyDefault.toUpperCase() ) || key)[0];
    }catch(e){
        console.log(`Error when translating entry - key: ${key} params: ${params} keyDefault: ${keyDefault}`);
    }
    
} 