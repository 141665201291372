import React from "react";
import translate from "../../../lang/translate";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Record from "./admin/Record";
import { makeStyles } from '@material-ui/core/styles'
import Transmit from "./admin/Transmit";
import Scrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles({
  hideBorder: {
    '&.MuiExpansionPanel-root:before': {
			display: 'none',
		},
		'&.MuiExpansionPanel-rounded' : {
			"border-radius": "8px",
		},
		'&.MuiPaper-root' : {
			"margin-bottom": "8px",
		},
	},
	bestDetails: {
		'&.MuiExpansionPanelDetails-root' : {
			'padding': '0px 16px 16px',
		},
	}
})

const AdminPanel = ({ setAsking, setSpeaker, me, meApi, open, peers }) => {
	const classes = useStyles()
  return (
    <div data-component="AdminPanel">
      <div className="contentTitle">
        {translate("TEXT_ADMIN_PANEL")}
      </div>
      <Scrollbar className="div-scroll">
        <ExpansionPanel className={classes.hideBorder}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
  					id="video-header"
  					className="expansion-desing"
  				>
            <h5 className="m-0">{translate("TEXT_TRANSMIT_VIDEO")}</h5>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.bestDetails}>
  					<Transmit />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.hideBorder}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
  					id="video-header"
  					className="expansion-desing"
          >
            <h5 className="m-0">{translate("TEXT_RECORD_VIDEO")}</h5>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.bestDetails}>
            <React.Fragment>
              <Record />
            </React.Fragment>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Scrollbar>
    </div>
  );
};

export default AdminPanel;
