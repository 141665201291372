import Tooltip from "@material-ui/core/Tooltip";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import GroupIcon from "@material-ui/icons/Group";
// import { propTypes } from "react-image-fade-in";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// import ButtonMicrophone from "./../../modules/components/room/buttons/customs/ButtonMicrophone";
// import ButtonCamera from "./../../modules/components/room/buttons/customs/ButtonCamera";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import IconComponent from "../../../modules/components/generic/Icon";
import FloatMenu from "../../../modules/components/menu/FloatMenu";
import constants from "../../../constants/index.js";
import translate from "../../../lang/translate";
//import classnames from "classnames";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import {
  changeStatusRoom,
  toogleAdminPanel,
  toogleChat,
  toogleContacts,
} from "../../../redux/actions/roomActions";
import {
  changeLang,
  toggleChat,
  toggleHand,
  toggleShowInivited,
  toggleShowMe,
} from "../../../redux/stateActions";
import helper from "../../../utils/helpers";
import AuthAccess from "./../../../modules/session/auth/AuthAccess";
import ListItem from "./../../../modules/components/menu/ListItem";
import useService from "./../../../modules/api/hooks/useService";
import services from "./../../../services";
// import IconButton from "@material-ui/core/IconButton";
import ButtonMenuTop from "./ButtonMenuTop";
import StatusBar from "./Room/roomTypes/event/desktop/StatusBar";
import Inforoom from "./Inforoom";
//import Button from "./Button";
import Infospeaker from "./Infospeaker";
import ManagerAccess from "./ManagerAccess";

const MenuTop = ({
  api,
  room,
  roomClient,
  audioProducer,
  videoProducer,
  videoProducerShare,
  toggleChat,
  toggleHand,
  toggleShowInivited,
  toggleShowMe,
  changeLang,
  langs,
  stateBar,
}) => {
  const [anchorLang, setAnchorLang] = useState(null);
  const [anchorInviteInterpreter, setAnchorInviteInterpreter] = useState(null);
  const [anchorScreen, setAnchorScreen] = useState(null);
  const [openPopoverScreen, setOpenPopoverScreen] = useState(false);
  const [openPopoverInviteInterpreter, setOpenPopoverInviteInterpreter] =
    useState(false);
  const [openPopoverLang, setOpenPopoverLang] = useState(false);
  const [anchorSettings, setAnchorSettings] = useState(null);
  const [openPopoverSettings, setOpenPopoverSettings] = useState(false);
  const [showInfoRoom, setShowInfoRoom] = useState(false);
  const [showInfoSpeaker, setShowInfoSpeaker] = useState(false);
  const [setWebcamState] = useState(null);
  const [canSendWebcam, setCanSendWebcam] = useState(null);

  const [blinkNotificationColor, setBlinkNotificationColor] =
    useState("inherit");
  const [cantBlinkNotification, setCantBlinkNotification] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const chat = useSelector((store) => store.chat);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const me = useSelector((store) => store.me);

  const disableShare = () => {
    const aux = !(me.peerType === constants.PEERS_TYPES.SPEEKER || me.asking);
    return aux;
  };

  const dispatch = useDispatch();

  const currentRoom = useSelector((store) => store.room);
  const currentRoomApi = useSelector((store) => store.api.currentRoom);

  const { send: activeRoom, loading: loadingActiveRoom } = useService(
    services.module.rooms.enabled,
    {
      paramsUrl: { roomId: _.get(currentRoom, "info._id") },
      callback: () => {
        dispatch(changeStatusRoom("open"));
      },
    }
  );

  const { send: disabledRoom, loading: loadingDisabledRoom } = useService(
    services.module.rooms.enabled,
    {
      paramsUrl: { roomId: _.get(currentRoom, "info._id") },
      callback: () => {
        dispatch(changeStatusRoom("disabled"));
      },
    }
  );

  const toogleContactsMenu = () => {
    dispatch(toogleContacts());
  };

  const toogleChatMenu = () => {
    setBlinkNotificationColor("inherit");
    dispatch(toogleChat());
    setCantBlinkNotification(false);
  };

  const toogleAdminPanelMenu = () => {
    dispatch(toogleAdminPanel());
  };

  useEffect(() => {
    if (canSendWebcam && !me.canSendWebcam) {
      if (!me.canSendWebcam) setWebcamState(null);
      else if (videoProducerShare) setWebcamState(true);
      else setWebcamState(false);
      setCanSendWebcam(me.canSendWebcam);
    }
  });

  useEffect(() => {
    if (chat.newMessages) {
      handleblink();
    }
  }, [chat.newMessages]);

  const handleOpenLang = (event) => {
    setAnchorLang(event.currentTarget);
    setOpenPopoverLang(true);
  };

  const handleOpenSettings = (event) => {
    setAnchorSettings(event.currentTarget);
    setOpenPopoverSettings(true);
  };

  const handleOpenInviteInterpreter = (event) => {
    setAnchorInviteInterpreter(event.currentTarget);
    setOpenPopoverInviteInterpreter(true);
  };

  const handleOpenScreen = (event) => {
    setAnchorScreen(event.currentTarget);
    setOpenPopoverScreen(true);
  };

  const handleCloseInviteInterpreter = () => {
    setOpenPopoverInviteInterpreter(false);
  };

  const handleCloseScreen = () => {
    setOpenPopoverScreen(false);
  };

  const handleCloseLang = () => {
    setOpenPopoverLang(false);
  };
  const handleCloseSettings = () => {
    setOpenPopoverSettings(false);
  };

  const handleShowInfoRoom = () => {
    handleCloseSettings();
    setShowInfoRoom(!showInfoRoom);
  };

  const handleEnabledRoom = () => {
    activeRoom(null, { roomId: _.get(currentRoom, "info._id") });
  };

  const handleDisabledRoom = () => {
    disabledRoom(null, { roomId: _.get(currentRoom, "info._id") });
  };

  const handleShowInfoSpeaker = () => {
    handleCloseSettings();
    setShowInfoSpeaker(!showInfoSpeaker);
  };

  const handleDesktopShare = () => {
    if (videoProducerShare) {
      roomClient.disableShare();
    } else {
      roomClient.enableShare();
    }
  };

  const handleLogout = () => {
    window.localStorage.clear();
    window.location = "/";
  };

  const handleblink = () => {
    if (!room.show_chat) {
      setBlinkNotificationColor("primary");
      setCantBlinkNotification(true);
      setTimeout(function () {
        setCantBlinkNotification(false);
      }, 5000);
    }
  };

  return (
    <div data-component="MenuTop">
      <div className={"botoneraLeft"}>
        <StatusBar />
      </div>
      <div className={"botoneraCenter"}>
        <Tooltip
          title={
            currentRoomApi.description === "" ||
            currentRoomApi.description === undefined
              ? translate("TEXT_ROOM_NO_DESCRIPTION")
              : helper.formatText(currentRoomApi.description, true)
          }
        >
          <div className="tags">
            <span className="resalt text-muted">
              {translate("TEXT_YOU_ARE")}:{" "}
              {currentRoomApi.title === "" || currentRoomApi.title === undefined
                ? translate("TEXT_ROOM_UNTITLED")
                : helper.formatText(currentRoomApi.title, true)}
            </span>
          </div>
        </Tooltip>
      </div>
      <div className={"botoneraRight"}>
        {/* <ManagerAccess action="INVITE_INTERPRETER">
          <ButtonMenuTop
            child={<RecordVoiceOverIcon />}
            tip="TOOLTIP_INVITE_INTERPRETE"
            actionClick={handleOpenInviteInterpreter}
          ></ButtonMenuTop>
          <Popover
            data-component="Popover"
            open={openPopoverInviteInterpreter}
            anchorEl={anchorInviteInterpreter}
            onClose={handleCloseInviteInterpreter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <InviteInterpreterPanel
              handleClose={handleCloseInviteInterpreter}
            ></InviteInterpreterPanel>
          </Popover>
        </ManagerAccess> */}
        <ButtonMenuTop
          child={<ScreenShareIcon />}
          tip="SHARE_MY_SCREEN_ON"
          actionClick={handleDesktopShare}
          toggle={room.show_shareScreen}
          tipClose="SHARE_MY_SCREEN_OFF"
          disabled={disableShare()}
        ></ButtonMenuTop>
        <ButtonMenuTop
          child={<ChatBubbleIcon />}
          tip="TOOLTIP_SHOW_CHAT"
          actionClick={toogleChatMenu}
          toggle={room.show_chat}
          tipClose="TOOLTIP_HIDE_CHAT"
          blink={cantBlinkNotification}
          color={blinkNotificationColor}
        ></ButtonMenuTop>
        <ButtonMenuTop
          child={<GroupIcon />}
          tip="SHOW_PARTICIPANTS_LIST"
          actionClick={toogleContactsMenu}
          toggle={room.show_contacts}
          tipClose="HIDE_PARTICIPANTS_LIST"
        ></ButtonMenuTop>
        <ManagerAccess action="PANEL_ADMIN">
          <ButtonMenuTop
            child={<VpnKeyIcon />}
            tip="TOOLTIP_SHOW_ADMIN"
            actionClick={toogleAdminPanelMenu}
            toggle={room.show_adminPanel}
            tipClose="TOOLTIP_HIDE_ADMIN"
          ></ButtonMenuTop>
        </ManagerAccess>
        <ButtonMenuTop
          child={<MoreVertIcon />}
          tip="TOOLTIP_SHOW_SETTINGS"
          actionClick={handleMoreClick}
        ></ButtonMenuTop>
        <FloatMenu
          options={[
            <ListItem
              show={<AuthAccess businessRule={"CAN_ENABLED_ROOM"} />}
              onClick={handleEnabledRoom}
              icon={
                <IconComponent custom={true} size={20}>
                  activeMeetings
                </IconComponent>
              }
              text={translate("TEXT_ENABLED_ROOM")}
            />,
            <ListItem
              show={<AuthAccess businessRule={"CAN_DISABLED_ROOM"} />}
              onClick={handleDisabledRoom}
              icon={
                <IconComponent custom={true} size={20}>
                  closeroom
                </IconComponent>
              }
              text={translate("TEXT_DISABLED_ROOM")}
            />,
            <ListItem
              show={false}
              onClick={handleShowInfoRoom}
              icon={
                <IconComponent custom={true} size={20}>
                  info1
                </IconComponent>
              }
              text={translate("TEXT_INFO_ROOM")}
            />,
            <ListItem
              show={true}
              onClick={handleLogout}
              icon={
                <IconComponent custom={true} size={20}>
                  switch1
                </IconComponent>
              }
              text={translate("TEXT_LOGOUT")}
            />,
          ]}
          anchorEl={anchorEl}
          handleClose={handleClose}
        ></FloatMenu>

        <div className="detail-bottom"></div>
      </div>
      <Inforoom open={showInfoRoom} handleClose={handleShowInfoRoom} />
      <Infospeaker open={showInfoSpeaker} handleClose={handleShowInfoSpeaker} />
    </div>
  );
};
MenuTop.propTypes = {
  roomClient: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  const producersArray = Object.values(state.producers);
  const audioProducer = producersArray.find(
    (producer) => producer.track.kind === "audio"
  );
  const videoProducer = producersArray.find(
    (producer) => producer.track.kind === "video" && producer.type != "share"
  );
  const videoProducerShare = producersArray.find(
    (producer) => producer.track.kind === "video" && producer.type == "share"
  );
  const langs = _.get(state.room, "info.languages", []).map((lang) => ({
    ...lang,
    id: lang.isoCode,
    key: lang.isoCode,
  }));
  return {
    room: state.room,

    api: state.api,
    audioProducer,
    videoProducer,
    videoProducerShare,
    langs,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { toggleChat, toggleHand, toggleShowInivited, toggleShowMe, changeLang },
    dispatch
  );

const MenuContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => prev === next,
  })(MenuTop)
);

export default MenuContainer;
