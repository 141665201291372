

export default {
  module:{
    companies:{
      list: {
        uri:"/admin/module/companies/list",
      },
      add: {
        uri:"/admin/module/companies/add",
      },
      
    }
  }
};