import { MatxLoadable } from "matx";
import { getUriPage } from "../../../views/RootPages";

const NewApiKey = MatxLoadable({
  loader: () => import("./pages/NewApiKey"),
});

const CompaniesList = MatxLoadable({
  loader: () => import("./pages/CompaniesList"),
});

const companiesRoute = [
  {
    path: getUriPage("module.companies.add"),
    component: NewApiKey,
  },
  {
    path: getUriPage("module.companies.list"),
    component: CompaniesList,
  },
];
export default companiesRoute;
