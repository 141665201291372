const user = window.localStorage.getItem("user")
const initialState = {
	token: window.localStorage.getItem("token"),
	user: user && user !== "undefined" ? JSON.parse(user) : null,
	access: user && user !== "undefined" ? JSON.parse(user).access : [],
	client: null
};

const apiReducer = (state = initialState, action) => {

	switch (action.type) {
		case 'CHANGE_PASS': {
			let newUser = {...state.user}
			newUser.shouldChangePassword=false;
			return { ...state,  user:newUser};
		}
		case 'LOGIN': {
			const { token, user } = action.payload;
			return { ...state, token, user, access: user.access };
		}
        case 'SET_ORGANIZATION': {
			const { name, logo, icon,termsAndConditions } = action.payload;
			return { ...state, client: { name, logo, icon ,termsAndConditions} };
        }
        case 'SET_CURRENT_USER': {
			const { user } = action.payload;
			return { ...state, user: user, access: user.access };
		}
		default:
			return state;
	}
};

export default apiReducer;
