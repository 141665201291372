import React from "react";
import Text from "./../Text/Text"
const CountrySelect = ({ id,...props }) => {
    return (
        <div className={`w-100`} data-component="CountrySelect">
            <img className={`mr-8`}src={`/room/images/flags-iso/flat/16/${id.toUpperCase()}.png`} />
            <Text uuid={`LANG_${id}`}/>
        </div>
    );
};


export default CountrySelect;
