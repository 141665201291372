import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import translate,{ setLang, lang } from "../../../lang/translate";
import ImageFadeIn from "react-image-fade-in";

export default function TopDetail({ ...props }) {
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <h2 className={`capitalize-flfw${props.isMobil ? " mt-0" : " mt-20"}`}>
          {translate(props.title)}
        </h2>
        <div className="card-subtitle mb-40 text-muted">
          {translate(props.subtitle)}
        </div>
      </Box>
      {props.srcImage !== "" ? (
        <Box width="110px">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {props.srcImage !== "" ? (
              <ImageFadeIn src={props.srcImage} />
            ) : null}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
}
