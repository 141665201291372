
import React from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "./../generic/Icon";


function ListItem({ text, icon, iconAfter, onClick, ...props }) {


  return (
    <React.Fragment>
      <div className="flex flex-center flex-middle w-100" onClick={onClick}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
        {iconAfter && (
          <ListItemIcon>
            {iconAfter}
          </ListItemIcon>
        )}
      </div>
    </React.Fragment>
  );
}

export default ListItem;
