import React from "react";

import translate from "../../../lang/translate";
const Text = ({uuid}) => {
    return (
        <React.Fragment>
            {uuid && translate(uuid)}
        </React.Fragment>
    );
};


export default Text;
