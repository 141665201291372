export default {
  ACTIVE_ROOMS: "Active rooms",
  ACTIVE_ROOMS_NAME: "Room name: ",
  ADD_NEW_APP: "Create new app",
  ADD_NEW_ROOM: "Create new room",
  ADD_NEW_USER: "Create User",
  APPS: "My apps",
  BUTTON_BACK_LOGIN: "Back",
  CAN_TALK: "You have the floor",
  CHANGED_HIS_LANGUAGE: "has changed its interpretation language",
  CHANGED_HIS_NAME_TO: "you have changed your name to",
  CHANGE_PASSWORD_TEXT_SUBTITLE: "Fill in the data, to change your password.",
  CHAT_UNAVAILABLE: "Chat is not available.",
  CLOSE_CHAT: "Close chat window",
  CONNECTED: "Connected",
  CONNECTING: "Connecting",
  COULD_NOT_CHANGE_WEBCAM_RESOLUTION: "Error changing camera resolution",
  CREATE_APP: "Create app",
  CREATE_ROOM: "Create room",
  DASHBOARD_ACTIVE_MEETINGS: "Available rooms",
  DASHBOARD_ACTIVE_MEETINGS_PLACEHOLDER:
    "This listing shows the rooms you have created or have been invited to",
  DASHBOARD_CLOSED_MEETINGS: "Previous meetings",
  DASHBOARD_CLOSED_MEETINGS_PLACEHOLDER:
    "In these rooms were the meetings you attended or did not participate in",
  DASHBOARD_FUTURE_MEETINGS: "Future meetings",
  DASHBOARD_FUTURE_MEETINGS_PLACEHOLDER:
    "In these rooms your next meetings are scheduled",
  DASHBOARD_GOTO_AREA: "Go to listing",
  DATA_CONSUMER_CLOSED: "Data consumer closed.",
  DATA_CONSUMER_ERROR: "Data consumer error.",
  DATA_CONSUMER_ERROR: "Data consumer error.",
  ERROR_CLOSING_SERVER_SERVER_SIDE_WEBCAM_PRODUCER:
    "Error closing server-side video producer",
  ERROR_CLOSING_SERVER_SIDE_SHARE_PRODUCER: "Error closing server-side PCDP",
  ERROR_CODE_0001: "User and password do not match",
  ERROR_CODE_0002: "User is not enabled",
  ERROR_CODE_0003: "User does not exist",
  ERROR_CODE_0004: "The token does not exist",
  ERROR_CODE_0005: "Session has expired",
  ERROR_CODE_0006: "The user is already logged in",
  ERROR_CODE_0007: "You are logged in on another device",
  ERROR_CODE_0008: "You were logged out by the administrator",
  ERROR_CODE_0009: "The room was closed",
  ERROR_CODE_0010: "The user is not invited",
  ERROR_CODE_0011: "The session has expired",
  ERROR_CREATING_CONSUMER: "Error creating a data consumer",
  ERROR_CREATING_DATA_CONSUMER: "Error creating data consumer.",
  ERROR_CREATING_DATA_CONSUMER: "Error creating data consumer",
  ERROR_ENABLING_MIC: "Error enabling microphone",
  ERROR_ENABLING_WEBCAM: "An error occurred while enabling the camera",
  ERROR_PAUSING_SERVER_SIDE_MIC_PRODUCER:
    "Error pausing the server-side sound producer",
  ERROR_SHARING: "Error sharing screen",
  HAS_BEEN_GRANTED_SPEAK:
    "You have the floor. Your camera and microphone have been enabled",
  HAS_BEEN_REVOKED_SPEAK:
    "You no longer have the floor. Your camera and microphone have been disabled.",
  HAS_ENTERED_THE_ROOM: "you have entered the room",
  HAS_RAISED_HIS_HAND: "you have raised your hand",
  HIDE_PARTICIPANTS_LIST: "Hide participants panel",
  HIDE_PRODUCT_INFO: "Hide product information",
  INPUT_YOUR_NAME: "Name",
  INPUT_YOUR_NAME_PLACEHOLDER: "Type your name to identify you",
  INTERPRETER_LENGUAGE_SELECTION: "Interpreter language",
  LABEL_LOGOUT_APP: "Exit the room",
  LANG_EN: "English",
  LANG_ES: "Spanish",
  LANG_FR: "French",
  LANG_IT: "Italian",
  LANG_LSA: "Argentine Sign Language",
  LANG_LSB: "Bolivian Sign Language",
  LANG_LSC: "Colombian Sign Language",
  LANG_LSCH: "Chilean Sign Language",
  LANG_LSE: "Spanish Sign Language",
  LANG_LSEC: "Ecuadorian Sign Language",
  LANG_LSI: "Sign Language International",
  LANG_LSM: "Mexican Sign Language",
  LANG_LSP: "Peruvian Sign Language",
  LANG_LSV: "Venezuelan Sign Language",
  LANG_NL: "Dutch",
  LANG_PT: "Portuguese",
  LANG_ZH: "Chinese",
  LINK_ACCEPT_TERMS: "Accept",
  LINK_ACCEPT_TERMS_LINK: "terms and conditions",
  LINK_CHANGE_PASSWORD: "Change password",
  LINK_COPIED_TO_CLIPBOARD: "The room link has been copied to the clipboard",
  LOGIN_ERROR: "Invalid credentials.",
  LOGOUT_DEFAULT: "We thank you for using this new way to communicate.",
  LOGOUT_DEFAULT_SUBTITLE: "For using our meeting platform.",
  LOGOUT_DEFAULT_TITLE: "Thank you!",
  MENU_ACTIVITIES: "Activity Log",
  MENU_DASHBOARD: "Dashboard",
  MENU_MONITOR: "Monitoring",
  MENU_MY_APPS: "My apps",
  MENU_MY_APPS_PLACEHOLDER: "These are the apps you have created",
  MENU_MY_ROOMS: "My rooms",
  MENU_MY_ROOMS_PLACEHOLDER: "These are the rooms you have created",
  MENU_NEW_APP: "New app",
  MENU_NEW_APP_PLACEHOLDER: "Complete the required data to create the new app.",
  MENU_NEW_ROOM: "New room",
  MENU_NEW_ROOM_PLACEHOLDER: "Fill in the required data to create the new room",
  MENU_UPDATE_USER: "Modify user",
  MENU_USERS: "Users",
  MENU_USER_NAME: "User List",
  MIC_DISCONNECTED: "Microphone disconnected!",
  MODAL_BUTTON_CAMERA: "Camera",
  MODAL_BUTTON_CAMERA_MICRO: "Camera and microphone",
  MODAL_BUTTON_DISABLED: "None",
  MODAL_BUTTON_MICRO: "Microphone",
  MODAL_CLOSE_CUSTOMER_TITLE: "Are you sure you want to leave?",
  MODAL_CLOSE_PEER_SUBTITLE: "You have been made to leave the site.",
  MODAL_CLOSE_PEER_TITLE: "You have been disconnected.",
  MODAL_CLOSE_ROOM_SUBTITLE: "We hope you come back soon!",
  MODAL_CLOSE_ROOM_TITLE: "Are you leaving already?",
  MODAL_ENABLEDPEER_SUBTITLE:
    "Now other people will be able to see and hear it.",
  MODAL_ENABLEDPEER_TITLE: "You have permission to speak",
  MODAL_FORUSER_CLOSEROOM:
    "The administrator has closed this room and therefore you can no longer be in it, you will not be able to enter again until it is active.",
  MODAL_FORUSER_CLOSEROOM_SUBTITLE: "There is no one here anymore...",
  MODAL_FORUSER_CLOSEROOM_TITLE: "The room is closed",
  MODAL_FORUSER_EJECT:
    "For some reason, the administrator has decided to expel you from this room, contact the administrator for more details.",
  MODAL_FORUSER_EJECT_SUBTITLE: "You can no longer participate",
  MODAL_FORUSER_EJECT_TITLE: "You have been banned",
  MODAL_FORUSER_MULTIPLEDEVICE:
    "You have entered the same room from another device, for that reason you have been removed from the room. You will be able to participate from the other device.",
  MODAL_FORUSER_MULTIPLEDEVICE_SUBTITLE: "You accessed from another device",
  MODAL_FORUSER_MULTIPLEDEVICE_TITLE: "Did you log in again?",
  MODAL_FORUSER_NOTINVITED:
    "The room administrator has not allowed you to enter the room, contact him to allow you access.",
  MODAL_FORUSER_NOTINVITED_SUBTITLE: "You are not invited to this room",
  MODAL_FORUSER_NOTINVITED_TITLE: "You are not allowed to enter",
  MODAL_KICK_PEER_SUBTITLE:
    "You are about to kick that person out of the meeting",
  MODAL_KICK_PEER_TEXT:
    "Are you sure you want to kick the participant out of the room?",
  MODAL_KICK_PEER_TITLE: "Eject participant",
  MODAL_TERMS_SUBTITLE: "Please read the terms of service",
  MODAL_TERMS_TITLE: "Terms and conditions",
  NEWUSER_INCORRECT_PASSWORD:
    "The password has to be 8 to 16 characters, one number, one uppercase and one lowercase.",
  NEWUSER_INVALID_EMAIL: "The email must be valid",
  NEWUSER_PASSWORD_NOT_MACH: "Passwords do not match",
  NEWUSER_PASSWORD_REQUIRED: "Password is required",
  NOTIFICATION_CREATE_ROOM_SUCCESS: "Room created successfully",
  NOTIFICATION_DISABLED_ROOM_SUCCESS: "Room deactivated",
  NOTIFICATION_ENABLED_ROOM_SUCCESS: "Room successfully activated",
  NOTIFICATION_ERROR: "Error in the server",
  NOTIFICATION_INTERPRETER_INCITED_SUCCESS:
    "An interpreter will soon join the room...",
  NOTIFICATION_SUCCESS: "Successful operation",
  NO_ACTIVE_ROOMS: "There are no active rooms",
  PAGE_PASSWORD_RESETED:
    "We have sent you, to your email, a temporary password with which you will be able to log in. Remember that once you log in you will have to change it to one of your choice",
  PAGE_PASSWORD_RESETED_SUBTITLE: "You have completed the process",
  PAGE_PASSWORD_RESETED_TITLE: "New password",
  PAGE_RESET_LINK:
    "We have sent an email to the account you specified with the steps to generate a new login password. Check your email and open the link we sent you.",
  PAGE_RESET_LINK_SUBTITLE: "Read carefully to follow the process",
  PAGE_RESET_LINK_TITLE: "Change accepted",
  PEER_ASKING: "ENABLED",
  PEER_NO_ASKING: "DISABLED",
  RAISE_HAND: "HAND RAISED",
  READ_MORE: "Read more",
  RECORD_WARNING:
    "Please note that to record the audio and video of all participants you must choose as the device to share the current tab of your browser and enable the audio sharing option.",
  REQUIRED_FIELD: "Field is required.",
  RESET_TEXT_SEND_BY_EMAIL:
    "An email has been sent, in order to recover the password",
  ROLES_ADMIN: "Admin",
  ROLES_INTERPRETER: "Interpreter",
  ROLES_INVITED: "Participant",
  ROLES_SPEEKER: "Speaker",
  ROOM_ENABLED: "The room is now enabled.",
  SHARE_DISCONNECTED: "Screen sharing has been disconnected",
  SHARE_MY_SCREEN_OFF: "Stop sharing my screen",
  SHARE_MY_SCREEN_ON: "Share my screen",
  SHOW_HIDE_PARTICIPANTS_VIDEO: "Show/hide my screen",
  SHOW_PARTICIPANTS_LIST: "Participants panel",
  SHOW_PRODUCT_INFO: "Product information",
  SNACK_COPY_API_KEY: "The api key has been copied to the clipboard",
  SNACK_COPY_LINK: "The link has been copied to the clipboard",
  STOP_RAISE_HAND: "HAND DOWN",
  TEXT_404: "Sorry, the page you are looking for does not exist",
  TEXT_ACCEPT: "Close room",
  TEXT_ACTION: "Actions",
  TEXT_ACTIVITY_STATUS: "Status",
  TEXT_ADDITIONAL_DATA: "Advanced Configuration",
  TEXT_ADD_CUSTOM_FIELD: "Add requested data",
  TEXT_ADD_PLURAL1: "en",
  TEXT_ADD_PLURAL2: "s",
  TEXT_ADD_PLURAL3: "n",
  TEXT_ADD_USER: "Add user",
  TEXT_ADMIN_PANEL: "Administrator Panel",
  TEXT_API_KEY: "Api key",
  TEXT_APP_NAME: "App Nnme",
  TEXT_APP_NAMES_PLACEHOLDER: "Description room",
  TEXT_APP_WEBHOOK_INTERPRETER: "Interpreter Webhook",
  TEXT_APP_WEBHOOK_INTERPRETER_PLACEHOLDER: "Enter interpreter webhook",
  TEXT_APP_WEBHOOK_SUPPORT: "Support webhook",
  TEXT_APP_WEBHOOK_SUPPORT_PLACEHOLDER: "Enter the support webhook",
  TEXT_AVAILABLE: "Available",
  TEXT_BUTTON_ADD_FIELD_PARTICIPANTE: "Add participant field",
  TEXT_BUTTON_CANCEL: "Cancel",
  TEXT_BUTTON_CLOSE: "Close",
  TEXT_BUTTON_CONFIRM: "Accept",
  TEXT_BUTTON_ENABLED: "Enable",
  TEXT_BUTTON_ERASE: "Delete",
  TEXT_BUTTON_EXIT: "Exit",
  TEXT_BUTTON_KICK: "Eject",
  TEXT_BUTTON_SOLICITE: "Request",
  TEXT_CAM_OFF: "Camera off",
  TEXT_CAM_ON: "Camera on",
  TEXT_CHAT_PANEL: "Message panel",
  TEXT_CLOSE_PANEL: "Close panel",
  TEXT_CLOSE_PEER: "Close",
  TEXT_CLOSE_ROOM: "Close room",
  TEXT_CONECTOR1: "stop",
  TEXT_CONECTOR2: "of",
  TEXT_COPY_API_KEY: "Copy api key",
  TEXT_COPY_LINK_ROOM: "Copy link from room",
  TEXT_CREATE_ACCOUNT: "Send",
  TEXT_CREATE_ROOM: "Create room",
  TEXT_CREATE_ROOMS: "Create",
  TEXT_CUSTOMER_CANCEL_BUTTON: "No",
  TEXT_CUSTOMER_EXIT_BUTTON: "Yes",
  TEXT_DATE: "Date",
  TEXT_DATE_OF_MEETING_ACTIVE: "Date and time of the meeting",
  TEXT_DATE_OF_MEETING_FUTURE: "Date and time agreed",
  TEXT_DATE_OF_MEETING_PAST: "Date and time of the meeting",
  TEXT_DELETE_ROOM: "Delete room",
  TEXT_DISABLED_ROOM: "Disable Room",
  TEXT_DOTS: "...",
  TEXT_DURATION: "Duration",
  TEXT_ECOMERSESUPPORT: "Online support",
  TEXT_EDIT: "Edit",
  TEXT_EDIT_USER: "Edit User Data",
  TEXT_EJECT_PARTICIPANTS_ROOM: "Eject Participants",
  TEXT_EMPTY_STATE: "There seems to be nothing here...",
  TEXT_ENABLED: "Enabled",
  TEXT_ENABLED_ROOM: "Enable room",
  TEXT_ERROR_URL_VIDEO: "The video URL is incorrect",
  TEXT_EVENT: "Event with translator",
  TEXT_EXIT_ROOM: "Leave the room",
  TEXT_EXIT_ROOM_TEXT:
    "You can leave the room, but the meeting will continue without your presence.",
  TEXT_EXIT_ROOM_TEXT_ADMIN:
    "As you are the owner of the room, you can close the room and remove all participants.",
  TEXT_FIRSTNAME: "Name",
  TEXT_FIRSTNAME_PLACEHOLDER: "Type your name",
  TEXT_GOTO_ROOM: "Go to room",
  TEXT_GOTO_START: "Go to start",
  TEXT_GOTO_TOP: "Go to start",
  TEXT_HAVE: "Has",
  TEXT_HEADER_PLACEHOLDER: "Don't miss any of your meetings",
  TEXT_INFO_ROOM: "Room information",
  TEXT_INFO_SPEEKER: "Speaker information",
  TEXT_INIT_FORGOT_PASSWORD: "Password recovery",
  TEXT_INIT_SESSION_ENTER: "Login",
  TEXT_INIT_SESSION_PLACEHOLDER: "Enter the requested data",
  TEXT_INIT_SIGN_UP: "Are you entering for the first time?",
  TEXT_INTERPRETER_GOTO_PARTICIPANTE: "Enter as participant",
  TEXT_INTRO_GO_TO_LOGIN: "Login with my account",
  TEXT_IS_ADMIN: "Administrator",
  TEXT_LABEL_FIELD: "Field label",
  TEXT_LASTNAME: "Last Name",
  TEXT_LASTNAME_PLACEHOLDER: "Type your last name",
  TEXT_LISTENER: "Participant",
  TEXT_LOADER_CUSTOMER: "Waiting for an operator",
  TEXT_LOADER_NULL: "Waiting",
  TEXT_LOADER_SUPPORT: "Waiting for customer",
  TEXT_LOGIN: "Enter",
  TEXT_LOGOUT: "Logout",
  TEXT_MAIL: "E-mail",
  TEXT_MAXIMIZE: "Maximize",
  TEXT_ME: "Me",
  TEXT_MENU_ADD_SPEAKER: "Assign as speaker",
  TEXT_MENU_DISABLED_PARTICIPANTE: "Remove him as speaker",
  TEXT_MENU_EJECT: "Eject",
  TEXT_MENU_ENABLED_PARTICIPANTE: "Give him the floor",
  TEXT_MENU_MUTE: "Mute",
  TEXT_MENU_REMOVE_SPEAKER: "Remove him as speaker",
  TEXT_MENU_TITLE_CHAT: "Messages",
  TEXT_MENU_TITLE_CONTACTS: "Contacts",
  TEXT_MENU_TITLE_LANG: "Language",
  TEXT_MENU_TITLE_OPTIONS: "Options",
  TEXT_MENU_UNIQUE_SPEAKER: "Make single speaker",
  TEXT_MIC_OFF: "Microphone off",
  TEXT_MIC_ON: "Microphone on",
  TEXT_MINIMIZE: "Minimize",
  TEXT_MODAL_YOU_WORD:
    "You have been given the word, please select the device to use.",
  TEXT_MODAL_YOU_WORD_CUSTOMER: "Enable camera and microphone?",
  TEXT_MODAL_YOU_WORD_CUSTOMER_CLOSE: "Close",
  TEXT_MODAL_YOU_WORD_CUSTOMER_YES: "Yes",
  TEXT_MODAL_YOU_WORD_CUSTOMER_NO: "No",
  TEXT_MORE_ACTIONS: "More actions",
  TEXT_NAME_FIELD: "Field name",
  TEXT_NEWROOM_DESING: "Design",
  TEXT_NEWROOM_LENGUAGES: "Language and interpretation",
  TEXT_NEWUSER: "Activate user",
  TEXT_NEWUSER_PLACEHOLDER: "Fill in the requested data",
  TEXT_NEW_PASSWORD: "New password",
  TEXT_NEW_PASSWORD_PLACEHOLDER: "Enter a new password",
  TEXT_NOTAVAIBLE_STATE: "This feature is not available...",
  TEXT_NOTHING_TO_SAY: "I am not saying anything...",
  TEXT_NO_INTERPRETER: "At this time, there are no interpreters available.",
  TEXT_ONLYUSERS: "Private conference",
  TEXT_ORGANIZATION: "Organization",
  TEXT_PARTICIPANT_PANEL: "List of participants",
  TEXT_PASSWORD: "Password",
  TEXT_PASSWORD_PLACEHOLDER: "Enter current password",
  TEXT_PASSWORD_VERIFY: "Password verification",
  TEXT_PASSWORD_VERIFY_PLACEHOLDER: "Repeat new password",
  TEXT_PERMISSIONS: "Permission",
  TEXT_PIP: "Picture in picture",
  TEXT_PROFILE: "Role",
  TEXT_RECORD_VIDEO: "Recorder",
  TEXT_REQUIRED_FIELD: "You must fill in this field",
  TEXT_RESET: "Change password",
  TEXT_RESET_BYMAIL: "Change password",
  TEXT_RESET_PLACEHOLDER: "Enter your user email",
  TEXT_ROOM: "Room",
  TEXT_ROOMS: "My rooms",
  TEXT_ROOM_ADD_FIELDS_REGISTERS: "Additional information to request",
  TEXT_ROOM_ALIAS: "Alias of the room",
  TEXT_ROOM_ALIAS_PLACEHOLDER: "Alias of the room",
  TEXT_ROOM_AT: "at",
  TEXT_ROOM_BLOCK_ROOM_MESSAGE: "Message when room locked",
  TEXT_ROOM_BLOCK_ROOM_MESSAGE_PLACEHOLDER:
    "This message will appear when you try to enter this room and it is locked",
  TEXT_ROOM_CALL_INTERPRETER_COMMENT: "Comment",
  TEXT_ROOM_CALL_INTERPRETER_LANGUAGE: "Select a language",
  TEXT_ROOM_CLOSE_INFO: "Close",
  TEXT_ROOM_DATA_INIT: "Fill in the data",
  TEXT_ROOM_DATA_INIT_INTERPRETER: "You must select the language",
  TEXT_ROOM_DATE: "Date of the event",
  TEXT_ROOM_DATE_PLACEHOLDER: "Specify the date when the event will take place",
  TEXT_ROOM_DEFAULT_LANG: "Main language",
  TEXT_ROOM_DESCRIPTION: "Description",
  TEXT_ROOM_DESCRIPTION_INFO: "Room description",
  TEXT_ROOM_DESCRIPTION_PLACEHOLDER: "Brief description of the room",
  TEXT_ROOM_DISABLED_ROOM_MODAL_DETAILS:
    "You are entering a room that is not available. In order for other people to enter it has to be enabled.",
  TEXT_ROOM_DISABLED_ROOM_MODAL_SUBTITLE:
    "In this state the participants cannot enter.",
  TEXT_ROOM_DISABLED_ROOM_MODAL_TITLE: "Room disabled",
  TEXT_ROOM_IMAGE_STYLE: "CSS for room image",
  TEXT_ROOM_IMAGE_STYLE_PLACEHOLDER: "Styles for room image",
  TEXT_ROOM_IMG: "URL of the image for the room",
  TEXT_ROOM_IMG_PLACEHOLDER:
    "URL of the image to be displayed as room identification",
  TEXT_ROOM_INFO: "Room information",
  TEXT_ROOM_INIT: "Enter the room",
  TEXT_ROOM_INITE_NOT_AVAILABLE: "There are no active participants",
  TEXT_ROOM_INIT_SUBTITLE: "All set for the meeting!",
  TEXT_ROOM_INTERPRETER_NOT_AVAILABLE: "There are no active interpreters",
  TEXT_ROOM_LANGS_SUPPORT: "Interpreting languages",
  TEXT_ROOM_NAME: "Room name",
  TEXT_ROOM_NAMES: "Room name",
  TEXT_ROOM_NAMES_PLACEHOLDER: "Descriptive name for the room",
  TEXT_ROOM_NAME_INFO: "Name of the room",
  TEXT_ROOM_NOT_AVAILABLE:
    "The room is not yet enabled, the event may not have started yet.",
  TEXT_ROOM_NOT_AVAILABLE_SUBTITLE: "You cannot enter",
  TEXT_ROOM_NOT_AVAILABLE_TITLE: "There is a problem...",
  TEXT_ROOM_NO_DESCRIPTION: "(Room with no description)",
  TEXT_ROOM_OWNER: "Owner",
  TEXT_ROOM_ROLES: "Roles",
  TEXT_ROOM_SELECT_LANGUAGE: "Request an interpreter",
  TEXT_ROOM_TYPE: "Room type",
  TEXT_ROOM_UNTITLED: "(Unnamed room)",
  TEXT_ROOM_URL_FINISH: "Room end URL",
  TEXT_ROOM_URL_FINISH_PLACEHOLDER:
    "Closing or leaving the room will open this URL",
  TEXT_ROOM_USERNAME: "User name / email",
  TEXT_ROOM_USERS: "Users",
  TEXT_ROTATE: "Rotate",
  TEXT_SAY: "says:",
  TEXT_SEARCH: "Search",
  TEXT_SHARE_SCREEN: "Sharing screen",
  TEXT_SHOULD_UPDATE_PASSWORD: "Must update password",
  TEXT_START_RECORD: "Start recording",
  TEXT_STOP_RECORD: "Stop recording",
  TEXT_THE_WORD: "the word",
  TEXT_TODO_UPDATE: "Updating...",
  TEXT_TOTAL: "Total",
  TEXT_TRANSMIT_DISABLE_TOOLTIP: "Disable transmission",
  TEXT_TRANSMIT_ENABLE_TOOLTIP: "Enable transmission",
  TEXT_TRANSMIT_VIDEO: "Transmit video",
  TEXT_TRANSMIT_VIDEO_URL_PLACEHOLDER: "Video URL",
  TEXT_TYPE_FIELD: "Field type",
  TEXT_UNDEFINED: "(Not defined)",
  TEXT_UPDATE: "Update",
  TEXT_UPDATE_USER: "Save changes",
  TEXT_USER: "User",
  TEXT_USERNAME: "User name / email",
  TEXT_USERNAME_MAIL: "Email address",
  TEXT_USERNAME_MAIL_PLACEHOLDER: "Email address",
  TEXT_USERNAME_PLACEHOLDER: "User name / email",
  TEXT_USER_NAME: "User name",
  TEXT_VALUE_REQUIRED: "Required value",
  TEXT_VIDEO_CHANGE: "Change video",
  TEXT_VIDEO_LOAD: "Load video",
  TEXT_WAITING_STATE: "Wait a bit! We'll be right with you",
  TEXT_WELCOME_TOBOARD: "Welcome to the board!",
  TEXT_YOU_ARE: "You are in the room",
  TOOLTIP_CANT_SPEAK: "You don't have the floor",
  TOOLTIP_CAN_SPEAK: "You can speak",
  TOOLTIP_CHANGE_CAMERA: "Change camera",
  TOOLTIP_CONNECTED: "Connected",
  TOOLTIP_DISCONNECTED: "Disconnected",
  TOOLTIP_DOWN_HAND: "Put your hand down",
  TOOLTIP_ENABLED_DISABLED_MICROPHONE: "Turn microphone on / off",
  TOOLTIP_ENABLED_DISABLED_SPEAK: "Enable/disable participant sound",
  TOOLTIP_ENABLED_DISABLED_SPEAKER: "Make speaker",
  TOOLTIP_HIDE_ADMIN: "Hide administrator panel",
  TOOLTIP_HIDE_CHAT: "Hide message board",
  TOOLTIP_INVITE_INTERPRETE: "Request interpreter",
  TOOLTIP_LOGOUT_ROOM: "Exit room",
  TOOLTIP_MIC: "Microphone on",
  TOOLTIP_RAISE_HAND: "Raise hand",
  TOOLTIP_RAISE_HANDUP: "You raised your hand",
  TOOLTIP_REMOVE_USER: "Delete user",
  TOOLTIP_SELECT_LANG: "Select language",
  TOOLTIP_SHOW_ADMIN: "Administrator panel",
  TOOLTIP_SHOW_CHAT: "Message panel",
  TOOLTIP_SHOW_HIDE_ME: "Activate / deactivate camera",
  TOOLTIP_SHOW_HIDE_PARTICIPANTS: "Show active participants",
  TOOLTIP_SHOW_SHOW_SETTINGS: "More options",
  TOOLTIP_STOP_MIC: "Microphone off",
  TOOLTIP_STOP_RAISE_HAND: "Did not raise hand",
  WEBCAM_DISCONNECTED: "Camera disconnected!",
  WEB_SOCKET_CONNECTION_FAILED: "WebSocket connection failed",
  WEB_SOCKET_DISCONNECTED: "WebSocket connection has been disconnected.",
  WRITE_HERE_MOBILE: "Write here.",
  WRITE_HERE_WEB: "Type here and press enter",
  YOUARE_INTERPRETER: "You are Interpreter",
  YOUARE_LISTENER: "You are a participant",
  YOUARE_SPEAKER: "You are speaker",
  connected: "Connected",
  connecting: "Connecting",
  TEXT_ROOM_DELETED_SUBTITLE: "This room is disabled",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_ON: "Yes",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_BUTTON_ASKING_OFF: "No",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_TITLE: "Meeting Room",
  TEXT_ROOM_INTRO_ONLINE_SUPPORT_SUBTITLE:
    "Do you want to enter with camera and microphone activated?",
  BUTTON_FULL_SCREEN: "Full Screen",
  SHARE_MY_SCREEN_ON_OFF: "Share / Unshare screen",
  TEXT_EXIT: "Exit",
  TEXT_PROCESSING_VIDEO: "Processing video",
  TOOLTIP_ENABLED_MICROPHONE: "Microphone",
  TOOLTIP_DISABLED_MICROPHONE: "Desactivate microphone",
  TOOLTIP_ENABLED_CAMERA: "Camera",
  TOOLTIP_DISABLED_CAMERA: "Desactivate camera",
  BUTTON_DISABLED_FULL_SCREEN: "Desactivate full screen",
  TOGGLE_SHOW_MENU: "Menu",
  TOGGLE_LANG: "Change language",
  CHANGED_LANG_NOTIFICATION: "Language changed",
  BUTTON_HIDE_ME: "Hide / Show my camera",
};
