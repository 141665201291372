import {yupResolver} from '@hookform/resolvers';
import _ from "lodash";

window.formInitValue = null;
const getInitialValues = (initialValues, key) => {
  if (process.env && process.env.NODE_ENV == "development" && window.formInitValue) {
    let jsonMock = {};
    if (typeof window.formInitValue == "object"){
      jsonMock = window.formInitValue;
    }else{
      jsonMock = require("./../../mocks/" + window.formInitValue + ".json");
    }
    if (!jsonMock) {
      jsonMock = {};
    }
    return _.merge(jsonMock, initialValues);
  }
  return initialValues;
}

const getDefaultConfigForm = (initialValues, schema, options={}) => {
    return {
        defaultValues: {
            ...getInitialValues(initialValues)
        },
        resolver: yupResolver(schema),
        mode: 'all',
        criteriaMode: "firstErrorDetected",
        reValidateMode: 'onChange',
        shouldFocusError: true,
        ...options
    }

}
export default {
    getDefaultConfigForm
}