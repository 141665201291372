import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRoomContext } from '../../../modules/meeting/RoomContext';
import _ from "lodash";

import Dialog from '@material-ui/core/Dialog';
const InfoSpeaker = ({ me, chat, roomClient, activeSpeakerId, peers, open, handleClose }) => {

	return (

		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={false}
		>
			<div data-component='Infospeaker' >
				<div className={"header"}>
					<h4>Información del orador</h4>
				</div>
				<div className={"content"}>
					<button className={"next"} onClick={handleClose} >close</button>
				</div>
			</div>
		</Dialog>

	);
};

InfoSpeaker.propTypes = {
	roomClient: PropTypes.any.isRequired
};

const mapStateToProps = (state) => {
	return {
		room: state.room,
		me: state.me,

	};
};

const InfoSpeakerContainer = withRoomContext(connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev == next
			);
		}
	}
)(InfoSpeaker));


export default InfoSpeakerContainer;
