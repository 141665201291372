import randomString from "random-string";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import UrlParse from "url-parse";
import deviceInfo from "../../deviceInfo";
import pokemonName from "../../randomName";
import RoomClient from "../../RoomClient";
import RoomContext from "../../RoomContext";
import _ from "lodash";
import { Helmet } from "react-helmet";
import constants, {
  PEERS_TYPES,
  ROOM_VIEWS,
} from "../../../../constants/index.js";
import "./../../../../../styles/room/stylus/index.scss";
import useApi from "./hooks/useApi";
import useRoom from "./useRoom";
import useRoomStatus from "./hooks/useRoomStatus";
window.roomClient = null;

function RoomComponent(props) {
  const [view, setView] = useState(null);

  const [roomClient, setRoomClient] = useState(null);
  const {
    getRoom,
    setMe,
    setRoomUrl,
    store,
    meLogin = {},
    token,
    currentRoom,
    rooms,
  } = useApi();

  const { search } = useLocation();
  const { role, userName, translationLang, hubeet } = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      role: searchParams.get("role"),
      userName: searchParams.get("userName"),
      translationLang: searchParams.get("translationLang"),
      hubeet: searchParams.get("hubeet"),
    };
  }, [search]);
  const { ComponentRoom } = useRoom({ view, hubeet });

  /* 
  this.roomClient = new RoomClient(roomClientOptions)
  window.roomClient  = this.roomClient;
  this.setState({ roomClientOptions })
 */
  const { checkinData = {} } = props;

  useEffect(() => {
    const roomId = _.get(props, "match.params.roomId");
    // if (!room) this.props.getRoom(roomId)
    let roomClientOptions = {};
    const urlParser = new UrlParse(window.location.href, true);

    if (meLogin && meLogin.isInterpreter) {
      if (_.get(props, "checkinData.isInvited", false)) {
        roomClientOptions.peerType = constants.PEERS_TYPES.INVITED;
      } else {
        roomClientOptions.peerType = constants.PEERS_TYPES.INTERPRETER;
      }
    } else if (meLogin && currentRoom.owner === meLogin.userName)
      roomClientOptions.peerType = constants.PEERS_TYPES.INVITED;
    else roomClientOptions.peerType = constants.PEERS_TYPES.INVITED;

    roomClientOptions.userName = meLogin ? meLogin.userName : undefined;
    roomClientOptions.sessionToken = token;
    roomClientOptions.roomId = roomId;
    roomClientOptions.roomType = currentRoom.type;

    roomClientOptions.displayName =
      meLogin && !_.get(props, "checkinData.displayName", null)
        ? `${meLogin.firstName} ${meLogin.lastName}`
        : checkinData.displayName;
    roomClientOptions.participantData = _.get(
      props,
      "checkinData.participantData",
      null
    );
    roomClientOptions.handlerName = urlParser.query.handler;
    roomClientOptions.peerLang =
      translationLang ||
      checkinData.language ||
      currentRoom.officialLang ||
      "es";
    roomClientOptions.useSimulcast = urlParser.query.simulcast !== "false";
    roomClientOptions.produce = urlParser.query.produce !== "false";
    roomClientOptions.consume = urlParser.query.consume !== "false";
    roomClientOptions.svc = urlParser.query.svc;
    roomClientOptions.peerId = randomString({ length: 8 }).toLowerCase();
    roomClientOptions.device = deviceInfo();

    if (!roomClientOptions.displayName)
      roomClientOptions.displayName = pokemonName();

    let roomUrlParser = new UrlParse(window.location.href, true);
    Object.keys(roomUrlParser.query).forEach((key) => {
      // Don't keep some custom params.
      const validQueryParams = [
        "roomId",
        "handler",
        "simulcast",
        "sharingSimulcast",
        "produce",
        "consume",
        "forceH264",
        "forceVP9",
        "forceTcp",
        "svc",
        "datachannel",
        "info",
        "faceDetection",
        "externalVideo",
        "throttleSecret",
      ];
      if (!validQueryParams.includes(key)) delete roomUrlParser.query[key];
    });
    delete roomUrlParser.hash;
    if (roomClientOptions.roomType === "OnlineSupport") {
      roomClientOptions.displayName = userName ? userName : "";
      if (role === PEERS_TYPES.CUSTOMER) {
        roomClientOptions.peerType = PEERS_TYPES.INVITED;

        setView(ROOM_VIEWS.CUSTOMER);
      }
      if (role === PEERS_TYPES.SUPPORT) {
        roomClientOptions.peerType = PEERS_TYPES.SPEEKER;
        setView(ROOM_VIEWS.SUPPORT);
      }
      if (role === PEERS_TYPES.INTERPRETER) {
        roomClientOptions.peerType = PEERS_TYPES.INTERPRETER;
        setView(ROOM_VIEWS.INTERPRETER);
      }
      if (role === PEERS_TYPES.INVITED) {
        roomClientOptions.peerType = PEERS_TYPES.INVITED;
        setView(ROOM_VIEWS.INVITED);
      }
    }

    setRoomUrl(roomUrlParser.toString());
    setRoomClient(new RoomClient(roomClientOptions));

    window.roomClient = roomClient;

    setMe({
      peerId: roomClientOptions.peerId,
      participantData: roomClientOptions.participantData,
      displayName: roomClientOptions.displayName,
      displayNameSet: false,
      device: roomClientOptions.device,
      peerLang: roomClientOptions.peerLang,
      peerType: roomClientOptions.peerType,
      asking: roomClientOptions.asking,
    });
  }, []);
  return (
    <React.Fragment>
      {roomClient && (
        <RoomContext.Provider value={roomClient}>
          <Helmet>
            <title>{_.get(props, "currentRoom.title", "")}</title>
          </Helmet>
          <ComponentRoom
            view={view}
            enableAsking={_.get(checkinData, "enableAsking", undefined)}
          />
        </RoomContext.Provider>
      )}
    </React.Fragment>
  );
}

export default withRouter(RoomComponent);
