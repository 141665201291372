export function getProtooUrl({ roomId, peerId, forceH264, forceVP9, roomType, userName="", sessionToken="" })
{
	const protocolHostname = process.env.REACT_APP_API_WSS_URI || `wss://${window.location.hostname}`
	let url = `${protocolHostname}/api?roomId=${roomId}&peerId=${peerId}&roomType=${roomType}&userName=${userName}`;
	if (sessionToken) url = url + `&sessionToken=${sessionToken}`
	if (forceH264)
		url = `${url}&forceH264=true`;
	else if (forceVP9)
		url = `${url}&forceVP9=true`;

	return url;
}