
import React, { Component, useEffect } from "react";
import { useSelector,useDispatch } from 'react-redux';
import * as apiActions from '../redux/apiActions';
let MeetingRoom = (props) => {
    const dispatch = useDispatch();
    const rooms = useSelector((store) => store.api.rooms);
    useEffect(() => {
        dispatch(apiActions.updateUserData());
        dispatch(apiActions.getOrganizationData());
    },[]);
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
  }
  export default MeetingRoom;