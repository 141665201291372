import "../styles/_app.scss";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";
import routes from "./views/RootRoutes";
import { Store } from "./redux/Store";
import MatxLayout from "./MatxLayout/MatxLayout";
import AuthGuard from "./modules/session/auth/AuthGuard";
import MeetingRoom from "./proyect/MeetingRoom";
import RoomClient from "./modules/meeting/RoomClient";
import { SnackbarProvider } from "notistack";
RoomClient.init(Store);

class App extends Component {
  /**
   *Atrapa el error de react
   */
  componentDidCatch(e) {
    console.log("-----> Error", e);
    // if (_.get(window, 'env.loggerConfig.redirectError', false)) {
    //   window.location = 'auth/error';
    // }
  }
  render() {
    return (
      <AppContext.Provider value={{ routes }}>
        <Provider store={Store}>
          <MeetingRoom>
            <MatxTheme>
              <SnackbarProvider maxSnack={3}>
                <Router history={history}>
                  <AuthGuard>
                    <MatxLayout />
                  </AuthGuard>
                </Router>
              </SnackbarProvider>
            </MatxTheme>
          </MeetingRoom>
        </Provider>
      </AppContext.Provider>
    );
  }
}

export default App;
