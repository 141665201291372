import React, {useEffect, useState} from 'react';
import event from "../../../../utils/event"

window.jsonView = false;
window.setJsonView = (value) => {
    window.jsonView = value;
    event.emmit("DEV_JSONVIEW", { jsonView: value })
}
const JsonView = ({ json, title }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        event.on("DEV_JSONVIEW", (params) => {
            setCount(count + 1)
        });
        return () => {
            event.remove("DEV_JSONVIEW", () => {

            });
        }
    })
    return (

        <React.Fragment>
            {window.jsonView && process.env && process.env.NODE_ENV == "development" && (
                <pre>
                    <h5>{title}</h5>
                    <hr />
                    {JSON.stringify(json, null, 2)}
                </pre>
            )}
        </React.Fragment>
    )
}
export default JsonView;
