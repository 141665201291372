import React from "react";
import _ from "lodash";
import { useSelector } from 'react-redux';
import constants from "../../../constants/index.js";
const businessRules = {
    NOT_FORCE_CHANGE_PASSWORD: (store) => {

        return !_.get(store, "session.use.shouldChangePassword", false);
    },
    CAN_ENABLED_ROOM: (store) => {
        const isAdmin = _.get(store, "api.me.isAdmin", false);
        const currentRoom = _.get(store, "room", {});
        const isDisabled = constants.ROOM_STATUS.DISABLED == _.get(currentRoom, "info.status");
        return isAdmin && isDisabled;
    },
    CAN_DISABLED_ROOM: (store) => {
        const isAdmin = _.get(store, "api.me.isAdmin", false);
        const currentRoom = _.get(store, "room", {});
        const isOpen = constants.ROOM_STATUS.OPEN == _.get(currentRoom, "info.status");
        return isAdmin && isOpen;
    }
}
const AuthAccess = ({ children, action, businessRule, ...props }) => {
    const access = useSelector((store) => store.session.access);
    const store = useSelector((store) => store);
    const hasAccess = () => {
        if (!businessRule) {
            return access.some(a => a === action);
        } else {
            return _.get(businessRules, businessRule, () => { return false })(store);
        }

    }
    return (
        <React.Fragment>
            {hasAccess() && children}
        </React.Fragment>
    );
};


export default AuthAccess;
