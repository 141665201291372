import React from "react";
import { Typography } from "@material-ui/core";

const LoaderBounce = ({ text }) => {
  return (
    <div className="loader-bounce">
      <div className="spinner">
        <div
          className="double-bounce1 mat-bg-primary"
          style={{ background: "#fcc02e" }}
        />
        <div
          className="double-bounce2 mat-bg-accent"
          style={{ background: "#03a9f4" }}
        />
      </div>
      {text && (
        <Typography variant="h5" align="center">
          {text}
        </Typography>
      )}
    </div>
  );
};

export default LoaderBounce;
