import _ from "lodash";
const initialState = {
  info: {
    creationDate: null,
    description: null,
    languages: [],
    owner: null,
    staff: [],
    status: null,
    _id: null,
  },
  admin: {
    isRecord: false,
  },
  presentation: {
    video: null,
  },

  url: null,
  state: "new", // new/connecting/connected/disconnected/closed,
  isConnected: false,
  activeSpeakerId: null,
  statsPeerId: null,
  faceDetection: false,
  enabledMe: true,
  enabledInvited: true,
  handUp: false,
  mePositions: null,
  show_adminPanel: false,
  show_contacts: false,
  show_options: false,
  show_chat: false,
  show_lang: false,
  show_infoRoom: false,
  show_shareScreen: false,
  show_productInfo: false,
  modals: {
    enabledPeer: { show: false, props: {} },
    closeRoom: { show: false, props: {} },
    kickPeer: { show: false, props: {} },
    participantCloseRoom: { show: false, props: {} },
    participantKick: { show: false, props: {} },
    participantOpenDevice: { show: false, props: {} },
    participantNotInvited: { show: false, props: {} },
  },
};

const room = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ROOM_URL": {
      const { url } = action.payload;

      return { ...state, url };
    }

    case "SET_ROOM_STATE": {
      const roomState = action.payload.state;

      if (roomState === "connected")
        return { ...state, state: roomState, isConnected: true };
      else
        return {
          ...state,
          state: roomState,
          activeSpeakerId: null,
          statsPeerId: null,
          isConnected: false,
        };
    }

    case "SET_ROOM_ACTIVE_SPEAKER": {
      const { peerId } = action.payload;

      return { ...state, activeSpeakerId: peerId };
    }

    case "SET_ROOM_STATS_PEER_ID": {
      const { peerId } = action.payload;

      if (state.statsPeerId === peerId) return { ...state, statsPeerId: null };

      return { ...state, statsPeerId: peerId };
    }

    case "SET_FACE_DETECTION": {
      const flag = action.payload;

      return { ...state, faceDetection: flag };
    }

    case "REMOVE_PEER": {
      const { peerId } = action.payload;
      const newState = { ...state };

      if (peerId && peerId === state.activeSpeakerId)
        newState.activeSpeakerId = null;

      if (peerId && peerId === state.statsPeerId) newState.statsPeerId = null;

      return newState;
    }
    case "TOGGLE_SHOW_INVITED": {
      return { ...state, enabledInvited: !state.enabledInvited };
    }
    case "TOGGLE_SHOW_ME": {
      return { ...state, enabledMe: !state.enabledMe };
    }
    case "TOGGLE_HAND": {
      return { ...state, handUp: !state.handUp };
    }
    case "SET_MEPOSITION": {
      return { ...state, mePositions: action.payload.position };
    }
    case "TOGGLE_SHOW_ADMIN_PANEL": {
      return {
        ...state,
        show_adminPanel: !state.show_adminPanel,
        show_chat: false,
        show_contacts: false,
        show_productInfo: false,
      };
    }
    case "TOOGLE_SHARE_SCREEN": {
      return {
        ...state,
        show_shareScreen:
          action.payload.share !== undefined
            ? action.payload.share
            : !state.show_shareScreen,
      };
    }
    case "TOGGLE_SHOW_CONTACTS": {
      return {
        ...state,
        show_contacts: !state.show_contacts,
        show_adminPanel: false,
        show_chat: false,
        show_productInfo: false,
      };
    }
    case "TOGGLE_SHOW_LANG": {
      return { ...state, show_lang: !state.show_lang };
    }
    case "TOGGLE_SHOW_CHAT": {
      return {
        ...state,
        show_chat: !state.show_chat,
        show_adminPanel: false,
        show_contacts: false,
      };
    }
    case "TOGGLE_SHOW_PRODUCT": {
      return {
        ...state,
        show_productInfo: !state.show_productInfo,
        show_chat: false,
        show_adminPanel: false,
        show_contacts: false,
      };
    }
    case "TOGGLE_SHOW_OPTIONS": {
      return { ...state, show_options: !state.show_options };
    }
    case "TOGGLE_SHOW_INFO_ROOM": {
      return { ...state, show_infoRoom: !state.show_infoRoom };
    }
    case "ROOM_SET_SERVER_STATUS": {
      return { ...state, status: action.payload.status };
    }
    case "ROOM_CHANGE_STATUS": {
      let auxState = state;
      _.set(auxState, "info.status", action.payload.status);
      return auxState;
    }
    case "SET_INFO_ROOM": {
      return { ...state, info: action.payload.info };
    }

    case "UPDATE_PRESENTATION_VIDEO": {
      let presentation = state.presentation;
      let defaultPorperties = {
        url: null,
        playing: false,
        controls: false,
        muted: false,
        loop: false,
        pip: false,
        light: false,
        volume: 0.8,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
      };
      const { progessVideo } = action.payload;
      presentation.video = progessVideo
        ? { ...defaultPorperties, ...progessVideo }
        : null;

      return { ...state, presentation };
    }
    case "UPDATE_IS_RECORD": {
      let newState = { ...state };
      _.set(newState, "admin.isRecord", action.payload.isRecord);
      return newState;
    }
    case "MODAL_CHANGE_STATUS": {
      let newState = { ...state };
      newState.modals[action.payload.type] = {
        open: action.payload.open,
        props: action.payload.props,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default room;
