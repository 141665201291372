import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { CustomView, MobileView } from "react-device-detect";
import Room from "../Room";
import RoomMobile from "./roomTypes/event/mobile/RoomMobile";
import "./../../../../../styles/room/stylus/index.scss";
function DefaultRoom({ isDefaultView }) {
  isDefaultView = () => {
    return !isMobile;
  };

  return (
    <>
      <MobileView>
        <div id="app-room-mobile-container">
          <RoomMobile></RoomMobile>
        </div>
      </MobileView>
      <CustomView condition={isDefaultView()}>
        <div id="app-container">
          <Room />
        </div>
      </CustomView>
    </>
  );
}

export default DefaultRoom;
