import { MatxLoadable } from "matx";
import {getUriPage} from "../../../views/RootPages"





const Dashboard = MatxLoadable({
  loader: () => import("./pages/Dashboard")
})
const dashboardRoutes = [
  {
    path: getUriPage("module.dashboard.room"),
    component: Dashboard,
  }
];

export default dashboardRoutes;
