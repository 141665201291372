import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../../../modules/components/help/Tooltip";
import IconComponent from "../../../modules/components/generic/Icon";
import classnames from "classnames";

export default function ButtonMenuTop({ disabled = false, ...props }) {
  return (
    <div data-component="ButtonMenuTop">
      <Tooltip title={props.toggle ? props.tipClose : props.tip}>
        <div style={props.backgroundStyles}>
          <div className={props.blink ? "parpadeo" : ""}>
            <IconButton onClick={props.actionClick} disabled={disabled}>
              <IconComponent color={props.color}>{props.child}</IconComponent>
            </IconButton>
          </div>
        </div>
      </Tooltip>
      <div
        className={classnames({
          "detail-toggle": props.toggle,
          detail: !props.toggle,
        })}
      ></div>
    </div>
  );
}
