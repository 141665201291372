export default {
  session: {
    signIn: {
      uri: "/session/signIn",
    },
    logoutDefault: {
      uri: "/session/logout-default",
    },
    logoutCustomer: {
      uri: "/session/logout-customer/:lang?",
    },
    passwordReseted: {
      uri: "/session/passwordReseted",
    },
    "reset-link": {
      uri: "/session/reset-link",
    },
    signup: {
      uri: "/session/signup",
    },
    "forgot-password": {
      uri: "/session/forgot-password",
    },
    "change-password": {
      uri: "/admin/session/change-password",
    },
    reset: {
      uri: "/session/reset",
    },
    404: {
      uri: "/session/404",
    },
  },
};
