import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import Button from "./../../../modules/components/buttons/Button";
import Modal from "./../../../modules/components/generic/Modal";
import * as roomActions from "./../../../redux/actions/roomActions";
import { getUriPage } from "./../../../views/RootPages";
//import ManagerAccess from "./ManagerAccess";

const ModalForUserNotInvited = ({...props }) => {
  const dispatch = useDispatch();
  const modalProps = useSelector((store) =>
    _.get(store, "room.modals.participantNotInvited")
  );
  const currentRoom = useSelector((store) => store.room);

	const closeModal = ()=>{
		dispatch(roomActions.closeModal("participantNotInvited",{}));
	}

  const exitRoom = () => {
    window.location = _.get(currentRoom, "info.urlFinish", getUriPage("session.logoutDefault"));
  }

  return (
    <Modal
      open={modalProps.open}
      title={"MODAL_FORUSER_NOTINVITED_TITLE"}
      subtitle={"MODAL_FORUSER_NOTINVITED_SUBTITLE"}
      srcImage={"/assets/images/illustrations/ModalForUserNotInvited.svg"}
      buttons={[
        <Button
          variant="contained"
          type="button"
          color={"primary"}
          onClick={() => exitRoom()}
        >
          {translate("TEXT_BUTTON_CONFIRM")}
        </Button>,
      ]}
      onClose={closeModal}
    >
      <React.Fragment>
        <div data-component={"ModalForUserSimple"}>
          <div>{translate("MODAL_FORUSER_NOTINVITED")}</div>
        </div>
      </React.Fragment>
    </Modal>
  );
};
export default withRoomContext(ModalForUserNotInvited);
