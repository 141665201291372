import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import translate from "../../../lang/translate";
import Button from "./../../../modules/components/buttons/Button";
import * as roomActions from "./../../../redux/actions/roomActions";
import { getUriPage } from "./../../../views/RootPages";
import useEvent from "./Room/hooks/useEvent";
import useRoomStatus from "./Room/hooks/useRoomStatus";
import ModalOnlineSupport from "./ModalOnlineSupport";
const ModalCloseRoomOnlineSupport = () => {
  const { closeRoom } = useEvent();
  const { dispatch, room: currentRoom } = useRoomStatus();
  const modalCloseRoom = useSelector((store) =>
    _.get(store, "room.modals.closeRoom")
  );
  const exitRoom = () => {
    window.location = _.get(
      currentRoom,
      "info.urlFinish",
      getUriPage("session.logoutCustomer").replace(
        ":lang?",
        _.get(currentRoom, "info.officialLang", "")
      )
    );
  };
  const closeModal = () => {
    dispatch(roomActions.closeModal("closeRoom", {}));
  };

  return (
    <ModalOnlineSupport
      open={modalCloseRoom.open}
      title={translate("MODAL_CLOSE_CUSTOMER_TITLE")}
      buttons={[
        <Button
          variant="contained"
          size="medium"
          type="button"
          onClick={() => {
            closeModal();
          }}
        >
          {translate("TEXT_CUSTOMER_CANCEL_BUTTON")}
        </Button>,
        <Button
          variant="contained"
          type="button"
          size="medium"
          color={"primary"}
          onClick={() => {
            closeRoom();
            exitRoom();
          }}
        >
          {translate("TEXT_CUSTOMER_EXIT_BUTTON")}
        </Button>,
      ]}
      onClose={closeModal}
    ></ModalOnlineSupport>
  );
};
export default ModalCloseRoomOnlineSupport;
