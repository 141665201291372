import React from "react";
import {  Button } from "@material-ui/core";
import _ from "lodash";
const ButtonComponent = ({ children,  ...props }) => {

    return (
        <Button {...props}>
            {children}
        </Button>
    );
};


export default ButtonComponent;
