import { _ } from "core-js";
import { ROOM_VIEWS } from "app/constants";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getLangs, getRoom } from "../../../redux/apiActions";
import DeletedRoom from "../components/DeletedRoom";

import useRoomStatus from "../components/Room/hooks/useRoomStatus";
import Room from "../components/Room/Room";
import useRoomIntro from "../components/RoomIntro/useRoomIntro";
import { setLang, lang } from "./../../../../app/lang/translate";
const RoomIntro = ({ children, action, ...props }) => {
  const [checkinRoomPassed, setCheckinRoomPassed] = useState(undefined);
  const [view, setView] = useState(null);
  const isLoggedIn = useSelector((store) => !!store.api.token);
  const currentRoom = useSelector((store) => store.api.currentRoom);
  const { peers } = useRoomStatus();
  let room = currentRoom;
  const client = useSelector((store) => store.session.client);
  const user = useSelector((store) => store.api.me);
  const { roomId } = useParams();
  const dispatch = useDispatch();
  const [langTranslate, setLangTranslate] = useState(lang);
  useEffect(() => {
    dispatch(getRoom(roomId));
    dispatch(getLangs(roomId));
  }, []);
  const { search } = useLocation();
  const { role } = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return { role: searchParams.get("role") };
  }, [search]);

  // const { result: room, loading } = useService(services.module.rooms.get, { auto: true, paramsUrl: { roomId }, initValue: [] });

  const handleFinishSubmit = (data) => {
    setCheckinRoomPassed(data);
  };
  useEffect(() => {
    const officialLang = _.get(room, "officialLang", lang);

    if (officialLang !== lang) {
      setLang(officialLang);
      setLangTranslate(officialLang);
    }
  }, [room]);
  useEffect(() => {
    if (room && room.type === "OnlineSupport") {
      if (room.status === "deleted") {
        setView(ROOM_VIEWS.DELETED);
      } else if (checkinRoomPassed) {
        setView("room");
      } else {
        setView(ROOM_VIEWS.INTRO_ONLINE_SUPPORT);
      }
    } else {
      if (isLoggedIn && user && !user.isInterpreter) {
        setView("room");
      } else if (isLoggedIn && user && user.isInterpreter) {
        if (checkinRoomPassed) {
          setView("room");
        } else {
          setView("interpreter");
        }
      } else if (!isLoggedIn) {
        if (room && room.status !== "open") {
          setView("close");
        } else {
          if (checkinRoomPassed) {
            setView("room");
          } else {
            setView("participant");
          }
        }
      }
    }
  }, [checkinRoomPassed, isLoggedIn, user, room]);
  const { ComponentRoomIntro } = useRoomIntro({ view });
  return (
    <React.Fragment>
      {view === "deleted" ? (
        <DeletedRoom role={role}></DeletedRoom>
      ) : (
        currentRoom && (
          <React.Fragment>
            {view === "room" ? (
              <Room checkinData={checkinRoomPassed} />
            ) : (
              <ComponentRoomIntro
                isMobile={isMobile}
                client={client}
                view={view}
                room={room}
                role={role}
                handleFinishSubmit={handleFinishSubmit}
              />
            )}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default RoomIntro;
