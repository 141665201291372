import { combineReducers } from "redux";
import LayoutReducer from "./LayoutReducer";


import session from "./../../modules/session/redux/reducers";
import api from "./meeting/api";
import chat from "./meeting/chat";
import room from "./meeting/room";
import me from "./meeting/me";
import peers from "./meeting/peers";
import dataProducers from "./meeting/dataProducers";
import producers from "./meeting/producers";
import consumers from "./meeting/consumers";
import notifications from './meeting/notifications';
import { reducer as formReducer } from 'redux-form';
const RootReducer = combineReducers({
  layout: LayoutReducer,
  
  session,
  api,
  consumers,
  chat,
  room,
  me,
  peers,
  producers,
  dataProducers,
  notifications,
  form: formReducer
});

export default RootReducer;