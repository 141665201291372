import { useCallback } from "react";

/**
 * Hook utilizado para enviar mensaje o notificaicones al padre
 * @param {*} param0
 * @returns
 */
function useIframeParent() {
  const sendMessage = useCallback((key, payload) => {
    // * se puede reemplazar por los datos de la organizacion
    window.top.postMessage({ key, payload, source: "meet-client" }, "*");
  }, []);
  return { sendMessage };
}

export default useIframeParent;
