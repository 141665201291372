import React, { useState, useMemo, useEffect } from "react";
import ButtonMenuTopCustomer from "./ButtonMenuTopCustomer";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import useEvent from "../../../hooks/useEvent";
import useEventHandler from "app/modules/meeting/components/Room/hooks/useEventHandler.jsx";
import { useCallback } from "react";

function ButtonFullScreen() {
  const { dragResizeOn, dragResizeOff, dragResizeButton } = useEvent();
  const [active, setActive] = useState(false);
  const insideIframe = useMemo(() => window.top !== window.self, []);
  const onDragResizeOn = useCallback(() => {
    setActive(true);
  }, []);
  const onDragResizeOff = useCallback(() => {
    setActive(false);
  }, []);
  const handler = useEventHandler({ onDragResizeOn, onDragResizeOff });
  useEffect(() => {
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [handler]);
  useEffect(() => {
    dragResizeButton();
  }, []);
  return (
    <React.Fragment>
      {insideIframe && (
        <ButtonMenuTopCustomer
          tip="BUTTON_FULL_SCREEN"
          child={<ZoomOutMapIcon />}
          actionClick={() => {
            if (active) {
              dragResizeOff();
            } else {
              dragResizeOn();
            }
          }}
          toggleDetailEnabled={true}
          toggle={active}
        ></ButtonMenuTopCustomer>
      )}
    </React.Fragment>
  );
}

export default ButtonFullScreen;
