import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
// import Button from './Button';
import { setAsking } from '../../../redux/stateActions';
import { withRoomContext } from '../../../modules/meeting/RoomContext';
import { bindActionCreators } from 'redux';
// import translate  from '../../lang/translate';
// import ReactTooltip from 'react-tooltip';
import constants from '../../../constants/index.js';
import _ from "lodash"

const actions = {
	CLOSE_ROOM: (me,user, room) => { return (_.get(user,"isAdmin",false)) },
	PANEL_ADMIN: (me,user, room) => { return (_.get(user,"isAdmin",false)) },
	INVITE_INTERPRETER: (me,user, room) => { return (_.get(user,"isAdmin",false)) },
	CONTACT_ACTIONS: (me, user,room) => { return (_.get(user,"isAdmin",false) && room.isConnected) },
	ENABLED_ROOM: (me, user,room) => { return (_.get(user,"isAdmin",false)) },
	DISABLED_ROOM: (me,user, room) => { return (_.get(user,"isAdmin",false)) },
	HAND_UP: (me, user,room) => { return (me.peerType === constants.PEERS_TYPES.INVITED) },
	SELECT_LANG: (me,user, room) => { return (me.peerType === constants.PEERS_TYPES.INVITED) }
}
const ManagerAccess = ({ me, user,room, action, children }) => {
	return (
		<React.Fragment>
			{(_.get(actions, action, () => { return true })(me,user, room)) ? (
				<React.Fragment>
				{ children }
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

ManagerAccess.propTypes =
{
	roomClient: PropTypes.any.isRequired
	// activeSpeakerId: PropTypes.string
};

const mapStateToProps = (state) => {



	return {
		room: state.room,
		user: state.session.user,
		me: state.me,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({ setAsking }, dispatch)

const ManagerAccessContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev == next
			);
		}
	}
)(ManagerAccess));


export default ManagerAccessContainer;
