import history from "history.js";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import AuthAccess from "../auth/AuthAccess";
import Button from "../../../modules/components/buttons/Button";
import SubmitButton from "../../../modules/components/buttons/SubmitButton";
import Form from "../../forms/Form";
import InputPassword from "../../../modules/components/forms/InputPassword";
import ControllerInput from "../../forms/ControllerInput";
import useService from "../../../modules/api/hooks/useService";
import translate from "../../../lang/translate";
import {changePass} from '../redux/actions';
import services from "../../../services";
import configParams from "../../../utils/config";
import { getUriPage } from "../../../views/RootPages";
const schema = yup.object().shape({
  prevPassword: yup
    .string().required(translate("NEWUSER_PASSWORD_REQUIRED")),
  password: yup
    .string()
    .matches(configParams.RegExp.PASSWORD, {
      message: translate("NEWUSER_INCORRECT_PASSWORD"),
      excludeEmptyString: true,
    })
    .required(translate("NEWUSER_PASSWORD_REQUIRED")),
  passwordValidation: yup
    .string()
    .oneOf([yup.ref("password"), null], translate("NEWUSER_PASSWORD_NOT_MACH"))
    .required(translate("TEXT_VALUE_REQUIRED")),
});

let ChangePasswordForm = ({ initialValues, handleSubmit, handleClose }) => {
  const dispatch = useDispatch();
  const callbackSubmit= ()=>{
    dispatch(changePass());
    goBack();
  } 
  const { send: forgotPassword, loading: loadingCreateUser } = useService(
    services.module.session.change_pass,
    {
      callback: callbackSubmit,
    }
  );
  
  const onSubmit = (values) => {
    const forgotPassValues = { prevPassword: values.prevPassword, password: values.password };
    forgotPassword(forgotPassValues);
  };
  const goBack = () => {
    history.push({
      pathname: getUriPage("module.dashboard.room"),
    });
  }

  return (
    <React.Fragment>
      <Form initialValues={initialValues} schema={schema} onSubmit={onSubmit}>
        <ControllerInput
          as={InputPassword}
          defaultValue=""
          name="prevPassword"
          placeholder={translate("TEXT_PASSWORD_PLACEHOLDER")}
          label={translate("TEXT_PASSWORD")}
        />
        <ControllerInput
          as={InputPassword}
          defaultValue=""
          name="password"
          placeholder={translate("TEXT_NEW_PASSWORD_PLACEHOLDER")}
          label={translate("TEXT_NEW_PASSWORD")}
        />
        <ControllerInput
          as={InputPassword}
          defaultValue=""
          name="passwordValidation"
          placeholder={translate("TEXT_PASSWORD_VERIFY_PLACEHOLDER")}
          label={translate("TEXT_PASSWORD_VERIFY")}
        />
        <div className={"flex flex-end flex-middle"}>
          <div className="mr-8 ">
            <AuthAccess businessRule={"NOT_FORCE_CHANGE_PASSWORD"}>
              <Button variant="contained" type="button" onClick={goBack}>
                {translate("BUTTON_BACK_LOGIN")}
              </Button>
            </AuthAccess>
          </div>
          <div>
            <SubmitButton>{translate("TEXT_CREATE_ACCOUNT")}</SubmitButton>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};
export default ChangePasswordForm;
