import GroupIcon from "@material-ui/icons/Group";
import _ from "lodash";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import translate from "app/lang/translate";
//import classnames from "classnames";
import RoomContext from "app/modules/meeting/RoomContext";
import { toogleContacts } from "app/redux/actions/roomActions";
// import IconButton from "@material-ui/core/IconButton";
import ButtonMenuTop from "../../../../ButtonMenuTop";
import StatusBar from "../../event/desktop/StatusBar";
import Inforoom from "app/modules/meeting/components/Inforoom.jsx";
//import Button from "./Button";
import Infospeaker from "../../../../Infospeaker";

import useRoomStatus from "../../../hooks/useRoomStatus";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import * as roomActions from "../../../../../../../redux/actions/roomActions";

import MenuTopSpacer from "app/modules/meeting/components/MenuTopSpacer";
import { useContext } from "react";
import ButtonToggleMenu from "app/modules/meeting/components/ButtonToggleMenu";
import ListItem from "app/modules/components/menu/ListItem";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useLocation } from "react-router-dom";
const MenuTopInterpreter = ({ onToggleHideMe }) => {
  const roomClient = useContext(RoomContext);
  const [openPopoverSettings, setOpenPopoverSettings] = useState(false);
  const [showInfoRoom, setShowInfoRoom] = useState(false);
  const [showInfoSpeaker, setShowInfoSpeaker] = useState(false);
  const [setWebcamState] = useState(null);
  const [canSendWebcam, setCanSendWebcam] = useState(null);

  const [blinkNotificationColor, setBlinkNotificationColor] =
    useState("inherit");
  const [cantBlinkNotification, setCantBlinkNotification] = useState(false);
  const {
    enabledMic,
    enabledCam,
    room,
    audioProducer,
    videoProducer,
    videoProducerShare,
    dispatch,
    me,
  } = useRoomStatus();

  const toogleContactsMenu = () => {
    dispatch(toogleContacts());
  };

  useEffect(() => {
    if (canSendWebcam && !me.canSendWebcam) {
      if (!me.canSendWebcam) setWebcamState(null);
      else if (videoProducerShare) setWebcamState(true);
      else setWebcamState(false);
      setCanSendWebcam(me.canSendWebcam);
    }
  });

  const handleCloseSettings = () => {
    setOpenPopoverSettings(false);
  };

  const handleShowInfoRoom = () => {
    handleCloseSettings();
    setShowInfoRoom(!showInfoRoom);
  };

  const handleShowInfoSpeaker = () => {
    handleCloseSettings();
    setShowInfoSpeaker(!showInfoSpeaker);
  };

  const MicrophoneIcon = useMemo(
    () => () => {
      if (enabledMic) {
        return <MicIcon />;
      } else if (!enabledMic) {
        return <MicOffIcon />;
      } else {
        return null;
      }
    },
    [enabledMic]
  );
  const CameraIcon = useMemo(
    () => () => {
      if (enabledCam) {
        return <VideocamIcon />;
      } else if (!enabledCam) {
        return <VideocamOffIcon />;
      } else {
        return null;
      }
    },
    [enabledCam]
  );
  const handleCloseRoom = () => {
    dispatch(roomActions.openModal("closeRoom", {}));
  };
  const [hideMe, setHideMe] = useState(false);
  const EyeIcon = useMemo(() => {
    return hideMe ? <VisibilityOffIcon /> : <VisibilityIcon />;
  }, [hideMe]);

  const handleHideMe = useCallback(() => {
    onToggleHideMe(!hideMe);
    setHideMe(!hideMe);
  }, [hideMe, onToggleHideMe]);

  const { search } = useLocation();
  const { avaibleHideMe } = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      avaibleHideMe: searchParams.get("avaibleHideMe"),
    };
  }, [search]);
  return (
    <div data-component="MenuTopInterpreter">
      <div className={"botoneraLeft"}>
        <StatusBar showDescriptions={false} showPersonIcon={false} />
      </div>
      <div className={"botoneraCenter"}></div>
      <div className={"botoneraRight"}>
        <ButtonToggleMenu
          buttons={[
            <ListItem
              onClick={toogleContactsMenu}
              icon={<GroupIcon />}
              text={translate(`SHOW_PARTICIPANTS_LIST`)}
            />,
            avaibleHideMe && (
              <ListItem
                onClick={handleHideMe}
                icon={EyeIcon}
                text={translate(`BUTTON_HIDE_ME`)}
              />
            ),
          ]}
        ></ButtonToggleMenu>
        <MenuTopSpacer></MenuTopSpacer>
        <ButtonMenuTop
          tip="TOOLTIP_ENABLED_CAMERA"
          child={<CameraIcon />}
          actionClick={() => {
            if (
              me.canSendWebcam &&
              videoProducer &&
              videoProducer.type !== "share"
            ) {
              roomClient.disableWebcam();
            } else {
              roomClient.enableWebcam();
            }
          }}
          tipClose="TOOLTIP_DISABLED_CAMERA"
          toggle={enabledCam}
        ></ButtonMenuTop>
        <ButtonMenuTop
          tip="TOOLTIP_ENABLED_MICROPHONE"
          child={<MicrophoneIcon />}
          actionClick={() => {
            if (!me.canSendMic) roomClient.unmuteMic();
            else if (!audioProducer) roomClient.unmuteMic();
            else if (!audioProducer.paused) roomClient.muteMic();
            else roomClient.unmuteMic();
          }}
          toggle={enabledMic}
          tipClose="TOOLTIP_DISABLED_MICROPHONE"
        ></ButtonMenuTop>

        <div className="detail-bottom"></div>
      </div>
      <Inforoom open={showInfoRoom} handleClose={handleShowInfoRoom} />
      <Infospeaker open={showInfoSpeaker} handleClose={handleShowInfoSpeaker} />
    </div>
  );
};
/* const mapStateToProps = (state) => {
  const producersArray = Object.values(state.producers);
  const audioProducer = producersArray.find(
    (producer) => producer.track.kind === "audio"
  );
  const videoProducer = producersArray.find(
    (producer) => producer.track.kind === "video" && producer.type != "share"
  );
  const videoProducerShare = producersArray.find(
    (producer) => producer.track.kind === "video" && producer.type == "share"
  );
  const langs = _.get(state.room, "info.languages", []).map((lang) => ({
    ...lang,
    id: lang.isoCode,
    key: lang.isoCode,
  }));
  return {
    room: state.room,

    api: state.api,
    audioProducer,
    videoProducer,
    videoProducerShare,
    langs,
  };
}; */

export default MenuTopInterpreter;
