import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "app/modules/components/help/Tooltip";
import IconComponent from "app/modules/components/generic/Icon";
import classnames from "classnames";
export default function ButtonMenuTopCustomer({
  disabled = false,
  toggleDetailEnabled = false,
  ...props
}) {
  return (
    <div data-component="ButtonMenuTopCustomer">
      <Tooltip title={props.tip}>
        <div data-component="ButtonMenuTopCustomerBackground">
          <IconButton
            size="small"
            onClick={props.actionClick}
            disabled={disabled}
          >
            <IconComponent color={props.color}>{props.child}</IconComponent>
          </IconButton>
        </div>
      </Tooltip>
      {toggleDetailEnabled && (
        <div
          className={classnames({
            "detail-toggle": props.toggle,
          })}
        ></div>
      )}
    </div>
  );
}
