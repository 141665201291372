export default {
  RegExp: {
    EMAIL: /^[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)@[a-zA-Z0-9-]{1,}(.[a-zA-Z0-9-]+)(.[a-zA-Z]{2,4})$/,
    PASSWORD: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
    DATE: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
  },
  publicPages: [
    "session.change-password"
  ]

};