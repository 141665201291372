import LockIcon from "@material-ui/icons/Lock";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import translate from "../../../lang/translate";
import { withRoomContext } from "../../../modules/meeting/RoomContext";
import { closeRoom } from "../../../redux/stateActions";
import Button from "./../../../modules/components/buttons/Button";
import Modal from "./../../../modules/components/generic/Modal";
import useService from "./../../../modules/api/hooks/useService";
import * as roomActions from "./../../../redux/actions/roomActions";
import services from "./../../../services";
import { getUriPage } from "./../../../views/RootPages";
import ManagerAccess from "./ManagerAccess";
import useEvent from "./Room/hooks/useEvent";
import { useEffect } from "react";

const ModalCloseRoom = ({ roomClient, ...props }) => {
  const { closeRoom } = useEvent();
  const { view = undefined } = props;
  const dispatch = useDispatch();
  const modalCloseRoom = useSelector((store) =>
    _.get(store, "room.modals.closeRoom")
  );
  const api = useSelector((store) => _.get(store, "api"));
  const currentRoom = useSelector((store) => store.room);
  const { send: disabledRoom } = useService(services.module.rooms.enabled, {
    paramsUrl: { roomId: _.get(currentRoom, "info._id") },
    callback: () => {
      dispatch(roomActions.changeStatusRoom("disabled"));
    },
  });

  const closeAndKickRoom = () => {
    if (api.me.isAdmin) {
      disabledRoom(null, { roomId: _.get(currentRoom, "info._id") });
      dispatch(closeRoom());
    }
    exitRoom();
  };
  const exitRoom = () => {
    let logout =
      api.currentRoom.type === "OnlineSupport"
        ? "logoutCustomer"
        : "logoutDefault";
    window.location = _.get(
      currentRoom,
      "info.urlFinish",
      getUriPage(`session.${logout}`)
    );
  };

  const closeModal = () => {
    dispatch(roomActions.closeModal("closeRoom", {}));
  };

  const handleSubmit = (values) => {
    closeModal();
  };

  return (
    <Modal
      open={modalCloseRoom.open}
      title={"MODAL_CLOSE_ROOM_TITLE"}
      subtitle={"MODAL_CLOSE_ROOM_SUBTITLE"}
      srcImage={"/assets/images/illustrations/exitRoom.svg"}
      buttons={[
        <Button variant="contained" type="button" onClick={() => closeModal()}>
          {translate("TEXT_BUTTON_CANCEL")}
        </Button>,
        <Button
          variant="contained"
          type="button"
          color={"primary"}
          onClick={() => {
            closeRoom();
            exitRoom();
          }}
        >
          {translate("TEXT_EXIT_ROOM")}
        </Button>,
        <ManagerAccess action="CLOSE_ROOM">
          <Button
            variant="contained"
            type="button"
            color={"primary"}
            startIcon={<LockIcon />}
            onClick={() => closeAndKickRoom()}
          >
            {translate("TEXT_CLOSE_ROOM")}
          </Button>
        </ManagerAccess>,
      ]}
      onClose={closeModal}
    >
      <React.Fragment>
        <div data-component={"ModalCloseRoom"}>
          <div>{translate("TEXT_EXIT_ROOM_TEXT")}</div>
          <ManagerAccess action="CLOSE_ROOM">
            <div>{translate("TEXT_EXIT_ROOM_TEXT_ADMIN")}</div>
          </ManagerAccess>
        </div>
      </React.Fragment>
    </Modal>
  );
};
export default withRoomContext(ModalCloseRoom);
