import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import translate from "../../../lang/translate";
import FloatMenu from "./../menu/FloatMenu";
import { IconButton, Icon } from "@material-ui/core";

const MoreButton = ({ options, color = "primary", ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title={translate("TEXT_MORE_ACTIONS")}>
        <IconButton onClick={handleClick}>
          {" "}
          <Icon color={color}> more_vert </Icon>{" "}
        </IconButton>
      </Tooltip>
      <FloatMenu
        options={options}
        anchorEl={anchorEl}
        handleClose={handleClose}
      ></FloatMenu>
    </React.Fragment>
  );
};

export default MoreButton;
