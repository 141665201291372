export default {
	rooms: {
		list: {
			uri: "/api/rooms",
			method: "get",
			useToken: true,
		},
		create: {
			uri: "/api/rooms",
			method: "post",
			useToken: true,
			successMessageKey: "NOTIFICATION_CREATE_ROOM_SUCCESS"
		},
		delete: {
			uri: `/api/rooms/:roomId`,
			method: "delete",
			useToken: true,
			successMessageKey: "NOTIFICATION_DELETE_ROOM_SUCCESS"
		},
		get: {
			uri: `/api/rooms/getRoom/:roomId`,
			method: "get",
			useToken: true,
		},
		//SAcar de este modulo
		enabled: {
			uri: `/api/rooms/:roomId`,
			method: "put",
			useToken: true,
			successMessageKey: "NOTIFICATION_ENABLED_ROOM_SUCCESS"
		},
		disabled: {
			uri: `/api/rooms/:roomId`,
			method: "put",
			useToken: true,
			successMessageKey: "NOTIFICATION_DISABLED_ROOM_SUCCESS"
		},
		eject: {
			uri: `/api/room/eject/:roomId`,
			method: "put",
			useToken: true,
			successMessageKey: "NOTIFICATION_EJECT_ROOM_SUCCESS"
		}
	},

}
