import React from "react";
import { Link } from '@material-ui/core';
import _ from "lodash";
const LinkComponent = ({ children,  ...props }) => {

    return (
        <Link data-component={"Link"} {...props}>
            {children}
        </Link>
    );
};


export default LinkComponent;
